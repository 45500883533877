import React from 'react';
import { withStyles } from '@material-ui/core';
import { Header } from '../components';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        flexGrow: 1,
        position: 'relative',
    },
});

const HeaderLayout = ({ children, classes }) => (
    <div className={classes.layout}>
        <Header />
        {children}
    </div>
);

export default withStyles(styles)(HeaderLayout);
