import { handleActions, combineActions } from 'redux-actions';
import {
    GET_PROJECT_ECRS,
    CREATE_ECR,
    CERTIFY_ECR,
    UNCERTIFY_ECR,
    REMOVE_ECR,
    ACTION_ECR,
    UPDATE_ECR,
} from './actions/type';

const initialState = [];

const reducer = handleActions(
    {
        [GET_PROJECT_ECRS]: (state, { payload }) => payload,
        [CREATE_ECR]: (state, { payload }) => [...state, ...payload],
        [combineActions(CERTIFY_ECR, ACTION_ECR, UPDATE_ECR)]: (state, { payload }) =>
            state.map(ecr => (ecr.id === payload.id ? payload : ecr)),
        [REMOVE_ECR]: (state, { payload }) => state.filter(ecr => !payload.includes(ecr.id)),
        [UNCERTIFY_ECR]: (state, { payload }) => state.map(ecr => (ecr.id === payload.id ? payload : ecr)),
    },
    initialState
);

export default reducer;
