import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover, Typography } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { APPROVAL_STATUS } from '../constants';

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: '300px',
    },
}));

const PopoverComment = ({ content, status }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    return (
        <>
            <IconButton color={status === APPROVAL_STATUS.REJECTED ? 'secondary' : 'primary'} onClick={handleClick}>
                <Assignment />
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>{content}</Typography>
            </Popover>
        </>
    );
};

PopoverComment.propTypes = {
    content: PropTypes.string.isRequired,
    status: PropTypes.string,
};

export default PopoverComment;
