import { roleColors, statusColor } from '../../../../components/styles';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '92%',
    },
    cloudButton: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    cloudDone: {
        color: statusColor.approved,
    },
    list: {
        minHeight: '10vh',
        width: '100%',
        position: 'relative',
        overflowY: 'scroll',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '&:before': {
            top: 0,
            left: '50%',
            width: '3px',
            bottom: 0,
            content: '""',
            position: 'absolute',
            marginLeft: '-1px',
            backgroundColor: theme.palette.grey[500],
        },
    },
    node: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '& span,h6': {
            flex: 1,
        },
    },
    nodeDate: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,

        '& span': {
            lineHeight: 'inherit',
        },

        '&.overdue': {
            color: 'red',

            '& span:first-child': {
                textDecoration: 'line-through',
            },
        },
    },
    doc_author: {
        color: roleColors.author,
    },
    doc_reviewer: {
        color: roleColors.reviewer,
    },
    doc_approver: {
        color: roleColors.approver,
    },
    approveBtn: {
        backgroundColor: statusColor.approved,
        color: theme.palette.primary.contrastText,
    },
    rejectBtn: {
        backgroundColor: statusColor.rejected,
        color: theme.palette.primary.contrastText,
    },
});

export default styles;
