import httpClient from '../lib/httpClient';

const client = new httpClient();
const feeItemsUrl = projectId => `projects/${projectId}/feeitems`;

const getFeeItems = projectId => client.get(feeItemsUrl(projectId));

const submitFeeItems = (projectId, feeItems) => client.post(feeItemsUrl(projectId), feeItems);

const getTasks = () => client.get('taskitems');

const exportSummary = projectId => client.getHtml(`${feeItemsUrl(projectId)}/export`);

export const feeService = {
    getFeeItems,
    submitFeeItems,
    getTasks,
    exportSummary,
};
