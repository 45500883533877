import httpClient from '../lib/httpClient';

const client = new httpClient();
const userApiUrl = 'users';

const fetchUsers = () => client.get(`${userApiUrl}`);

export const userService = {
    fetchUsers,
};
