import httpClient from '../lib/httpClient';

const client = new httpClient();

const verificationDocUrlByProjectId = projectId => `projects/${projectId}/verificationDocuments`;
const verificationDocUrlById = verificationDocId => `projectVerificationDocuments/${verificationDocId}`;
const verificationDocHistoryUrlByVerificationDocId = verificationDocId =>
    `${verificationDocUrlById(verificationDocId)}/DocumentHistory`;
const verificationDocHistoryUrlById = verificationDocHistoryId =>
    `VerificationDocumentHistory/${verificationDocHistoryId}`;

const fetchVerificationDocsByProject = projectId => client.get(verificationDocUrlByProjectId(projectId));
const postVerificationDocsToProject = (projectId, verificationDocs) =>
    client.post(verificationDocUrlByProjectId(projectId), verificationDocs);
const deleteProjectAddon = verificationDocId => client.delete(verificationDocUrlById(verificationDocId));

const fetchVerificationDocHistoryByVerificationDocId = verificationDocId =>
    client.get(verificationDocHistoryUrlByVerificationDocId(verificationDocId));
const postVerificationDocHistoryToVerificationDoc = (verificationDocId, verificationDocHistory) =>
    client.post(verificationDocHistoryUrlByVerificationDocId(verificationDocId), verificationDocHistory);
const putVerificationDocHistoryById = (verificationDocHistoryId, verificationDocHistory) =>
    client.put(verificationDocHistoryUrlById(verificationDocHistoryId), verificationDocHistory);

const uploadVerificationDocHistoryDoc = (verificationDocId, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return client.post(`uploads/projectVerificationDocuments/${verificationDocId}`, data, true);
};

const deleteVerificationDocHistory = verificationDocHistoryId =>
    client.delete(verificationDocHistoryUrlById(verificationDocHistoryId));

export const verificationDocService = {
    fetchVerificationDocsByProject,
    postVerificationDocsToProject,
    deleteProjectAddon,
    fetchVerificationDocHistoryByVerificationDocId,
    postVerificationDocHistoryToVerificationDoc,
    putVerificationDocHistoryById,
    uploadVerificationDocHistoryDoc,
    deleteVerificationDocHistory,
};
