import httpClient from '../lib/httpClient';

const client = new httpClient();

const addOnUrlByProjectId = projectId => `projects/${projectId}/addondocuments`;
const addOnUrlById = addOnId => `projectaddondocuments/${addOnId}`;
const addOnHistoryUrlByAddOnId = addOnId => `${addOnUrlById(addOnId)}/documenthistory`;
const addOnHistoryUrlById = addOnHistoryId => `addondocumenthistory/${addOnHistoryId}`;

const fetchAddOnsByProject = projectId => client.get(addOnUrlByProjectId(projectId));
const postAddOnsToProject = (projectId, addOns) => client.post(addOnUrlByProjectId(projectId), addOns);
const deleteProjectAddon = addOnId => client.delete(addOnUrlById(addOnId));

const fetchAddOnHistoryByAddOnId = addOnId => client.get(addOnHistoryUrlByAddOnId(addOnId));
const postAddOnHistoryToAddOn = (addOnId, addOnHistory) => client.post(addOnHistoryUrlByAddOnId(addOnId), addOnHistory);
const putAddOnHistoryById = (addOnHistoryId, addOnHistory) =>
    client.put(addOnHistoryUrlById(addOnHistoryId), addOnHistory);

const uploadAddOnHistoryDoc = (addOnId, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return client.post(`uploads/projectaddondocuments/${addOnId}`, data, true);
};

const deleteAddOnHistory = addOnHistoryId => client.delete(addOnHistoryUrlById(addOnHistoryId));

export const addOnService = {
    fetchAddOnsByProject,
    postAddOnsToProject,
    deleteProjectAddon,
    fetchAddOnHistoryByAddOnId,
    postAddOnHistoryToAddOn,
    putAddOnHistoryById,
    uploadAddOnHistoryDoc,
    deleteAddOnHistory,
};
