import { combineReducers } from 'redux';

import logs from './logs';
import tags from './tags';
import currentLog from './currentLog';

export default combineReducers({
    currentLog,
    logs,
    tags,
});
