import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

import { SwitchRoutes } from '../components';

const styles = theme => ({
    layout: {
        padding: theme.spacing(2),
    },
    main: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
});

export class Project extends Component {
    static propTypes = {
        classes: PropTypes.object,
        subRoutes: PropTypes.array,
        title: PropTypes.string,
    };

    render() {
        const { classes, subRoutes, title } = this.props;
        return (
            <div className={classes.layout}>
                <Typography variant="h5">{title}</Typography>
                <main className={classes.main}>
                    <SwitchRoutes routes={subRoutes} />
                </main>
            </div>
        );
    }
}

export default withStyles(styles)(Project);
