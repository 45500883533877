import { createAction } from 'redux-actions';

import { authService } from '../../../services';
import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';
import { LOGIN, LOGOUT, REFRESH_TOKEN } from './types';

export const submitLogin = (userName, password) => async dispatch => {
    const requestName = LOGIN;
    dispatch(networkRequestStart({ requestName }));
    try {
        const auth = await authService.login(userName, password);
        const action = createAction(requestName);
        dispatch(action(auth));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const logout = () => dispatch => {
    authService.logout();
    const action = createAction(LOGOUT);
    dispatch(action());
};

export const refreshToken = () => async dispatch => {
    const requestName = REFRESH_TOKEN;
    dispatch(networkRequestStart({ requestName }));
    try {
        const auth = await authService.renewToken();
        const action = createAction(requestName);
        dispatch(action(auth));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
