const styles = theme => ({
    layout: {
        display: 'flex',
    },
    mainSection: {
        width: '80%',
    },
    referSection: {
        width: '20%',
        textAlign: 'center',
    },
});

export default styles;
