import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { connect } from 'react-redux';

import { downloadFile } from '../modules/project/actions';
import { getFileNameFromPath } from '../lib/utils';

const useStyles = makeStyles(theme => ({
    button: {
        justifyContent: 'flex-start',
    },
    icon: {
        marginLeft: theme.spacing(2),
    },
    label: {
        textAlign: 'start',
    },
}));

const FileDownloadButton = ({ label, filePath, downloadFile }) => {
    const classes = useStyles();

    const handleDownload = () => downloadFile(filePath);

    return (
        <Button color="primary" onClick={handleDownload} className={classes.button} classes={{ label: classes.label }}>
            {label ? label : getFileNameFromPath(filePath)}
            <Save className={classes.icon} />
        </Button>
    );
};

FileDownloadButton.propTypes = {
    filePath: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export default connect(null, { downloadFile })(FileDownloadButton);
