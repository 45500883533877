import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Paper } from '@material-ui/core';
import { AddToQueueTwoTone } from '@material-ui/icons';

const styles = theme => ({
    layout: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(5),
        color: theme.palette.grey[600],
    },
    icon: {
        fontSize: 128,
    },
    text: {
        color: theme.palette.grey[700],
    },
});

const EmptyState = ({ content, Icon, classes }) => {
    return (
        <Paper>
            <Grid
                className={classes.layout}
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={4}
            >
                <Grid item>
                    {Icon ? <Icon className={classes.icon} /> : <AddToQueueTwoTone className={classes.icon} />}
                </Grid>
                <Grid item>
                    <Typography className={classes.text} variant="h5">
                        {content}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

EmptyState.propTypes = {
    content: PropTypes.string.isRequired,
    Icon: PropTypes.object,
};

export default withStyles(styles)(EmptyState);
