import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MUIDataTable from 'mui-datatables';

import styles from './styles/logEntryTable';
import { EmptyState, ConfirmDialog } from '../../../components';
import { removeLogEntries, setCurrentLog } from '../actions';
import { tagsSelector } from '../selectors';
import { allUsersSelector, currentUserSelector } from '../../user/selectors';
import { checkUserRole } from '../../../lib/utils';
import { USER_ROLES } from '../../../constants';

import FileDownloadButton from '../../../components/FileDownloadButton';

const LogEntryTable = ({ logs, tags, allUsers, classes, currentUser, removeLogEntries, setCurrentLog, title }) => {
    const [logsToDelete, setLogsToDelete] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const handleLogsDelete = selectedRows => {
        const toDeleteData = logs.filter((log, index) => selectedRows.data.map(d => d.dataIndex).includes(index));
        setLogsToDelete(toDeleteData);
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = confirmed => () => {
        if (confirmed) {
            removeLogEntries(logsToDelete.map(log => log.id));
        }
        setShowConfirmDialog(false);
    };

    const handleLogEdit = (rowData, { dataIndex }) => {
        const currentLog = logs[dataIndex];
        if (currentLog.authorId !== currentUser.userId) return;

        const currentTagOptions = tags
            .filter(tag => currentLog.tags.includes(tag.id))
            .map(tag => ({ value: tag.id, label: tag.name }));
        setCurrentLog({ ...currentLog, tags: currentTagOptions });
    };

    const checkLogSelectable = dataIndex => logs[dataIndex].authorId === currentUser.userId;

    const renderDownloadButton = recordIndex => {
        const record = logs[recordIndex];
        if (record.path) return <FileDownloadButton label="Download" filePath={record.path} />;
        return null;
    };

    const renderLogDataTable = () => {
        if (_.isEmpty(allUsers)) return;

        const columns = [
            { name: 'Subject', options: { filter: false } },
            'Author',
            { name: 'Notes', options: { filter: false } },
            { name: 'EventDate', options: { filter: false } },
            { name: 'DateSubmitted', options: { filter: false } },
            {
                name: 'File',
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => renderDownloadButton(tableMeta.rowIndex),
                },
            },
        ];
        const data = logs.map(({ subject, authorId, notes, eventDate, dateSubmitted, path }) => [
            subject,
            allUsers.find(user => user.id === authorId).userName,
            notes,
            eventDate,
            dateSubmitted,
            path,
        ]);

        const options = {
            filterType: 'multiselect',
            onRowClick: handleLogEdit,
            isRowSelectable: checkLogSelectable,
            onRowsDelete: handleLogsDelete,
            print: false,
            responsive: 'scrollFullHeight',
        };

        const emptyMessage =
            checkUserRole(currentUser, USER_ROLES.RA_MANAGER) || checkUserRole(currentUser, USER_ROLES.RA_SPECIALIST)
                ? 'Start inputting log entries by clicking the floating New Log button.'
                : 'No log is found.';

        return (
            <div className={classes.tableContainer}>
                {_.isEmpty(logs) ? (
                    <EmptyState content={emptyMessage} />
                ) : (
                    <MUIDataTable columns={columns} data={data} options={options} title={title} />
                )}
            </div>
        );
    };

    return (
        <div>
            {renderLogDataTable()}
            <ConfirmDialog
                open={showConfirmDialog}
                onCloseDialog={handleCloseConfirmDialog}
                title="Are you sure to delete the selected logs?"
                content="Once confirmed the deletion, the logs will be permanently removed from database. "
            />
        </div>
    );
};

LogEntryTable.propTypes = {
    classes: PropTypes.object,
    logs: PropTypes.array,
    tags: PropTypes.array,
    allUsers: PropTypes.array,
    currentUser: PropTypes.object,
    removeLogEntries: PropTypes.func,
    setCurrentLog: PropTypes.func,
    title: PropTypes.string,
};

const mapStateToProps = state => ({
    tags: tagsSelector(state),
    allUsers: allUsersSelector(state),
    currentUser: currentUserSelector(state),
});

export default compose(
    connect(mapStateToProps, { removeLogEntries, setCurrentLog }),
    withStyles(styles)
)(LogEntryTable);
