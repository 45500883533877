const styles = theme => ({
    main: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
    docListSection: {
        width: '20vw',
    },
    timelineSection: {
        minWidth: '18vw',
        marginLeft: theme.spacing(3),
    },
    tabsSection: {
        flex: 1,
        marginLeft: theme.spacing(3),
    },
    paper: {
        height: '75vh',
        padding: theme.spacing(2),
    },
});

export default styles;
