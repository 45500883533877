import React from 'react';
import { matchPath } from 'react-router-dom';
import feeRoutes from './routes';
import { SubHeader, SwitchRoutes } from '../../components';

const Fee = ({ location }) => {
    const currentRoute = feeRoutes.find(route => !!matchPath(location.pathname, route.path));
    return (
        <div>
            <SubHeader title={currentRoute.title} />
            <SwitchRoutes routes={feeRoutes} />
        </div>
    );
};

export default Fee;
