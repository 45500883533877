import HealthZ from '../components/HealthZ';
import { APP_PATHS, USER_ROLES } from '../constants';
import { AuthPage, DashboardPage, LandingPage, MainPage } from '../views';

const rootRoutes = [
    { path: '/healthz', component: HealthZ },
    {
        path: '/auth',
        component: AuthPage,
    },
    {
        path: APP_PATHS.DASHBOARD,
        component: DashboardPage,
        roles: [...Object.values(USER_ROLES)],
    },
    {
        path: '/projects',
        component: MainPage,
        roles: [...Object.values(USER_ROLES)],
    },
    {
        path: '/',
        component: LandingPage,
        exact: true,
    },
    {
        path: '/:other',
        redirect: true,
        to: '/',
    },
];

export default rootRoutes;
