import React from 'react';
import ContentLoader from 'react-content-loader';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    loader: {
        width: '1000px',
        height: '100%',
    },
}));
const ChartLoader = () => {
    const classes = useStyles();
    return (
        <ContentLoader
            height={400}
            width={1000}
            className={classes.loader}
            speed={2}
            primaryColor="#d9d9d9"
            secondaryColor="#ecebeb"
        >
            <rect x="600" y="120" rx="4" ry="4" width="120" height="11" />
            <rect x="600" y="140" rx="4" ry="4" width="120" height="11" />
            <rect x="600" y="160" rx="4" ry="4" width="120" height="11" />
            <rect x="600" y="180" rx="4" ry="4" width="120" height="11" />
            <circle cx="40%" cy="50%" r="80" />
            <rect x="100" y="50" rx="4" ry="4" width="100" height="11" />
        </ContentLoader>
    );
};
export default ChartLoader;
