import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, DialogContentText, Button, DialogActions } from '@material-ui/core';

const ConfirmDialog = ({ open, onCloseDialog, title, content }) => {
    return (
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog(false)} color="default">
                    Cancel
                </Button>
                <Button onClick={onCloseDialog(true)} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    onCloseDialog: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
};

export default ConfirmDialog;
