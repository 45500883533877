import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    layout: {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(2),
    },
});
const SectionHeader = ({ classes, children }) => {
    return (
        <Typography variant="h6" className={classes.layout}>
            {children}
        </Typography>
    );
};

SectionHeader.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default withStyles(styles)(SectionHeader);
