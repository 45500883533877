import { createAction } from 'redux-actions';

export const CLOSE_ALERT = 'CLOSE_ALERT';
export const closeAlert = createAction(CLOSE_ALERT);

export const SET_ALERT = 'SET_ALERT';
export const setAlert = createAction(SET_ALERT);

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const showSpinner = createAction(SHOW_SPINNER);

export const HIDE_SPINNER = 'HIDE_SPINNER';
export const hideSpinner = createAction(HIDE_SPINNER);

export const SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS';
export const setSideBarStatus = createAction(SET_SIDEBAR_STATUS);
