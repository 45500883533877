const styles = theme => ({
    layout: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(4),
        maxWidth: '500px',
        flex: 1,
    },
    list: {
        maxHeight: '30vh',
        overflow: 'auto',
    },
    itemAction: {
        paddingLeft: theme.spacing(8),
    },
    todoHeader: {
        backgroundColor: theme.palette.grey[100],
    },
    todoTitle: {
        paddingRight: theme.spacing(2),
    },
    listLoader: {
        padding: theme.spacing(2),
    },
    listItemIconHolder: {
        width: '48px',
        height: '48px',
    },
});

export default styles;
