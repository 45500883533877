import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { SwitchRoutes, Sidebar } from '../components';
import { mainRoutes } from '../routes/main';
import { projectIdSelector, projectStatusSelector } from '../modules/project/selectors';
import { fetchProjectInfo, fetchProjectForms } from '../modules/project/actions';
import { getAppRouteByPath, buildProjectPath } from '../routes/helper';
import { PATH_STATUS_MAP, ALERT_LEVEL, STATUS_MESSAGE_MAP, APP_PATHS } from '../constants';
import { setAlert, setSideBarStatus } from '../modules/ui/actions';
import { getErrorCode, showSideBarSelector } from '../modules/ui/selectors';

const styles = theme => ({
    layout: {
        display: 'flex',
        zIndex: 1,
        flexGrow: 1,
        position: 'relative',
    },
    main: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minWidth: 0, // So the Typography noWrap works
    },
});

export const Main = ({
    classes,
    location,
    currentProjectId,
    currentProjectStatus,
    fetchProjectInfo,
    fetchProjectForms,
    setAlert,
    errorCode,
    showSideBar,
    setSideBarStatus,
}) => {
    const [redirect, setRedirect] = useState('');

    useEffect(() => {
        fetchProjectForms();
    }, [fetchProjectForms]);

    useEffect(() => {
        const projectPath = location.pathname.match(/projects\/(\d+)\/\w+/i);
        if (projectPath) {
            const projectId = projectPath[1];
            if (parseInt(projectId) !== currentProjectId) {
                fetchProjectInfo(projectId);
            }
            if (currentProjectId && currentProjectStatus) {
                const route = getAppRouteByPath(location.pathname);
                if (route) {
                    const validStatus = PATH_STATUS_MAP[route.path];
                    if (!validStatus.includes(currentProjectStatus)) {
                        setAlert({
                            variant: ALERT_LEVEL.WARNING,
                            message: STATUS_MESSAGE_MAP[currentProjectStatus].warningMessage,
                        });
                        setRedirect(
                            buildProjectPath(currentProjectId, STATUS_MESSAGE_MAP[currentProjectStatus].navigateTo)
                        );
                    } else {
                        setRedirect('');
                    }
                }
            }
        }
    }, [location.pathname, currentProjectId, currentProjectStatus, fetchProjectInfo, setAlert]);

    useEffect(() => {
        if (errorCode === 403) {
            setRedirect(APP_PATHS.DASHBOARD);
        }
    }, [errorCode]);

    if (redirect) {
        return <Redirect to={redirect} />;
    }
    return (
        <div className={classes.layout}>
            <Sidebar
                routes={mainRoutes}
                projectId={currentProjectId}
                projectStatus={currentProjectStatus}
                showSideBar={showSideBar}
                onSetSideBarStatus={() => setSideBarStatus(!showSideBar)}
            />
            <main className={classes.main}>
                <SwitchRoutes routes={mainRoutes} />
            </main>
        </div>
    );
};
Main.propTypes = {
    classes: PropTypes.object.isRequired,
    currentProjectId: PropTypes.number,
    currentProjectStatus: PropTypes.string,
    fetchProjectInfo: PropTypes.func,
    fetchProjectForms: PropTypes.func,
    setAlert: PropTypes.func,
    errorCode: PropTypes.number,
    showSideBar: PropTypes.bool,
    setSideBarStatus: PropTypes.func,
};
const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    errorCode: getErrorCode(state),
    showSideBar: showSideBarSelector(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, { fetchProjectInfo, fetchProjectForms, setAlert, setSideBarStatus }),
    withStyles(styles)
)(Main);
