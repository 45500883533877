import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import {
    GET_DOC_LOG_ENTRIES,
    GET_TAGS,
    CREATE_LOG_ENTRY,
    UPDATE_LOG_ENTRY,
    REMOVE_LOG_ENTRIES,
    GET_PROJECT_LOG_ENTRIES,
    SET_CURRENT_LOG,
    UPLOAD_LOG_FILE,
} from './types';
import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';
import { logService } from '../../../services';
import { currentDocIdSelector } from '../../review/selectors';
import { projectIdSelector } from '../../project/selectors';
import { tagsSelector } from '../selectors';

export const setCurrentLog = createAction(SET_CURRENT_LOG);

export const uploadLogFile = file => async (dispatch, getState) => {
    const requestName = UPLOAD_LOG_FILE;
    dispatch(networkRequestStart(requestName));
    try {
        const projectId = projectIdSelector(getState());
        const { path } = await logService.uploadLogFile(projectId, file);
        dispatch(networkRequestSuccess({ requestName }));
        return path;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getLogEntriesForCurrentDoc = () => async (dispatch, getState) => {
    const requestName = GET_DOC_LOG_ENTRIES;
    const docId = currentDocIdSelector(getState());
    if (docId) {
        dispatch(networkRequestStart({ requestName }));
        try {
            const logs = await logService.fetchLogEntriesByDocumentId(docId);
            const action = createAction(requestName);
            dispatch(action(logs));
            dispatch(networkRequestSuccess({ requestName }));
        } catch (error) {
            handleRequestError(dispatch, requestName, error);
        }
    }
};

export const getLogEntriesForCurrentProject = () => async (dispatch, getState) => {
    const requestName = GET_PROJECT_LOG_ENTRIES;
    const projectId = projectIdSelector(getState());
    if (projectId) {
        dispatch(networkRequestStart({ requestName }));
        try {
            const logs = await logService.fetchLogEntriesByProjectId(projectId);
            const action = createAction(requestName);
            dispatch(action(logs));
            dispatch(networkRequestSuccess({ requestName }));
        } catch (error) {
            handleRequestError(dispatch, requestName, error);
        }
    }
};

export const getTags = () => async (dispatch, getState) => {
    const requestName = GET_TAGS;
    if (!isEmpty(tagsSelector(getState()))) return;

    dispatch(networkRequestStart({ requestName }));
    try {
        const tags = await logService.fetchTags();
        const action = createAction(requestName);
        dispatch(action(tags));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const createLogEntry = log => async (dispatch, getState) => {
    const requestName = CREATE_LOG_ENTRY;
    dispatch(networkRequestStart({ requestName }));
    try {
        const projectId = projectIdSelector(getState());
        const projectDocumentId = currentDocIdSelector(getState());
        const newLog = await logService.postLogEntry({
            ...log,
            projectId,
            projectDocumentId: projectDocumentId < 0 ? null : projectDocumentId,
        });
        const action = createAction(requestName);
        dispatch(action(newLog));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const updateLogEntry = log => async dispatch => {
    const requestName = UPDATE_LOG_ENTRY;
    dispatch(networkRequestStart({ requestName }));
    try {
        const updatedLog = await logService.putLogEntry(log);
        const action = createAction(requestName);
        dispatch(action(updatedLog));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const removeLogEntries = logIds => async dispatch => {
    const requestName = REMOVE_LOG_ENTRIES;
    dispatch(networkRequestStart({ requestName }));
    try {
        const promises = logIds.map(id => logService.deleteLogEntry(id));
        await Promise.all(promises);
        const action = createAction(requestName);
        dispatch(action(logIds));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
