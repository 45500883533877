import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import dateFormate from 'date-fns/format';
import dateParse from 'date-fns/parse';

import { SubHeader, SectionHeader, FormDialog, EmptyState, PopoverComment, FileController } from '../../../components';
import styles from './styles/addOn';
import { projectAddOnsSelector, currentAddOnIdSelector, AddOnTrackingRecordsSelector } from '../selectors';
import {
    getProjectAddOns,
    setCurrentAddOn,
    saveAddOnsToProject,
    saveAddOnTrackingRecordToDocument,
    getAddOnTrackingRecords,
    uploadAddOnTrackingFile,
    updateAddOnTrackingRecord,
    removeAddOnTrackingRecord,
} from '../actions';
import { DOC_REVIEW_STAGE, ADD_ON_STATUS, DATE_FORMAT } from '../../../constants';
import { projectIdSelector } from '../../project/selectors';
import { getAllReviewDocuments } from '../../review/actions';
import { DocList } from '../../review/components';
import useDocOptions from '../../review/hooks/useDocOptions';
import useDocIdQuery from '../../review/hooks/useDocIdQuery';
import { predefinedDocsSelector } from '../../review/selectors';
import { currentUserSelector } from '../../user/selectors';
import ConfirmDeleteButton from '../../../components/ConfirmDeleteButton';

const Addon = ({
    currentUser,
    classes,
    location,
    selectedAddOnId,
    getAllReviewDocuments,
    getProjectAddOns,
    documents,
    currentProjectAddOns,
    saveAddOnsToProject,
    setCurrentAddOn,
    currentProjectId,
    saveAddOnTrackingRecordToDocument,
    getAddOnTrackingRecords,
    addOnTrackingRecords,
    uploadAddOnTrackingFile,
    removeAddOnTrackingRecord,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showRecordForm, setShowRecordForm] = useState(false);

    useEffect(() => {
        getAllReviewDocuments();
    }, [getAllReviewDocuments]);

    useEffect(() => {
        if (currentProjectId) getProjectAddOns();
    }, [currentProjectId, getProjectAddOns]);

    useDocIdQuery(location.search, currentProjectAddOns, setCurrentAddOn);

    useEffect(() => {
        if (selectedAddOnId) {
            getAddOnTrackingRecords();
        }
    }, [getAddOnTrackingRecords, selectedAddOnId]);

    const addOnDocuments = useMemo(() => documents.filter(doc => doc.stage === DOC_REVIEW_STAGE.ADDONS), [documents]);

    const predefinedAddOnOptions = useDocOptions(addOnDocuments, currentProjectAddOns);

    const selectedDoc = useMemo(() => currentProjectAddOns.find(doc => doc.id === selectedAddOnId), [
        currentProjectAddOns,
        selectedAddOnId,
    ]);

    const handleSaveAddOns = async () => {
        if (selectedOptions.length > 0) {
            const addedDocs = await saveAddOnsToProject(
                documents
                    .filter(doc => selectedOptions.some(op => op.value === doc.id))
                    .map(doc => _.pick(doc, ['name', 'stage', 'type']))
            );
            if (addedDocs) {
                setSelectedOptions([]);
            }
        }
    };

    const handleAddOnItemClick = addOnId => () => {
        if (addOnId !== selectedAddOnId) {
            setCurrentAddOn(addOnId);
        }
    };

    const handleSubmitNewAddOn = async newAddOn => {
        const addOns = await saveAddOnsToProject([{ ...newAddOn, stage: DOC_REVIEW_STAGE.ADDONS }]);
        if (addOns) {
            setCurrentAddOn(addOns[0].id);
        }
    };

    const handleRemoveProjectAddOn = () => () => {};

    const handleAddTrackingRecord = () => {
        setShowRecordForm(true);
    };

    const handleSubmitTrackingRecord = async record => {
        await saveAddOnTrackingRecordToDocument(record);
        setShowRecordForm(false);
    };

    const handleUploadTrackingFile = async file => await uploadAddOnTrackingFile(file);

    const handleRemoveAddOnTrackingRecord = recordId => async () => {
        await removeAddOnTrackingRecord(recordId);
    };

    const renderTrackingTable = () => {
        if (selectedAddOnId < 0) {
            return <EmptyState content="Select an existing addon document or create a new one to start" />;
        }
        return (
            <Table className={classes.trackingTable}>
                {renderTableHead()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderTableHead = () => {
        const headers = ['Name', 'Email', 'Phone', 'Date', 'Status', 'File', 'Comments', ''];
        return (
            <TableHead>
                <TableRow className={classes.highlightRow}>
                    {headers.map((col, index) => (
                        <TableCell key={index}>{col}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTrackingField = (id, field, value) => {
        if (field === 'comments' && value) return <PopoverComment content={value} />;
        if (field === 'eventDate') {
            value = dateFormate(dateParse(value), DATE_FORMAT);
        }
        if (field === 'path') return <FileController filePath={value} />;
        return value;
    };

    const renderTableBody = () => {
        if (addOnTrackingRecords.length > 0) {
            const fields = ['contactName', 'contactEmail', 'contactPhone', 'eventDate', 'status', 'path', 'comments'];
            return (
                <TableBody>
                    {addOnTrackingRecords.map(record => (
                        <TableRow key={record.id}>
                            {fields.map(key => (
                                <TableCell key={key}>{renderTrackingField(record.id, key, record[key])}</TableCell>
                            ))}
                            <TableCell padding="checkbox">
                                <ConfirmDeleteButton
                                    dialogTitle="Confirm to delete the tracking record?"
                                    onConfirmDelete={handleRemoveAddOnTrackingRecord(record.id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
    };

    const renderRecordForm = () => {
        const formTitle = 'Save Addon Tracking Record';
        const newRecord = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            eventDate: '',
            status: '',
            comments: '',
            path: '',
        };
        const fields = Object.keys(newRecord).map(key => {
            let type = '';
            let options = [];
            let rules = ['required'];
            let onUpload = null;
            switch (key) {
                case 'contactEmail':
                    type = 'email';
                    break;
                case 'comments':
                    type = 'textarea';
                    rules = [];
                    break;
                case 'eventDate':
                    type = 'date';
                    break;
                case 'status':
                    type = 'select';
                    options = Object.values(ADD_ON_STATUS).map(value => ({ label: value, value }));
                    break;
                case 'path':
                    type = 'upload';
                    onUpload = handleUploadTrackingFile;
                    break;
                default:
                    type = 'text';
            }
            return {
                type,
                name: key,
                options,
                rules,
                onUpload,
            };
        });

        return (
            <FormDialog
                formObject={newRecord}
                title={formTitle}
                fields={fields}
                open={showRecordForm}
                onClose={() => setShowRecordForm(false)}
                onSubmit={handleSubmitTrackingRecord}
            />
        );
    };

    return (
        <div>
            <SubHeader title="Add On Documents" />
            <main className={classes.main}>
                <section className={classes.docListSection}>
                    <Paper className={classes.paper}>
                        <SectionHeader>Choose Documents</SectionHeader>
                        <DocList
                            predefinedDocOptions={predefinedAddOnOptions}
                            onSelectChange={options => setSelectedOptions(options)}
                            selectedOptions={selectedOptions}
                            selectedDocId={selectedAddOnId}
                            onAddDocs={handleSaveAddOns}
                            currentProjectDocs={currentProjectAddOns}
                            onDocItemClick={handleAddOnItemClick}
                            onSubmitNewDoc={handleSubmitNewAddOn}
                            onRemoveProjectDoc={handleRemoveProjectAddOn}
                            reviewStage={DOC_REVIEW_STAGE.ADDONS}
                            currentUser={currentUser}
                        />
                    </Paper>
                </section>
                <section className={classes.reviewTrackSection}>
                    <Paper className={classes.paper}>
                        <SectionHeader>{`${selectedDoc ? selectedDoc.name : ''} Status Track`}</SectionHeader>
                        <Button
                            color="primary"
                            onClick={handleAddTrackingRecord}
                            variant="contained"
                            disabled={selectedAddOnId < 0}
                        >
                            Add Tracking Record
                        </Button>
                        {renderTrackingTable()}
                    </Paper>
                </section>
            </main>
            {renderRecordForm()}
        </div>
    );
};

Addon.propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object.isRequired,
    selectedAddOnId: PropTypes.number,
    getAllReviewDocuments: PropTypes.func,
    getProjectAddOns: PropTypes.func,
    documents: PropTypes.array,
    currentProjectAddOns: PropTypes.array,
    saveAddOnsToProject: PropTypes.func,
    setCurrentAddOn: PropTypes.func,
    saveAddOnTrackingRecordToDocument: PropTypes.func,
    getAddOnTrackingRecords: PropTypes.func,
    addOnTrackingRecords: PropTypes.array,
    uploadAddOnTrackingFile: PropTypes.func,
    updateAddOnTrackingRecord: PropTypes.func,
    removeAddOnTrackingRecord: PropTypes.func,
};

const mapStateToProps = state => ({
    documents: predefinedDocsSelector(state),
    currentProjectAddOns: projectAddOnsSelector(state),
    selectedAddOnId: currentAddOnIdSelector(state),
    currentProjectId: projectIdSelector(state),
    currentUser: currentUserSelector(state),
    addOnTrackingRecords: AddOnTrackingRecordsSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        getAllReviewDocuments,
        getProjectAddOns,
        saveAddOnsToProject,
        setCurrentAddOn,
        saveAddOnTrackingRecordToDocument,
        getAddOnTrackingRecords,
        uploadAddOnTrackingFile,
        updateAddOnTrackingRecord,
        removeAddOnTrackingRecord,
    }),
    withStyles(styles)
)(Addon);
