import { handleActions, combineActions } from 'redux-actions';
import { GET_FEE_ITEMS, GET_TASKS, SAVE_FEE_ITEMS } from './actions/types';

const initialState = {
    rates: [],
    bills: [],
    tasks: [],
};

const reducer = handleActions(
    {
        [combineActions(GET_FEE_ITEMS, SAVE_FEE_ITEMS)]: (state, { payload: { rates = [], bills = [] } }) => ({
            ...state,
            rates,
            bills,
        }),
        [GET_TASKS]: (state, { payload }) => ({ ...state, tasks: payload }),
    },
    initialState
);

export default reducer;
