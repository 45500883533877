import _ from 'lodash';
import qs from 'qs';

import { FILTER_OPERATOR, PROJECT_STATUS } from '../constants';

export const getFileNameFromPath = filePath => (filePath ? _.last(filePath.split(/\\|\//)) : '');

export const checkUserRole = (user, ...roles) => {
    const userRoles = _.get(user, 'roles', []);
    return !_.isEmpty(_.intersection(userRoles, roles));
};

export const generateFormData = object =>
    Object.keys(object).reduce((formData, key) => {
        if (object[key]) {
            formData.append(key, object[key]);
        }
        return formData;
    }, new FormData());

export const getProjectStatusByStage = stage =>
    _.chain(PROJECT_STATUS)
        .values()
        .filter(v => v.startsWith(stage));

const throwWrongFormatError = filter => {
    console.error(`${JSON.stringify(filter)} is not a valid filter`);
    throw new Error('Error occurs during process, please try again later.');
};

export const generateQueryStringByFilters = filters => {
    if (!_.isArray(filters)) throwWrongFormatError(filters);
    const where = filters.map(filter => {
        if (_.isArray(filter)) {
            if (filter.length !== 3 || !_.values(FILTER_OPERATOR).includes(filter[1])) throwWrongFormatError(filter);

            return filter.join(' ');
        }

        if (_.isObject(filter)) {
            const filterArray = _.toPairs(filter)[0];
            if (filterArray.length !== 2) throwWrongFormatError(filter);
            const [field, value] = filterArray;
            return [field, FILTER_OPERATOR.EQUAL, value].join(' ');
        }
        return null;
    });

    return qs.stringify(
        { where },
        {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
            encode: false,
            skipNulls: true,
        }
    );
};

export const createActionTypes = actionName => ({
    start: `${actionName.toUpperCase()}_START`,
    success: `${actionName.toUpperCase()}_SUCCESS`,
    fail: `${actionName.toUpperCase()}_FAIL`,
});

export const validate = (currentValue, rules) => {
    for (const rule of rules) {
        if (rule === 'required') {
            if (_.isNil(currentValue) || (_.isArray(currentValue) && _.isEmpty(currentValue))) return false;
        }
        // may have other rules validation coming
    }
    return true;
};

export const download = (file, name) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, 0);
};
