import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';
import {
    CREATE_PROJECT,
    FETCH_PROJECT,
    RESET_PROJECT,
    FETCH_FORMS,
    UPDATE_PROJECT,
    UPDATE_STATUS,
    GET_TOP_PROJECTS,
    ASSIGN_PROJECT,
    CLEAR_PROJECT_INFO,
    UPLOAD_FILE,
    DOWNLOAD_FILE,
    GET_PROJECT_MILESTONES,
    REMOVE_PROJECT_MILESTONE,
    UPDATE_PROJECT_FIELD,
    GET_PROJECT_DOCUMENTS_WITH_FILE,
} from './types';
import { projectService } from '../../../services';
import { getFormFields, projectIdSelector } from '../selectors';
import { TOP_PROJECTS_COUNT } from '../../../constants';
import { getFileNameFromPath, download } from '../../../lib/utils';

export const getTopProjects = (count = TOP_PROJECTS_COUNT) => async dispatch => {
    const requestName = GET_TOP_PROJECTS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const topProjects = await projectService.searchProjects({ limit: count });
        dispatch(networkRequestSuccess({ requestName }));
        const action = createAction(GET_TOP_PROJECTS);
        dispatch(action(topProjects));
        return true;
    } catch (error) {
        console.error({ requestName, error });
    }
};

export const createProject = (name, identifier) => async dispatch => {
    const requestName = CREATE_PROJECT;
    dispatch(networkRequestStart({ requestName }));

    try {
        const newProject = await projectService.createProject(name, identifier);
        const projectInfo = await projectService.getProjectFields(newProject.id);
        dispatch(networkRequestSuccess({ requestName }));
        const action = createAction(CREATE_PROJECT);
        dispatch(action(projectInfo));
        return newProject;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const fetchProjectInfo = id => async (dispatch, getState) => {
    const requestName = FETCH_PROJECT;
    dispatch(networkRequestStart({ requestName }));
    if (!id) {
        id = projectIdSelector(getState());
    }
    try {
        const projectInfo = await projectService.getProjectFields(id);
        if (projectInfo) {
            dispatch(networkRequestSuccess({ requestName }));
            const action = createAction(FETCH_PROJECT);
            dispatch(action(projectInfo));
            return true;
        }
        return null;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const fetchProjectForms = () => async (dispatch, getState) => {
    if (!isEmpty(getFormFields(getState()))) return;
    const requestName = FETCH_FORMS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const formFields = await projectService.getFormFields();
        if (formFields) {
            dispatch(networkRequestSuccess({ requestName }));
            const action = createAction(FETCH_FORMS);
            dispatch(action(formFields));
            return true;
        }
        return null;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const saveProjectInfo = (id, portion, data) => async dispatch => {
    const requestName = UPDATE_PROJECT;
    dispatch(networkRequestStart({ requestName }));

    try {
        const projectInfo = await projectService.updateProjectFormFieldsByStep(id, portion, data);
        dispatch(networkRequestSuccess({ requestName }));
        const action = createAction(UPDATE_PROJECT);
        dispatch(action(projectInfo));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const updateProjectStatus = (id, status, notes = '') => async dispatch => {
    const requestName = UPDATE_STATUS;
    dispatch(networkRequestStart({ requestName }));

    try {
        await projectService.updateProjectStatus(id, status, notes);
        dispatch(networkRequestSuccess({ requestName }));
        const action = createAction(UPDATE_STATUS);
        dispatch(action(status));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const assignProject = (id, userId) => async dispatch => {
    const requestName = ASSIGN_PROJECT;
    dispatch(networkRequestStart({ requestName }));
    try {
        await projectService.assignProjectTo(id, userId);
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const uploadFile = (id, fieldName, file, formName) => async dispatch => {
    const requestName = UPLOAD_FILE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const { path } = await projectService.uploadFileForProjectField(id, fieldName, file);
        dispatch(networkRequestSuccess({ requestName }));
        const action = createAction(UPDATE_PROJECT_FIELD);
        dispatch(action({ fieldPath: `${formName}.${fieldName}`, fieldValue: path }));
        return path;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const downloadFile = path => async dispatch => {
    const requestName = DOWNLOAD_FILE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const file = await projectService.downloadFile(path);
        download(file, getFileNameFromPath(path));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const resetProject = createAction(RESET_PROJECT);
export const clearProjectInfo = createAction(CLEAR_PROJECT_INFO);

export const getProjectMilestones = () => async (dispatch, getState) => {
    const requestName = GET_PROJECT_MILESTONES;
    dispatch(networkRequestStart({ requestName }));
    try {
        const currentProjectId = projectIdSelector(getState());
        if (currentProjectId) {
            const milestones = await projectService.fetchProjectMilestones(currentProjectId);
            const action = createAction(requestName);
            dispatch(action(milestones));
            dispatch(networkRequestSuccess({ requestName }));
        }
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getProjectDocumentsWithFile = () => async (dispatch, getState) => {
    const requestName = GET_PROJECT_DOCUMENTS_WITH_FILE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const currentProjectId = projectIdSelector(getState());
        if (currentProjectId) {
            const allDocs = await projectService.fetchAllDocumentsWithFileByProject(currentProjectId);
            const action = createAction(requestName);
            dispatch(action(allDocs));
            dispatch(networkRequestSuccess({ requestName }));
        }
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const removeProjectMilestone = id => async dispatch => {
    const requestName = REMOVE_PROJECT_MILESTONE;
    dispatch(networkRequestStart(requestName));
    try {
        await projectService.deleteProjectMilestone(id);
        const action = createAction(requestName);
        dispatch(action(id));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
