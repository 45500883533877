import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { authService } from '../services';
import { LOCATION_STATE, APP_PATHS } from '../constants';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (authService.isTokenAboutExpire()) {
                authService.renewToken();
            }
            if (!authService.isTokenValid())
                return (
                    <Redirect
                        to={{
                            pathname: APP_PATHS.LOGIN,
                            state: { from: props.location },
                        }}
                    />
                );
            if (!authService.isAuthorized(roles))
                return (
                    <Redirect
                        to={{
                            pathname: APP_PATHS.DASHBOARD,
                            state: { [LOCATION_STATE.UNAUTHORIZED]: true },
                        }}
                    />
                );
            return <Component {...props} />;
        }}
    />
);

const SwitchRoutes = ({ routes }) => (
    <Switch>
        {routes.map((route, key) => {
            const { path, component, redirect, to, roles, exact } = route;
            if (redirect) return <Redirect from={path} to={to} key={key} />;
            if (roles)
                return <ProtectedRoute exact={exact} path={path} component={component} roles={roles} key={key} />;
            return <Route path={path} exact={exact} component={component} key={key} />;
        })}
    </Switch>
);

SwitchRoutes.propTypes = {
    routes: PropTypes.array,
};

export default SwitchRoutes;
