import { handleActions, combineActions } from 'redux-actions';
import { FETCH_USERS, SET_CURRENT_USER } from './actions/types';
import { LOGIN, REFRESH_TOKEN, LOGOUT } from '../auth/actions/types';

const initialState = {
    currentUser: null,
    all: [],
};
const reducer = handleActions(
    {
        [combineActions(LOGIN, REFRESH_TOKEN)]: (state, { payload }) => ({
            ...state,
            currentUser: payload.userInfo,
        }),
        [FETCH_USERS]: (state, { payload }) => ({ ...state, all: payload }),
        [LOGOUT]: state => ({ ...state, currentUser: null }),
        [SET_CURRENT_USER]: (state, { payload }) => ({ ...state, currentUser: payload }),
    },
    initialState
);

export default reducer;
