const styles = theme => ({
    layout: {
        width: 420,
        display: 'block',
        margin: 'auto',
    },
    paper: {
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    field: {
        margin: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

export default styles;
