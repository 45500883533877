import { handleActions } from 'redux-actions';
import { GET_PROJECT_DOC_TIMELINE_NODES, SAVE_PROJECT_DOC_TIMELINE_NODE } from '../actions/types';

const initialState = [];

const reducer = handleActions(
    {
        [GET_PROJECT_DOC_TIMELINE_NODES]: (state, { payload }) => payload,
        [SAVE_PROJECT_DOC_TIMELINE_NODE]: (state, { payload }) => [payload, ...state],
    },
    initialState
);

export default reducer;
