import { Login } from './components';
import { APP_PATHS } from '../../constants';

const authRoutes = [
    {
        path: APP_PATHS.LOGIN,
        component: Login,
        navbarName: 'Login',
    },
    {
        redirect: true,
        path: '/auth',
        to: APP_PATHS.LOGIN,
    },
];

export default authRoutes;
