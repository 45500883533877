import { handleActions } from 'redux-actions';
import { SET_CURRENT_LOG } from '../actions/types';

const reducer = handleActions(
    {
        [SET_CURRENT_LOG]: (state, { payload }) => payload,
    },
    null
);

export default reducer;
