import { combineReducers } from 'redux';

import docs from './docs';
import assignments from './assignments';
import timeline from './timeline';

const reducer = combineReducers({
    docs,
    assignments,
    timeline,
});

export default reducer;
