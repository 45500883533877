import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import _ from 'lodash';

export const SideBarLoader = () => (
    <ContentLoader height={1200} width={400} speed={2} primaryColor="#d9d9d9" secondaryColor="#ecebeb">
        {_.times(10, index => (
            <Fragment key={index}>
                <circle cx="40" cy={100 + 100 * index} r="16" />
                <rect x="70" y={100 + 100 * index} rx="5" ry="5" width="70%" height="20" />
            </Fragment>
        ))}
    </ContentLoader>
);
