import React, { Component } from 'react';
import Landing from './views/Landing';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error('error', error);
        console.info('stack trace', info);
        console.info('current state', this.props.store.getState());
    }

    render() {
        if (this.state.hasError) {
            return <Landing hasError />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
