import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

import rootRoutes from './routes/root';
import { SwitchRoutes, Spinner, Alert } from './components';
import { showSpinnerSelector, getAlert } from './modules/ui/selectors';
import { closeAlert } from './modules/ui/actions';
import { AddLogFab, LogDetailDialog } from './modules/logEntries/components';
import { getTopProjects } from './modules/project/actions';
import { getAllUsers } from './modules/user/actions';
import { getTags } from './modules/logEntries/actions';
import { currentUserSelector } from './modules/user/selectors';
import { checkUserRole } from './lib/utils';
import { USER_ROLES } from './constants';

const App = ({ showSpinner, alert, userInfo, closeAlert, getTopProjects, getAllUsers, getTags }) => {
    useEffect(() => {
        if (userInfo) {
            getTopProjects();
            getAllUsers();
            getTags();
        }
    }, [getAllUsers, getTags, getTopProjects, userInfo]);

    const renderLogButtonAndDialog = () => {
        return checkUserRole(userInfo, USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER, USER_ROLES.ADMIN) ? (
            <>
                <AddLogFab />
                <LogDetailDialog />
            </>
        ) : null;
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Router>
                <SwitchRoutes routes={rootRoutes} />
            </Router>
            <Alert open={!!alert} {...alert} onClose={closeAlert} />
            <Spinner open={showSpinner} />
            {renderLogButtonAndDialog()}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>&copy; {new Date().getFullYear()} - ZEM Engineers Inc.</div>
            </div>
        </React.Fragment>
    );
};

App.propTypes = {
    alert: PropTypes.object,
    showSpinner: PropTypes.bool,
    closeAlert: PropTypes.func,
    userInfo: PropTypes.object,
    getTopProjects: PropTypes.func,
    getAllUsers: PropTypes.func,
    getTags: PropTypes.func,
};

const mapStateToProps = state => ({
    showSpinner: showSpinnerSelector(state),
    alert: getAlert(state),
    userInfo: currentUserSelector(state),
});

export default connect(mapStateToProps, { closeAlert, getTags, getAllUsers, getTopProjects })(App);
