import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper, Typography, Button, MenuItem, TextField, Chip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import dateFormate from 'date-fns/format';
import Chart from 'react-google-charts';
import cx from 'classnames';

import { SectionHeader, SubHeader, ChartLoader, DataTableLoader, PopoverComment } from '../../../components';
import { LogEntryTable } from '../../logEntries/components';
import { getLogEntriesForCurrentProject } from '../../logEntries/actions';
import {
    projectIdSelector,
    projectMilestonesSelector,
    projectNoteSelector,
    projectStatusSelector,
    assignedStaffNameSelector,
    projectLeadNameSelector,
} from '../selectors';
import { setCurrentDoc } from '../../review/actions';
import { renderLabels } from '../../../constants/labels';
import { DATE_FORMAT, APPROVAL_STATUS, ADD_ON_STATUS, DOC_REVIEW_STATUS } from '../../../constants';
import { VerifiedUser, Timelapse } from '@material-ui/icons';
import { getProjectMilestones } from '../actions';
import { statusColor } from '../../../components/styles';
import { logsSelector, tagsSelector } from '../../logEntries/selectors';
import { onGoingActionsSelector } from '../../ui/selectors';
import DocDataTable from '../components/DocDataTable';
import { projectDocumentsWithFileSelector } from '../selectors';
import { getProjectDocumentsWithFile } from '../actions';
import { GET_PROJECT_DOCUMENTS_WITH_FILE } from '../actions/types';
import { currentUserSelector } from '../../user/selectors';
import { USER_ROLES } from '../../../constants';
import { checkUserRole } from '../../../lib/utils';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
    },
    docChartSection: {
        marginTop: theme.spacing(3),
    },
    docListSection: {
        marginTop: theme.spacing(3),
    },
    logTableSection: {
        flex: 1,
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        minHeight: '400px',
    },
    milestoneWrapper: {
        display: 'flex',
        padding: `0 ${theme.spacing(3)}px`,
        flexWrap: 'wrap',
    },
    milestoneItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '240px',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        borderRadius: 8,
    },
    milestoneIcon: {
        color: theme.palette.grey[400],
        fontSize: '5rem',
        marginBottom: theme.spacing(2),
    },
    milestoneAchieved: {
        color: statusColor.approved,
    },
    milestoneSelect: {
        minWidth: 240,
    },
}));

const ProjectSummary = ({
    currentProjectId,
    getLogEntriesForCurrentProject,
    setCurrentDoc,
    milestones,
    getProjectMilestones,
    logs,
    tags,
    onGoingActions,
    currentProjectNotes,
    currentProjectStatus,
    currentAssignedStaffName,
    currentProjectLeadName,
    getProjectDocumentsWithFile,
    currentProjectDocumentsWithFile,
    currentUser,
}) => {
    const classes = useStyles();
    const [milestone, setMilestone] = useState('');

    useEffect(() => {
        setCurrentDoc(-1);
    });

    useEffect(() => {
        if (currentProjectId) {
            getLogEntriesForCurrentProject();
            getProjectMilestones();
            getProjectDocumentsWithFile();
        }
    }, [currentProjectId, getLogEntriesForCurrentProject, getProjectMilestones, getProjectDocumentsWithFile]);

    const allDocs = useMemo(() => [...currentProjectDocumentsWithFile], [currentProjectDocumentsWithFile]);

    const milestoneLogs = useMemo(() => {
        const findTagIdByName = name => {
            const tag = tags.find(t => t.name.includes(name));
            return tag ? tag.id : -1;
        };
        return milestones
            .filter(m => m.date)
            .map(m => m.name)
            .reduce((prev, cur) => {
                prev[cur] = logs.filter(l => l.tags.includes(findTagIdByName(cur)));
                return prev;
            }, {});
    }, [logs, milestones, tags]);

    const currentLogs = useMemo(() => (milestone ? milestoneLogs[milestone] : logs), [logs, milestone, milestoneLogs]);

    const isAccountRole = !checkUserRole(
        currentUser,
        USER_ROLES.ADMIN,
        USER_ROLES.RA_MANAGER,
        USER_ROLES.RA_SPECIALIST,
        USER_ROLES.PROJECT_CREATOR,
        USER_ROLES.DE_MANAGER
    );
    const handleMilestoneSelectChange = e => setMilestone(e.target.value);

    const handleMilestoneLogsClick = milestoneName => {
        setMilestone(milestoneName);
        const { top, left } = document.getElementById('log-section').getBoundingClientRect();
        window.scrollBy({ top, left, behavior: 'smooth' });
    };

    const isLoadingAllDocs = () => onGoingActions.some(a => [GET_PROJECT_DOCUMENTS_WITH_FILE].includes(a));

    const renderChart = () => {
        if (isLoadingAllDocs()) return <ChartLoader />;

        const data = [['Status', 'Document Count']];

        const docStatusCount = allDocs.reduce((prev, doc) => {
            if (doc.status === ADD_ON_STATUS.APPROVED || doc.status === DOC_REVIEW_STATUS.COMPLETE) {
                const count = prev['Approved/Completed'];
                prev['Approved/Completed'] = count ? prev['Approved/Completed'] + 1 : 1;
            } else {
                const count = prev[doc.status];
                prev[doc.status] = count ? prev[doc.status] + 1 : 1;
            }

            return prev;
        }, {});

        Object.keys(docStatusCount).forEach(status => data.push([renderLabels(status), docStatusCount[status]]));

        const options = {
            title: 'Overall Documents Status',
            pieHole: 0.4,
        };

        return <Chart chartType="PieChart" width="100%" height="400px" data={data} options={options} />;
    };

    const renderDocTable = () => {
        if (isLoadingAllDocs()) return <DataTableLoader />;
        return <DocDataTable allDocs={allDocs} canViewDetail={!isAccountRole} />;
    };

    const renderMilestones = () => {
        return milestones.map(item => (
            <div key={item.name} className={classes.milestoneItem}>
                {item.date ? (
                    <VerifiedUser className={cx(classes.milestoneIcon, classes.milestoneAchieved)} />
                ) : (
                    <Timelapse className={classes.milestoneIcon} />
                )}
                <Typography variant="h6">{item.name}</Typography>
                {item.date ? <Typography variant="subtitle1">{dateFormate(item.date, DATE_FORMAT)}</Typography> : null}
                {milestoneLogs && milestoneLogs[item.name] && milestoneLogs[item.name].length > 0 && (
                    <Button color="secondary" onClick={() => handleMilestoneLogsClick(item.name)}>
                        {milestoneLogs[item.name].length} logs
                    </Button>
                )}
            </div>
        ));
    };

    return (
        <div>
            <SubHeader>
                Project Summary{' '}
                {currentProjectNotes && (
                    <PopoverComment
                        content={currentProjectNotes}
                        status={currentProjectStatus.includes('Rejected') ? APPROVAL_STATUS.REJECTED : ''}
                    />
                )}
            </SubHeader>
            Project Lead:{' '}
            <Chip
                icon={<PersonIcon />}
                color="primary"
                style={{ backgroundColor: '#7986cc' }}
                label={currentProjectLeadName}
            />{' '}
            Assigned RA Staff:{' '}
            <Chip
                icon={<PersonIcon />}
                color="primary"
                style={{ backgroundColor: '#7986cc' }}
                label={currentAssignedStaffName}
            />
            <main className={classes.main}>
                {!isAccountRole && (
                    <section>
                        <Paper className={classes.paper}>
                            <SectionHeader>Milestones</SectionHeader>
                            <div className={classes.milestoneWrapper}>{renderMilestones()}</div>
                        </Paper>
                    </section>
                )}
                {!isAccountRole && (
                    <section className={classes.docChartSection}>
                        <Paper className={classes.paper}>{renderChart()}</Paper>
                    </section>
                )}
                <section className={classes.docListSection}>{renderDocTable()}</section>
                {!isAccountRole && (
                    <section className={classes.logTableSection} id="log-section">
                        <TextField
                            id="milestone-filter"
                            select
                            label="Filter Logs By Milestone"
                            className={classes.milestoneSelect}
                            value={milestone}
                            onChange={handleMilestoneSelectChange}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {milestones
                                .filter(m => m.date)
                                .map(m => (
                                    <MenuItem key={m.name} value={m.name}>
                                        {m.name}
                                    </MenuItem>
                                ))}
                        </TextField>

                        <LogEntryTable logs={currentLogs} title={`Logs For ${milestone || 'Entire Project'}`} />
                    </section>
                )}
            </main>
        </div>
    );
};

ProjectSummary.propTypes = {
    currentProjectId: PropTypes.number,
    getLogEntriesForCurrentProject: PropTypes.func,
    history: PropTypes.object,
    milestones: PropTypes.array,
    getProjectMilestones: PropTypes.func,
    onGoingActions: PropTypes.array,
    currentProjectNotes: PropTypes.string,
    currentProjectStatus: PropTypes.string,
    currentProjectLeadName: PropTypes.string,
    currentAssignedStaffName: PropTypes.string,
    currentProjectDocumentsWithFile: PropTypes.array,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    currentProjectNotes: projectNoteSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    currentProjectLeadName: projectLeadNameSelector(state),
    currentAssignedStaffName: assignedStaffNameSelector(state),
    milestones: projectMilestonesSelector(state),
    logs: logsSelector(state),
    tags: tagsSelector(state),
    onGoingActions: onGoingActionsSelector(state),
    currentProjectDocumentsWithFile: projectDocumentsWithFileSelector(state),
    currentUser: currentUserSelector(state),
});

export default connect(mapStateToProps, {
    getLogEntriesForCurrentProject,
    setCurrentDoc,
    getProjectMilestones,
    getProjectDocumentsWithFile,
})(ProjectSummary);
