import _ from 'lodash';
import { createSelector } from 'reselect';
import { DOC_REVIEW_STATUS } from '../../constants';

export const predefinedDocsSelector = state => _.get(state, 'review.docs.predefinedDocs', []);

export const currentProjectDocReviewsSelector = state => _.get(state, 'review.docs.currentProjectDocReviews', []);

export const completedDocReviewSelector = createSelector(currentProjectDocReviewsSelector, docs =>
    docs.filter(doc => doc.status === DOC_REVIEW_STATUS.COMPLETE)
);

export const reviewAssignmentsSelector = state => _.get(state, 'review.assignments', {});

export const currentDocIdSelector = state => _.get(state, 'review.docs.selectedDocId');

export const currentDocAssignmentsSelector = createSelector(
    currentDocIdSelector,
    reviewAssignmentsSelector,
    (docId, assignments) => assignments[docId] || []
);

export const currentDocSelector = createSelector(
    currentDocIdSelector,
    currentProjectDocReviewsSelector,
    (docId, docs) => docs.find(doc => doc.id === docId)
);

export const currentActiveAssignmentSelector = createSelector(currentDocAssignmentsSelector, assignments =>
    assignments.find(item => item.isActive)
);

export const lastItemActiveSelector = createSelector(currentDocAssignmentsSelector, assignments =>
    _.isEmpty(assignments) ? false : _.last(assignments).isActive
);

export const timelineNodesSelector = state => _.get(state, 'review.timeline', []);
