import React from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles } from '@material-ui/core';
import cx from 'classnames';

import { SubHeader } from '.';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    container: {
        flex: 1,
    },
});
const SideSection = ({ classes, title, children, className }) => {
    return (
        <section className={cx(classes.layout, className)}>
            <SubHeader title={title} />
            <Paper className={classes.container} elevation={1}>
                {children}
            </Paper>
        </section>
    );
};

SideSection.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(SideSection);
