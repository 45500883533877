const styles = theme => ({
    form: {
        width: '100%',
    },
    formSection: {
        margin: '16px 10px 48px',
    },
    formGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
    },
    formFooter: {
        textAlign: 'right',
    },
});

export default styles;
