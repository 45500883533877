import qs from 'qs';
import httpClient from '../lib/httpClient';
import { generateQueryStringByFilters } from '../lib/utils';

const client = new httpClient();
const projectApiUrl = 'projects/';
const formFieldsUrl = 'forms/project/';
const referenceUrl = 'references/';

const fetchAllProjects = () => client.get(projectApiUrl);
const getReferences = () => client.get(referenceUrl);

const getFormFields = (name = '') => client.get(`${formFieldsUrl}${name}`);
const createProject = (name, identifier) => client.post(projectApiUrl, { name, identifier });
const getProjectFields = (projectId, portion = '') => client.get(`${projectApiUrl}${projectId}/${portion}`);

const updateProjectFormFieldsByStep = (projectId, step, fields) =>
    client.put(`${projectApiUrl}${projectId}/${step}`, fields);

const updateProjectStatus = (projectId, status, notes = '') =>
    client.put(`${projectApiUrl}${projectId}/status`, { status, notes });

const searchProjects = searchQuery => {
    const queryString = qs.stringify(searchQuery, { addQueryPrefix: true, skipNulls: true });
    return client.get(`${projectApiUrl}search/${queryString}`);
};

const filterProjectsBy = filters => {
    const queryString = generateQueryStringByFilters(filters);
    return client.get(`${projectApiUrl}${queryString}`);
};

const assignProjectTo = (projectId, userId) => client.put(`${projectApiUrl}${projectId}/assignedStaff`, { userId });

const uploadFileForProjectField = (projectId, fieldName, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return client.post(`uploads/${projectApiUrl}${projectId}/${fieldName}`, data, true);
};

const downloadFile = path => client.post('downloads', { url: path }, false, 'blob');

const fetchProjectMilestones = projectId => client.get(`${projectApiUrl}${projectId}/milestones`);

const deleteProjectMilestone = id => client.delete(`projectMilestones/${id}`);

const fetchProjectECRs = projectId => client.get(`${projectApiUrl}${projectId}/ECRs`);

const postProjectECR = (projectId, ecr) => client.post(`${projectApiUrl}${projectId}/ECRs`, ecr);

const updateProjectECR = (ecrId, ecr) => client.put(`ProjectECRs/${ecrId}`, ecr);

const deleteProjectECR = ecrId => client.delete(`ProjectECRs/${ecrId}`);

const downloadProjectECR = projectId => client.get(`ProjectECRs/download/${projectId}`, 'blob');

const certifyECR = (ecrId, record) => client.put(`ProjectECRs/${ecrId}/certify`, record);

const uncertifyECR = ecrId => client.put(`ProjectECRs/${ecrId}/uncertify`);

const actionECR = (ecrId, record) => client.put(`ProjectECRs/${ecrId}/actions`, record);

const fetchAllDocumentsWithFileByProject = projectId => client.get(`projects/${projectId}/documentsWithFile`);

export const projectService = {
    fetchAllProjects,
    getReferences,
    assignProjectTo,
    filterProjectsBy,
    getFormFields,
    createProject,
    getProjectFields,
    updateProjectFormFieldsByStep,
    updateProjectStatus,
    searchProjects,
    uploadFileForProjectField,
    downloadFile,
    fetchProjectMilestones,
    deleteProjectMilestone,
    fetchProjectECRs,
    postProjectECR,
    updateProjectECR,
    deleteProjectECR,
    downloadProjectECR,
    certifyECR,
    uncertifyECR,
    actionECR,
    fetchAllDocumentsWithFileByProject,
};
