import { handleActions } from 'redux-actions';
import { GET_TAGS } from '../actions/types';

const initialState = [];

const reducer = handleActions(
    {
        [GET_TAGS]: (state, { payload }) => payload,
    },
    initialState
);

export default reducer;
