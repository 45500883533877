const drawerWidth = 340;
const styles = theme => ({
    drawer: {
        position: 'relative',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        height: '100vh',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        '& $link.sub': {
            marginLeft: theme.spacing(4),
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    navToggle: {
        position: 'absolute',
        top: '40vh',
        zIndex: 10,
        right: '-16px',
    },
    list: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        display: 'block',
        marginTop: theme.spacing(1.1),
        marginBottom: theme.spacing(1.1),
        '&.active': {
            background: theme.palette.primary.light,
            '& $text,& $subText,& $icon': {
                color: theme.palette.primary.contrastText,
            },
        },
    },
    icon: {
        '&.sub': {
            fontSize: '1.1rem',
        },
    },
    text: {},
    subText: {
        fontSize: '.9rem',
    },
});

export default styles;
