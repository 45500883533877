const styles = theme => ({
    title: {
        flexGrow: 1,
        display: 'flex',
    },
    status: {
        marginLeft: theme.spacing(1),
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    search: {
        minWidth: theme.spacing(35),
        color: theme.palette.text.primary,
    },
});

export default styles;
