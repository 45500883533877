import { Button, IconButton, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import dateFormat from 'date-fns/format';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ConfirmDialog, EmptyState, FormDialog, SubHeader } from '../../components';
import ConfirmActionButton from '../../components/ConfirmActionButton';
import { APP_PATHS, DATE_FORMAT, USER_ROLES } from '../../constants';
import { checkUserRole } from '../../lib/utils';
import { buildProjectPath } from '../../routes/helper';
import { getProjectAddOns } from '../addon/actions';
import { completedAddOnsSelector } from '../addon/selectors';
import { projectIdSelector } from '../project/selectors';
import { getProjectDocReviews } from '../review/actions';
import { completedDocReviewSelector } from '../review/selectors';
import { currentUserSelector } from '../user/selectors';
import {
    actionECR,
    certifyECR,
    uncertifyECR,
    createECR,
    deleteECRs,
    exportECRs,
    getProjectECRs,
    updateECR,
} from './actions';
import RecurringSection from './components/RecurringSection';
import { ecrsSelector } from './selectors';

const useStyles = makeStyles(theme => ({
    recordTable: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const DIALOG_MODE = {
    NEW: 'new',
    EDIT: 'edit',
    CERTIFY: 'certify',
    ACTION: 'action',
};

const STANDARD_CONSTRUCTION_MEASURES = 'Standard Construction Measures';

const ECR = ({
    getProjectECRs,
    ecrs,
    currentUser,
    currentProjectId,
    createECR,
    deleteECRs,
    exportECRs,
    certifyECR,
    uncertifyECR,
    getProjectAddOns,
    getProjectDocReviews,
    completedAddOns,
    completedDocuments,
    actionECR,
    updateECR,
}) => {
    const classes = useStyles();
    const [currentRecord, setCurrentRecord] = useState(null);
    const [ecrsToDelete, setECRsToDelete] = useState(null);
    const [dialogMode, setDialogMode] = useState(null);
    const match = useRouteMatch({ path: `${APP_PATHS.ECR}/:ecrId/:action`, strict: false, sensitive: false });
    const history = useHistory();

    useEffect(() => {
        if (currentProjectId) {
            getProjectECRs();
            getProjectAddOns();
            getProjectDocReviews();
        }
    }, [currentProjectId, getProjectAddOns, getProjectDocReviews, getProjectECRs]);

    const handleCertify = useCallback(
        ecrId => {
            if (currentRecord) return;
            if (checkUserRole(currentUser, USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER)) {
                const certifyRecord = {
                    id: ecrId,
                    dateCertified: new Date(),
                };
                setDialogMode(DIALOG_MODE.CERTIFY);
                setCurrentRecord(certifyRecord);
            }
        },
        [currentRecord, currentUser]
    );

    const handleAddActions = useCallback(
        ecrId => {
            if (currentRecord) return;
            if (checkUserRole(currentUser, USER_ROLES.PROJECT_CREATOR, USER_ROLES.DE_MANAGER)) {
                const record = ecrs.find(e => e.id === ecrId);
                if (record) {
                    const actionRecord = {
                        id: ecrId,
                        actions: record.actions || '',
                    };
                    setDialogMode(DIALOG_MODE.ACTION);
                    setCurrentRecord(actionRecord);
                }
            }
        },
        [currentRecord, currentUser, ecrs]
    );

    useEffect(() => {
        if (match && match.params.ecrId) {
            const id = parseInt(match.params.ecrId);
            switch (match.params.action) {
                case 'actions':
                    handleAddActions(id);
                    break;
                case 'certify':
                    handleCertify(id);
                    break;
                default:
                    return;
            }
        } else if (match && !match.params.action) {
            setCurrentRecord(null);
            setDialogMode(null);
        }
    }, [handleAddActions, handleCertify, match]);

    const handleNewRecordClick = () => {
        const newECR = {
            task: '',
            document: '',
            page: '',
            phase: '',
            remarks: '',
            actionsRequired: '',
            actions: '',
        };
        setDialogMode(DIALOG_MODE.NEW);
        setCurrentRecord(newECR);
    };
    const handleExport = () => exportECRs();

    const handleECRSubmit = async ecr => {
        if (dialogMode === DIALOG_MODE.CERTIFY) {
            await certifyECR(currentRecord.id, _.omit(ecr, 'id'));
        } else if (dialogMode === DIALOG_MODE.ACTION) {
            await actionECR(currentRecord.id, _.omit(ecr, 'id'));
        } else {
            const ecrToSubmit =
                ecr.recurringMonth <= 0 || ecr.recurringTimes <= 0
                    ? _.omit(ecr, ['recurringMonth', 'recurringTimes'])
                    : ecr;
            dialogMode === DIALOG_MODE.EDIT
                ? await updateECR(currentRecord.id, ecrToSubmit)
                : await createECR(ecrToSubmit);
        }
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setDialogMode(null);
        setCurrentRecord(null);
        if (match && match.params.action) {
            history.push(buildProjectPath(currentProjectId, APP_PATHS.ECR));
        }
    };

    const handleUncertify = useCallback(
        recordId => async () => {
            await uncertifyECR(recordId);
        },
        [uncertifyECR]
    );

    const handleECRsDelete = selectedRows => {
        const toDeleteData = ecrs.filter((ecr, index) => selectedRows.data.map(d => d.dataIndex).includes(index));
        setECRsToDelete(toDeleteData);
    };

    const handleCloseConfirmDialog = confirmed => () => {
        if (confirmed && ecrsToDelete) {
            deleteECRs(ecrsToDelete.map(r => r.id));
        }
        setECRsToDelete(null);
    };

    const handleECREdit = recordIndex => {
        if (!checkUserRole(currentUser, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST)) return;
        const currentECR = ecrs[recordIndex];
        setDialogMode(DIALOG_MODE.EDIT);
        setCurrentRecord(currentECR);
    };

    const checkECRSelectable = recordIndex =>
        checkUserRole(currentUser, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST) &&
        _.isEmpty(ecrs[recordIndex].dateCertified);

    const setUpRulesByField = field => {
        if (field === 'actions' && dialogMode === DIALOG_MODE.ACTION) return ['required'];
        if (['actions', 'remarks', 'endDate'].includes(field)) return [];
        return ['required'];
    };

    const renderECRDialog = () => {
        let fields = [];
        let title;
        if (currentRecord) {
            fields = Object.keys(currentRecord).map(key => {
                let type;
                let label;
                let options;
                switch (key) {
                    case 'actionsRequired':
                        label = 'Actions Required';
                        type = 'textarea';
                        break;
                    case 'actions':
                        label = 'Actions Taken';
                        type = 'textarea';
                        break;
                    case 'task':
                    case 'remarks':
                        type = 'textarea';
                        break;
                    case 'startDate':
                    case 'endDate':
                    case 'dateCertified':
                        type = 'date';
                        break;
                    case 'document':
                        type = 'select';
                        options = [
                            { label: '', value: '' },
                            { label: STANDARD_CONSTRUCTION_MEASURES, value: STANDARD_CONSTRUCTION_MEASURES },
                        ].concat(
                            _.sortBy(
                                [...completedAddOns, ...completedDocuments].map(doc => ({
                                    label: doc.name,
                                    value: doc.name,
                                })),
                                'value'
                            )
                        );

                        break;
                    default:
                        type = 'text';
                }
                return {
                    name: key,
                    label,
                    type,
                    options,
                    rules: setUpRulesByField(key),
                };
            });
        }
        switch (dialogMode) {
            case DIALOG_MODE.NEW:
                title = 'Create ECR Record';
                break;
            case DIALOG_MODE.EDIT:
                title = `Edit ECR Record (ID ${currentRecord.id})`;
                //added by Xi to filter out fields that should not be displayed during edit
                var fieldsToDisplay = [
                    'task',
                    'actions',
                    'actionsRequired',
                    'remarks',
                    'endDate',
                    'document',
                    'phase',
                    'page',
                ];
                fields = fields.filter(el => fieldsToDisplay.includes(el.name));
                break;
            case DIALOG_MODE.CERTIFY:
                title = `Certify ECR Record (ID ${currentRecord.id})`;
                break;
            case DIALOG_MODE.ACTION:
                title = `Input Actions For ECR Record (ID ${currentRecord.id})`;
                break;
            default:
                title = '';
        }

        const renderRecurringSection = () => (dialogMode === DIALOG_MODE.NEW ? <RecurringSection /> : null);

        return (
            <FormDialog
                title={title}
                subTitle={'* Fields are required.'}
                formObject={currentRecord}
                fields={fields}
                open={!_.isEmpty(currentRecord)}
                onClose={handleCloseDialog}
                onSubmit={handleECRSubmit}
                renderCustomSection={renderRecurringSection}
            />
        );
    };

    const renderActionButton = recordIndex => {
        const record = ecrs[recordIndex];
        if (record.dateCertified)
            return (
                <ConfirmActionButton
                    buttonText="Uncertify"
                    dialogTitle="Confirm un-certify ECR?"
                    onConfirmAction={handleUncertify(record.id)}
                />
            );
        if (checkUserRole(currentUser, USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER) && !_.isEmpty(record.actions))
            return (
                <Button variant="outlined" color="secondary" onClick={() => handleCertify(record.id)}>
                    Certify
                </Button>
            );

        if (checkUserRole(currentUser, USER_ROLES.PROJECT_CREATOR, USER_ROLES.DE_MANAGER))
            return (
                <Button variant="outlined" color="secondary" onClick={() => handleAddActions(record.id)}>
                    Actions
                </Button>
            );

        return null;
    };

    const renderEditButton = recordIndex => {
        const record = ecrs[recordIndex];
        if (record.dateCertified) return null;
        if (checkUserRole(currentUser, USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER))
            return (
                <IconButton onClick={() => handleECREdit(recordIndex)}>
                    <Edit />
                </IconButton>
            );
        return null;
    };

    const renderECRTable = () => {
        if (_.isEmpty(ecrs)) return <EmptyState content="Start entering records by clicking New Record Button" />;

        const columns = [
            'ID',
            'Task',
            'Due Date',
            'Document',
            'Page',
            'Phase',
            'Actions Required',
            'Actions Taken',
            'Certified By',
            'Certified Date',
            'Remarks',
            {
                name: 'Edit',
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => renderEditButton(tableMeta.rowIndex),
                },
            },
            {
                name: '',
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => renderActionButton(tableMeta.rowIndex),
                },
            },
        ];
        const data = ecrs.map(
            ({
                task,
                endDate,
                document,
                page,
                phase,
                actionsRequired,
                actions,
                certifiedByFullName,
                dateCertified,
                remarks,
                id,
            }) => [
                id,
                task,
                endDate !== null ? dateFormat(endDate, DATE_FORMAT) : '',
                document,
                page,
                phase,
                actionsRequired,
                actions,
                certifiedByFullName,
                dateCertified ? dateFormat(dateCertified, DATE_FORMAT) : null,
                remarks,
            ]
        );

        const options = {
            filterType: 'multiselect',
            print: false,
            onRowsDelete: handleECRsDelete,
            responsive: 'scrollFullHeight',
            isRowSelectable: checkECRSelectable,
        };

        return <MUIDataTable columns={columns} data={data} options={options} title="All ECR" />;
    };

    return (
        <div>
            <SubHeader title="Environmental Commitment Records" />
            <main>
                {checkUserRole(currentUser, USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER) && (
                    <section>
                        <Button color="primary" variant="contained" onClick={handleNewRecordClick}>
                            New Record
                        </Button>
                    </section>
                )}
                <section className={classes.recordTable}>{renderECRTable()}</section>
                <section className={classes.footer}>
                    {!_.isEmpty(ecrs) && (
                        <Button color="primary" variant="contained" onClick={handleExport}>
                            Export EXCEL
                        </Button>
                    )}
                </section>
            </main>
            {renderECRDialog()}
            <ConfirmDialog
                open={!_.isEmpty(ecrsToDelete)}
                onCloseDialog={handleCloseConfirmDialog}
                title="Are you sure to delete the selected ECRs?"
                content="Once confirmed the deletion, the ecrs will be permanently removed from database. "
            />
        </div>
    );
};

const mapStateToProps = state => ({
    ecrs: ecrsSelector(state),
    currentUser: currentUserSelector(state),
    currentProjectId: projectIdSelector(state),
    completedDocuments: completedDocReviewSelector(state),
    completedAddOns: completedAddOnsSelector(state),
});

ECR.propTypes = {
    ecrs: PropTypes.array,
    currentUser: PropTypes.object,
    getProjectECRs: PropTypes.func,
    currentProjectId: PropTypes.number,
    createECR: PropTypes.func,
    exportECRs: PropTypes.func,
    deleteECRs: PropTypes.func,
    certifyECR: PropTypes.func,
    uncertifyECR: PropTypes.func,
    getProjectAddOns: PropTypes.func,
    getProjectDocReviews: PropTypes.func,
    updateECR: PropTypes.func,
};

export default connect(mapStateToProps, {
    getProjectDocReviews,
    getProjectECRs,
    createECR,
    exportECRs,
    deleteECRs,
    certifyECR,
    uncertifyECR,
    getProjectAddOns,
    actionECR,
    updateECR,
})(ECR);
