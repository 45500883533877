import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withStyles, IconButton, Typography, Fab } from '@material-ui/core';
import { CloudUpload, Assignment, Done, Clear, CloudDone } from '@material-ui/icons';
import { isAfter } from 'date-fns';

import { ReviewDialog } from '.';
import { APPROVAL_STATUS, DOC_REVIEW_STATUS } from '../../../constants';
import styles from './styles/timeline';
import {
    timelineNodesSelector,
    currentDocSelector,
    currentActiveAssignmentSelector,
    lastItemActiveSelector,
} from '../selectors';
import { saveTimelineNode, getReviewAssignments, updateReviewStatus } from '../actions';
import { currentUserSelector, allUsersSelector } from '../../user/selectors';
import { fetchProjectInfo } from '../../project/actions';

export const Timeline = ({
    classes,
    timelineNodes,
    currentUser,
    currentDoc,
    saveTimelineNode,
    activeAssignment,
    getReviewAssignments,
    updateReviewStatus,
    isLastAssignmentActive,
    fetchProjectInfo,
    allUsers,
}) => {
    const [showReviewModel, setShowReviewModel] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);

    const getUserFullNameById = id => allUsers.find(u => u.id === id).fullName;

    const handleSubmitTimelineNode = async node => {
        const success = await saveTimelineNode(node);
        if (success) {
            await getReviewAssignments(currentDoc.id);
            if (isLastAssignmentActive && node.action === APPROVAL_STATUS.APPROVED) {
                updateReviewStatus({ docId: currentDoc.id, status: DOC_REVIEW_STATUS.COMPLETE });
                fetchProjectInfo();
            }
        }
        setSelectedNode(null);
        setShowReviewModel(false);
    };

    const handleNodeClick = node => () => {
        setSelectedNode(node);
        setShowReviewModel(true);
    };

    const handleDialogClose = () => {
        setShowReviewModel(false);
        setSelectedNode(null);
    };

    const renderNodeIconButton = node => {
        let icon;
        let cssClass;
        let isPrimary;
        switch (node.action) {
            case APPROVAL_STATUS.APPROVED:
                isPrimary = false;
                icon = <Done />;
                cssClass = classes.approveBtn;
                break;
            case APPROVAL_STATUS.REJECTED:
                isPrimary = false;
                icon = <Clear />;
                cssClass = classes.rejectBtn;
                break;
            default:
                isPrimary = true;
                icon = <Assignment />;
        }

        return (
            <Fab
                size="small"
                color={isPrimary ? 'primary' : 'default'}
                className={cssClass}
                onClick={handleNodeClick(node)}
            >
                {icon}
            </Fab>
        );
    };

    const renderCloudButton = () => {
        if (currentDoc && currentDoc.status === DOC_REVIEW_STATUS.COMPLETE)
            return (
                <IconButton disableRipple>
                    <CloudDone className={cx(classes.cloudButton, classes.cloudDone)} />
                </IconButton>
            );

        const isActive = activeAssignment && activeAssignment.assignedUserId === currentUser.userId;

        return (
            <IconButton disableRipple disabled={!isActive} color="primary" onClick={() => setShowReviewModel(true)}>
                <CloudUpload className={classes.cloudButton} />
            </IconButton>
        );
    };

    const renderDate = node => {
        const isOverdue = isAfter(node.dateSubmitted, node.endDate);
        return (
            <div className={cx(classes.nodeDate, { overdue: isOverdue })}>
                <Typography variant="body1">{node.dateSubmitted}</Typography>
                {isOverdue && <Typography variant="body1">Overdue</Typography>}
            </div>
        );
    };

    return (
        <div className={classes.layout}>
            {renderCloudButton()}
            <ul className={classes.list}>
                {timelineNodes.map(node => (
                    <li key={node.id} className={classes.node}>
                        {renderDate(node)}
                        {renderNodeIconButton(node)}
                        <Typography variant="subtitle2" align="right" className={classes[node.role]}>
                            {getUserFullNameById(node.submittedById)}
                        </Typography>
                    </li>
                ))}
            </ul>
            <ReviewDialog
                open={showReviewModel}
                onClose={handleDialogClose}
                onSubmitTimelineNode={handleSubmitTimelineNode}
                timelineNode={selectedNode}
                activeAssignment={activeAssignment}
            />
        </div>
    );
};

Timeline.propTypes = {
    classes: PropTypes.object,
    timelineNodes: PropTypes.array,
    currentUser: PropTypes.object,
    currentDoc: PropTypes.object,
    saveTimelineNode: PropTypes.func,
    activeAssignment: PropTypes.object,
    getReviewAssignments: PropTypes.func,
    updateReviewStatus: PropTypes.func,
    isLastAssignmentActive: PropTypes.bool,
    fetchProjectInfo: PropTypes.func,
    allUsers: PropTypes.array,
};

const mapStateToProps = state => ({
    timelineNodes: timelineNodesSelector(state),
    currentUser: currentUserSelector(state),
    currentDoc: currentDocSelector(state),
    activeAssignment: currentActiveAssignmentSelector(state),
    isLastAssignmentActive: lastItemActiveSelector(state),
    allUsers: allUsersSelector(state),
});

export default compose(
    connect(mapStateToProps, { saveTimelineNode, getReviewAssignments, updateReviewStatus, fetchProjectInfo }),
    withStyles(styles)
)(Timeline);
