import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button, withStyles, Grid } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';

import { BillTable } from '../components';
import styles from './styles/feeSummary';
import { PROJECT_STATUS, USER_ROLES, PROJECT_PORTION, APP_PATHS, ALERT_LEVEL } from '../../../constants';
import { getFeeItems } from '../actions';
import {
    projectIdSelector,
    projectStatusSelector,
    getProjectFieldsWithValue,
    projectLeadIdSelector,
} from '../../project/selectors';
import { feeRatesSelector, feeBillsSelector } from '../selectors';
import { ApprovalButtonGroup, ConfirmDialog, FieldGrid, SectionHeader } from '../../../components';
import { checkUserRole } from '../../../lib/utils';
import { updateProjectStatus } from '../../project/actions';
import { setAlert, showSpinner, hideSpinner } from '../../ui/actions';
import { currentUserSelector } from '../../user/selectors';
import { feeService } from '../../../services';

const FeeSummary = ({
    classes,
    currentBills,
    rates,
    currentProjectId,
    currentProjectStatus,
    getFeeItems,
    user,
    feeInitialInfo,
    updateProjectStatus,
    setAlert,
    projectLeadId,
    showSpinner,
    hideSpinner,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [redirect, setRedirect] = useState('');

    useEffect(() => {
        if (currentProjectId) {
            getFeeItems(currentProjectId);
        }
    }, [currentProjectId, getFeeItems]);

    const isRaManager = checkUserRole(user, USER_ROLES.RA_MANAGER);
    const isProjectLead = user.userId === projectLeadId;

    const handleExportSummary = async () => {
        showSpinner();
        const reportHTML = await feeService.exportSummary(currentProjectId);
        hideSpinner();
        const features = 'width=1000, height=1200, left=200, top=100';
        const externalWindow = window.open('', '', features);
        externalWindow.document.write(reportHTML);
    };

    const handleCloseConfirmDialog = confirmed => async () => {
        if (confirmed) {
            await updateProjectStatus(currentProjectId, PROJECT_STATUS.FEE_SUBMITTED);
        }
        setShowDialog(false);
    };

    const handleApprove = async (approved, notes) => {
        let approveStatus = '';
        let rejectStatus = '';
        if (isRaManager) {
            approveStatus = PROJECT_STATUS.FEE_APPROVED;
            rejectStatus = PROJECT_STATUS.FEE_REJECTED;
        }
        if (isProjectLead) {
            approveStatus = PROJECT_STATUS.FEE_ACCEPTED;
        }
        const success = await updateProjectStatus(currentProjectId, approved ? approveStatus : rejectStatus, notes);
        if (success) {
            let variant = ALERT_LEVEL.SUCCESS;
            let message = '';
            if (!approved) {
                variant = ALERT_LEVEL.WARNING;
                message = 'The fee proposal is rejected and an email will be sent to the specialist to redo.';
            } else {
                if (isRaManager) {
                    message = 'The fee proposal is approved and wait for project lead to accept it';
                }
                if (isProjectLead) {
                    message = 'The fee proposal is accepted and document review process could start.';
                }
            }

            setAlert({
                variant,
                message,
            });
            if (isRaManager) {
                setRedirect(APP_PATHS.DASHBOARD);
            }
        }
    };

    const renderFooter = () => {
        if (currentProjectStatus === PROJECT_STATUS.FEE_SUBMITTED && isRaManager)
            return <ApprovalButtonGroup onApproveOrReject={handleApprove} />;
        if (currentProjectStatus === PROJECT_STATUS.FEE_APPROVED && isProjectLead)
            return <ApprovalButtonGroup noReject onApproveOrReject={handleApprove} />;
        if ([PROJECT_STATUS.FEE_PENDING, PROJECT_STATUS.FEE_REJECTED].includes(currentProjectStatus))
            return (
                <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}>
                    Submit
                </Button>
            );
    };
    if (redirect) return <Redirect to={redirect} />;
    if (_.isEmpty(feeInitialInfo)) return null;
    return (
        <div>
            <Paper className={classes.paper}>
                {feeInitialInfo.map(({ groupLabel, fields }) => (
                    <Grid key={groupLabel} container direction="column">
                        <SectionHeader>{groupLabel}</SectionHeader>
                        <Grid container spacing={2}>
                            {fields.map(({ label, name, currentValue }) => {
                                if (!currentValue) return null;
                                if (_.isBoolean(currentValue) && currentValue) currentValue = 'yes';
                                return <FieldGrid key={name} field={{ label, currentValue }} />;
                            })}
                        </Grid>
                    </Grid>
                ))}
            </Paper>
            <Paper className={classes.paper}>
                <SectionHeader>Fee Proposal Details</SectionHeader>
                <BillTable currentBills={currentBills} rates={rates} />
                <Grid container justify="flex-end">
                    {[
                        PROJECT_STATUS.FEE_ACCEPTED,
                        PROJECT_STATUS.FEE_APPROVED,
                        PROJECT_STATUS.DOC_REVIEW_PENDING,
                        PROJECT_STATUS.DOC_REVIEW_COMPLETE,
                    ].includes(currentProjectStatus) && (
                        <Button variant="contained" color="primary" onClick={handleExportSummary}>
                            Export
                        </Button>
                    )}

                    {renderFooter()}
                </Grid>
                <ConfirmDialog
                    open={showDialog}
                    onCloseDialog={handleCloseConfirmDialog}
                    title="Filled in all tasks?"
                    content="Once submitted the fee proposal, you will not be able to edit unless Regulatory
                    Affairs manager rejects the proposal. "
                />
            </Paper>
        </div>
    );
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    projectLeadId: projectLeadIdSelector(state),
    currentBills: feeBillsSelector(state),
    rates: feeRatesSelector(state),
    user: currentUserSelector(state),
    feeInitialInfo: _.get(getProjectFieldsWithValue(state), PROJECT_PORTION.FEE_INITIAL_SCREEN, []),
});

FeeSummary.propTypes = {
    currentProjectId: PropTypes.number,
    classes: PropTypes.object,
    currentBills: PropTypes.array,
    rates: PropTypes.array,
    getFeeItems: PropTypes.func,
    user: PropTypes.object,
    feeInitialInfo: PropTypes.array,
    updateProjectStatus: PropTypes.func,
    history: PropTypes.object,
};

export default compose(
    withRouter,
    connect(mapStateToProps, { getFeeItems, updateProjectStatus, setAlert, showSpinner, hideSpinner }),
    withStyles(styles)
)(FeeSummary);
