import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { FirstPage, LastPage } from '@material-ui/icons';

import styles from './styles/sidebar';
import { authService } from '../services';
import { buildProjectPath } from '../routes/helper';
import { PATH_STATUS_MAP } from '../constants';
import { SideBarLoader } from './SideBarLoader';

const Sidebar = ({ routes, projectId, projectStatus, classes, showSideBar, onSetSideBarStatus }) => {
    const primaryRoutes = routes.filter(r => r.primary);
    const secondaryRoutes = routes.filter(r => r.secondary);

    const LinkItem = ({ route, isSubLink }) => (
        <ListItem button={isSubLink}>
            <ListItemIcon>
                <Tooltip title={route.sidebarName} placement="top-end">
                    <route.icon
                        className={cx(classes.icon, {
                            sub: isSubLink,
                        })}
                    />
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={route.sidebarName}
                classes={{
                    primary: isSubLink ? classes.subText : classes.text,
                }}
            />
        </ListItem>
    );

    const renderLinkItem = (route, projectRoute, isSubLink) => {
        if (!authService.isAuthorized(route.roles)) return null;
        if (projectStatus && !PATH_STATUS_MAP[route.path].includes(projectStatus)) return null;

        if (route.routes) {
            return <LinkItem route={route} isSubLink={isSubLink} />;
        }
        return (
            <NavLink to={projectRoute} className={cx(classes.link, { sub: isSubLink })}>
                <LinkItem route={route} isSubLink={isSubLink} />
            </NavLink>
        );
    };

    const renderNavLinks = (routes, isSubLink) =>
        routes.map((route, key) => {
            if (route.redirect) return null;
            const projectRoute = buildProjectPath(projectId, route.path);
            return (
                <React.Fragment key={key}>
                    {renderLinkItem(route, projectRoute, isSubLink)}
                    {route.routes && renderNavLinks(route.routes, true)}
                </React.Fragment>
            );
        });

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: cx(classes.drawer, {
                    [classes.drawerOpen]: showSideBar,
                    [classes.drawerClose]: !showSideBar,
                }),
            }}
            open={showSideBar}
        >
            {projectId ? (
                <Fragment>
                    <Tooltip title={showSideBar ? 'Close Full Menus' : 'Open Full Menus'} placement="top-start">
                        <IconButton onClick={onSetSideBarStatus} color="primary" className={classes.navToggle}>
                            {showSideBar ? <FirstPage /> : <LastPage />}
                        </IconButton>
                    </Tooltip>
                    <List className={classes.list}>{renderNavLinks(primaryRoutes)}</List>
                    <Divider />
                    <List className={classes.list}>{renderNavLinks(secondaryRoutes)}</List>
                </Fragment>
            ) : (
                <SideBarLoader />
            )}
        </Drawer>
    );
};

Sidebar.propTypes = {
    routes: PropTypes.array.isRequired,
    classes: PropTypes.object,
    projectId: PropTypes.number,
    projectStatus: PropTypes.string,
    showSideBar: PropTypes.bool,
    onSetSideBarStatus: PropTypes.func,
};

export default withStyles(styles)(Sidebar);
