/* eslint-disable no-console */

import axios from 'axios';
import { authService } from '../services';
import { PROCESS_ERROR_CODE } from '../constants';
import ServerError from './ServerError';

export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default class httpClient {
    getAuthHeader = () => {
        const localAuth = authService.getLocalAuth();
        const authHeader = localAuth ? { Authorization: `Bearer  ${localAuth.token}` } : {};
        return authHeader;
    };

    getHeaders = isFormData => {
        const type = isFormData ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' };
        return { ...this.getAuthHeader(), ...type };
    };

    get = (url, responseType = 'json') => {
        const requestOptions = {
            url: `${BASE_URL}/${url}`,
            headers: this.getHeaders(),
            method: 'GET',
            responseType,
        };

        return this.handleRequest(requestOptions);
    };

    getHtml = url => {
        const type = { 'Content-Type': 'text/html' };
        const requestOptions = {
            url: `${BASE_URL}/${url}`,
            headers: { ...this.getAuthHeader(), ...type },
            method: 'GET',
            responseType: 'text',
        };

        return this.handleRequest(requestOptions);
    };

    post = (url, data, isFormData, responseType = 'json') => {
        const requestOptions = {
            url: `${BASE_URL}/${url}`,
            headers: this.getHeaders(isFormData),
            method: 'POST',
            data,
            responseType,
        };

        return this.handleRequest(requestOptions);
    };

    delete = url => {
        const requestOptions = {
            url: `${BASE_URL}/${url}`,
            headers: this.getHeaders(),
            method: 'DELETE',
        };

        return this.handleRequest(requestOptions);
    };

    put = (url, data, isFormData) => {
        const requestOptions = {
            url: `${BASE_URL}/${url}`,
            headers: this.getHeaders(isFormData),
            method: 'PUT',
            data,
        };

        return this.handleRequest(requestOptions);
    };

    handleRequest = async requestOptions => {
        try {
            const { data } = await axios(requestOptions);
            return data;
        } catch (error) {
            return this.handleError(error);
        }
    };

    //TODO: better to have error service to log errors
    handleError = error => {
        let message = 'Network request failed.';
        let code;
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            const { data } = error.response;
            if (data && data.errorCode >= PROCESS_ERROR_CODE) {
                message = data.detail || data.message;
                code = data.errorCode;
            }
            if (error.response.status === 403) {
                message = 'You do not have access to the resource.';
                if (data) {
                    message = data.detail || data.message || message;
                }
            }
            code = error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        throw new ServerError(code, message);
    };
}
