export const LABELS = {
    //DOC_REVIEW_STATUS
    pendingAssignment: 'Pending Assignment',
    inProgress: 'In Progress',
    complete: 'Complete',
    overdue: 'Overdue',
    pending: 'Pending',
    rejected: 'Rejected',
    approved: 'Approved',

    //DOC_REVIEW_STAGE
    INITIAL: 'Begin Environmental',
    SUBMITTAL: 'Application Submittal',
    STUDIES: 'Environmental Studies',
    ENVDOCUMENT: 'Environmental Document',
    ADDONS: 'AddOn',
    VERIFICATIONS: 'Verifications',

    //ADD_ON_TYPE
    PERMIT: 'Permit',
    ENTITLEMENT: 'Entitlement',
    SF_INTERNAL: 'SF Internal',
    PW_INTERNAL: 'Public Work Internal',
};

export const renderLabels = val => LABELS[val] || val;
