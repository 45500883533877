import { TableCell, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class EditableTableCell extends Component {
    static propTypes = {
        editable: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func,
        isNumber: PropTypes.bool,
    };

    render() {
        const { value, editable, onChange, isNumber } = this.props;

        return editable ? (
            <TableCell>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={value}
                    type={isNumber ? 'number' : 'text'}
                    onChange={onChange}
                    inputProps={{ min: '0' }}
                />
            </TableCell>
        ) : (
            <TableCell alight={isNumber ? 'right' : 'center'}>{value}</TableCell>
        );
    }
}

export default EditableTableCell;
