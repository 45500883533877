const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    footButtonsContainer: {
        textAlign: 'right',
        marginTop: theme.spacing(3),

        '& button': {
            marginLeft: theme.spacing(2),
        },
    },
});

export default styles;
