import _ from 'lodash';
import { createSelector } from 'reselect';

export const feeBillsSelector = state => _.get(state, 'fee.bills', []);
export const feeRatesSelector = state => _.get(state, 'fee.rates', []);
export const feeTasksSelector = state => _.get(state, 'fee.tasks', []);

export const otherTasksSelector = createSelector(feeBillsSelector, feeTasksSelector, (bills, tasks) =>
    tasks.filter(task => !bills.map(b => b.name).includes(task.name))
);
