import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ConfirmDialog from './ConfirmDialog';
import { useState } from 'react';

const ConfirmDeleteButton = ({ dialogTitle, dialogContent, onConfirmDelete }) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleCloseDialog = confirmed => async () => {
        if (confirmed) await onConfirmDelete();
        setShowConfirm(false);
    };

    return (
        <>
            <IconButton onClick={() => setShowConfirm(true)}>
                <Delete />
            </IconButton>
            <ConfirmDialog
                open={showConfirm}
                onCloseDialog={handleCloseDialog}
                title={dialogTitle}
                content={dialogContent}
            />
        </>
    );
};

ConfirmDeleteButton.propTypes = {
    dialogTitle: PropTypes.string.isRequired,
    dialogContent: PropTypes.string,
    onConfirmDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteButton;
