import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Fab } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'date-fns/format';
import { connect } from 'react-redux';
import { setCurrentLog } from '../actions';
import { projectIdSelector } from '../../project/selectors';

const useStyles = makeStyles(() => ({
    fab: {
        position: 'fixed',
        bottom: '15vh',
        right: '8vw',
        zIndex: '20',
    },
}));
const AddLogFab = ({ setCurrentLog, hide }) => {
    const classes = useStyles();
    const handleLogAdd = () => {
        setCurrentLog({
            subject: '',
            tags: [],
            notes: '',
            eventDate: dateFormat(Date.now(), 'MM/DD/YYYY'),
        });
    };

    if (hide) return null;

    return (
        <Tooltip title="New Log">
            <Fab color="secondary" className={classes.fab} onClick={handleLogAdd}>
                <Create />
            </Fab>
        </Tooltip>
    );
};

AddLogFab.propTypes = {
    setCurrentLog: PropTypes.func,
    hide: PropTypes.bool,
};

const mapStateToProps = state => ({
    hide: !projectIdSelector(state),
});

export default connect(mapStateToProps, { setCurrentLog })(AddLogFab);
