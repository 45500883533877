import { highlightRow, roleColors } from '../../../../components/styles';
import { amber } from '@material-ui/core/colors';

const styles = theme => ({
    tableContainer: {
        maxHeight: '50vh',
        overflow: 'auto',

        '& td,th': {
            textAlign: 'center',
        },
    },
    roleButton: {
        margin: theme.spacing(3),
        textTransform: 'uppercase',
    },
    addIcon: {
        marginRight: theme.spacing(1),
    },
    tableRow: {
        zIndex: '1',
        display: 'table-row',
    },
    activeRow: {
        backgroundColor: amber[100],
    },
    dndRow: {
        backgroundColor: theme.palette.grey[100],
        border: 'solid 1px #222',
    },
    dragHandle: {
        cursor: 'move',
    },
    author: {
        backgroundColor: roleColors.author,
    },
    reviewer: {
        backgroundColor: roleColors.reviewer,
    },
    approver: {
        backgroundColor: roleColors.approver,
    },
    footButton: {
        textAlign: 'right',
        padding: theme.spacing(2),
    },

    highlightRow: highlightRow(theme),
});

export default styles;
