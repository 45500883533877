import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import dateFormate from 'date-fns/format';
import dateParse from 'date-fns/parse';

import { SubHeader, SectionHeader, FormDialog, EmptyState, PopoverComment, FileController } from '../../../components';
import styles from './styles';
import {
    projectVerificationDocsSelector,
    currentVerificationDocIdSelector,
    VerificationDocTrackingRecordsSelector,
} from '../selectors';

import {
    getProjectVerificationDocs,
    setCurrentVerificationDoc,
    saveVerificationDocsToProject,
    saveVerificationDocTrackingRecordToDocument,
    getVerificationDocTrackingRecords,
    uploadVerificationDocTrackingFile,
    updateVerificationDocTrackingRecord,
    removeVerificationDocTrackingRecord,
} from '../actions';
import { DOC_REVIEW_STAGE, ADD_ON_STATUS, DATE_FORMAT } from '../../../constants';
import { projectIdSelector } from '../../project/selectors';
import { getAllReviewDocuments } from '../../review/actions';
import { DocList } from '../../review/components';
import useDocOptions from '../../review/hooks/useDocOptions';
import useDocIdQuery from '../../review/hooks/useDocIdQuery';
import { predefinedDocsSelector } from '../../review/selectors';
import { currentUserSelector } from '../../user/selectors';
import ConfirmDeleteButton from '../../../components/ConfirmDeleteButton';

const VerificationDocs = ({
    currentUser,
    classes,
    location,
    selectedVerificationDocId,
    getAllReviewDocuments,
    getProjectVerificationDocs,
    documents,
    currentProjectVerificationDocs,
    saveVerificationDocsToProject,
    setCurrentVerificationDoc,
    currentProjectId,
    saveVerificationDocTrackingRecordToDocument,
    getVerificationDocTrackingRecords,
    verificationDocTrackingRecords,
    uploadVerificationDocTrackingFile,
    removeVerificationDocTrackingRecord,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showRecordForm, setShowRecordForm] = useState(false);

    useEffect(() => {
        getAllReviewDocuments();
    }, [getAllReviewDocuments]);

    useEffect(() => {
        if (currentProjectId) getProjectVerificationDocs();
    }, [currentProjectId, getProjectVerificationDocs]);

    useDocIdQuery(location.search, currentProjectVerificationDocs, setCurrentVerificationDoc);

    useEffect(() => {
        if (selectedVerificationDocId) {
            getVerificationDocTrackingRecords();
        }
    }, [getVerificationDocTrackingRecords, selectedVerificationDocId]);

    const verificationDocs = useMemo(() => documents.filter(doc => doc.stage === DOC_REVIEW_STAGE.VERIFICATIONS), [
        documents,
    ]);

    const predefinedVerificationDocOptions = useDocOptions(verificationDocs, currentProjectVerificationDocs);

    const selectedDoc = useMemo(
        () => currentProjectVerificationDocs.find(doc => doc.id === selectedVerificationDocId),
        [currentProjectVerificationDocs, selectedVerificationDocId]
    );

    const handleSaveVerificationDocs = async () => {
        if (selectedOptions.length > 0) {
            const addedDocs = await saveVerificationDocsToProject(
                documents
                    .filter(doc => selectedOptions.some(op => op.value === doc.id))
                    .map(doc => _.pick(doc, ['name', 'stage', 'type']))
            );
            if (addedDocs) {
                setSelectedOptions([]);
            }
        }
    };

    const handleVerificationDocItemClick = verificationDocId => () => {
        if (verificationDocId !== selectedVerificationDocId) {
            setCurrentVerificationDoc(verificationDocId);
        }
    };

    const handleSubmitNewVerificationDoc = async newVerificationDoc => {
        const verificationDocs = await saveVerificationDocsToProject([
            { ...newVerificationDoc, stage: DOC_REVIEW_STAGE.VERIFICATIONS },
        ]);
        if (verificationDocs) {
            setCurrentVerificationDoc(verificationDocs[0].id);
        }
    };

    const handleRemoveProjectVerificationDoc = () => () => {};

    const handleAddTrackingRecord = () => {
        setShowRecordForm(true);
    };

    const handleSubmitTrackingRecord = async record => {
        await saveVerificationDocTrackingRecordToDocument(record);
        setShowRecordForm(false);
    };

    const handleUploadTrackingFile = async file => await uploadVerificationDocTrackingFile(file);

    const handleRemoveVerificationDocTrackingRecord = recordId => async () => {
        await removeVerificationDocTrackingRecord(recordId);
    };

    const renderTrackingTable = () => {
        if (selectedVerificationDocId < 0) {
            return <EmptyState content="Select an existing verification document or create a new one to start" />;
        }
        return (
            <Table className={classes.trackingTable}>
                {renderTableHead()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderTableHead = () => {
        const headers = ['Name', 'Email', 'Phone', 'Date', 'Status', 'File', 'Comments', ''];
        return (
            <TableHead>
                <TableRow className={classes.highlightRow}>
                    {headers.map((col, index) => (
                        <TableCell key={index}>{col}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTrackingField = (id, field, value) => {
        if (field === 'comments' && value) return <PopoverComment content={value} />;
        if (field === 'eventDate') {
            value = dateFormate(dateParse(value), DATE_FORMAT);
        }
        if (field === 'path') return <FileController filePath={value} />;
        return value;
    };

    const renderTableBody = () => {
        if (verificationDocTrackingRecords.length > 0) {
            const fields = ['contactName', 'contactEmail', 'contactPhone', 'eventDate', 'status', 'path', 'comments'];
            return (
                <TableBody>
                    {verificationDocTrackingRecords.map(record => (
                        <TableRow key={record.id}>
                            {fields.map(key => (
                                <TableCell key={key}>{renderTrackingField(record.id, key, record[key])}</TableCell>
                            ))}
                            <TableCell padding="checkbox">
                                <ConfirmDeleteButton
                                    dialogTitle="Confirm to delete the tracking record?"
                                    onConfirmDelete={handleRemoveVerificationDocTrackingRecord(record.id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
    };

    const renderRecordForm = () => {
        const formTitle = 'Save Verification Tracking Record';
        const newRecord = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            eventDate: '',
            status: '',
            comments: '',
            path: '',
        };
        const fields = Object.keys(newRecord).map(key => {
            let type = '';
            let options = [];
            let rules = ['required'];
            let onUpload = null;
            switch (key) {
                case 'contactEmail':
                    type = 'email';
                    break;
                case 'comments':
                    type = 'textarea';
                    rules = [];
                    break;
                case 'eventDate':
                    type = 'date';
                    break;
                case 'status':
                    type = 'select';
                    options = Object.values(ADD_ON_STATUS).map(value => ({ label: value, value }));
                    break;
                case 'path':
                    type = 'upload';
                    onUpload = handleUploadTrackingFile;
                    break;
                default:
                    type = 'text';
            }
            return {
                type,
                name: key,
                options,
                rules,
                onUpload,
            };
        });

        return (
            <FormDialog
                formObject={newRecord}
                title={formTitle}
                fields={fields}
                open={showRecordForm}
                onClose={() => setShowRecordForm(false)}
                onSubmit={handleSubmitTrackingRecord}
            />
        );
    };

    return (
        <div>
            <SubHeader title="Verification Documents" />
            <main className={classes.main}>
                <section className={classes.docListSection}>
                    <Paper className={classes.paper}>
                        <SectionHeader>Choose Documents</SectionHeader>
                        <DocList
                            predefinedDocOptions={predefinedVerificationDocOptions}
                            onSelectChange={options => setSelectedOptions(options)}
                            selectedOptions={selectedOptions}
                            selectedDocId={selectedVerificationDocId}
                            onAddDocs={handleSaveVerificationDocs}
                            currentProjectDocs={currentProjectVerificationDocs}
                            onDocItemClick={handleVerificationDocItemClick}
                            onSubmitNewDoc={handleSubmitNewVerificationDoc}
                            onRemoveProjectDoc={handleRemoveProjectVerificationDoc}
                            reviewStage={DOC_REVIEW_STAGE.VERIFICATIONS}
                            currentUser={currentUser}
                        />
                    </Paper>
                </section>
                <section className={classes.reviewTrackSection}>
                    <Paper className={classes.paper}>
                        <SectionHeader>{`${selectedDoc ? selectedDoc.name : ''} Status Track`}</SectionHeader>
                        <Button
                            color="primary"
                            onClick={handleAddTrackingRecord}
                            variant="contained"
                            disabled={selectedVerificationDocId < 0}
                        >
                            Add Tracking Record
                        </Button>
                        {renderTrackingTable()}
                    </Paper>
                </section>
            </main>
            {renderRecordForm()}
        </div>
    );
};

VerificationDocs.propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object.isRequired,
    selectedVerificationDocId: PropTypes.number,
    getAllReviewDocuments: PropTypes.func,
    getProjectVerificationDocs: PropTypes.func,
    documents: PropTypes.array,
    currentProjectVerificationDocs: PropTypes.array,
    saveVerificationDocsToProject: PropTypes.func,
    setCurrentVerificationDoc: PropTypes.func,
    saveVerificationDocTrackingRecordToDocument: PropTypes.func,
    getVerificationDocTrackingRecords: PropTypes.func,
    verificationDocTrackingRecords: PropTypes.array,
    uploadVerificationDocTrackingFile: PropTypes.func,
    updateVerificationDocTrackingRecord: PropTypes.func,
    removeVerificationDocTrackingRecord: PropTypes.func,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    documents: predefinedDocsSelector(state),
    currentProjectVerificationDocs: projectVerificationDocsSelector(state),
    selectedVerificationDocId: currentVerificationDocIdSelector(state),
    currentProjectId: projectIdSelector(state),
    currentUser: currentUserSelector(state),
    verificationDocTrackingRecords: VerificationDocTrackingRecordsSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        getAllReviewDocuments,
        getProjectVerificationDocs,
        saveVerificationDocsToProject,
        setCurrentVerificationDoc: setCurrentVerificationDoc,
        saveVerificationDocTrackingRecordToDocument,
        getVerificationDocTrackingRecords,
        uploadVerificationDocTrackingFile,
        updateVerificationDocTrackingRecord,
        removeVerificationDocTrackingRecord,
    }),
    withStyles(styles)
)(VerificationDocs);
