import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography, withStyles, Fab } from '@material-ui/core';
import { Navigation } from '@material-ui/icons';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',

        '& h5': {
            flexGrow: '1',
        },
    },

    buttonIcon: {
        marginRight: theme.spacing(1),
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
});

const SubHeader = ({ classes, title, onAction, actionText, children }) => (
    <div className={classes.layout}>
        <div className={classes.content}>
            <Typography variant="h5">{title || children}</Typography>
            {actionText && onAction && (
                <Fab variant="extended" color="primary" size="medium" onClick={onAction}>
                    <Navigation className={classes.buttonIcon} />
                    {actionText}
                </Fab>
            )}
        </div>
        <Divider className={classes.divider} />
    </div>
);

SubHeader.propTypes = {
    classes: PropTypes.object,
    onAction: PropTypes.func,
    title: PropTypes.string,
    actionText: PropTypes.string,
};

export default withStyles(styles)(SubHeader);
