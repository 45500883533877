import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import {
    withStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    Grid,
    Button,
} from '@material-ui/core';

import styles from './styles/requestSummary';
import { APP_PATHS, PROJECT_PORTION, USER_ROLES, ALERT_LEVEL, PROJECT_STATUS } from '../../../constants';
import { getProjectFieldsWithValue, projectIdSelector, projectStatusSelector } from '../selectors';
import { updateProjectStatus, uploadFile } from '../actions';
import { ApprovalButtonGroup, SubHeader, ConfirmDialog, FieldGrid } from '../../../components';
import { setAlert } from '../../ui/actions';
import { checkUserRole, validate } from '../../../lib/utils';
import { steps } from './ProjectWizard';
import { currentUserSelector } from '../../user/selectors';

const RequestSummary = ({
    classes,
    user,
    currentProjectId,
    projectFormsWithValue,
    currentProjectStatus,
    updateProjectStatus,
    setAlert,
    history,
    uploadFile,
}) => {
    const [expandedPortions, setExpandedPortions] = useState([PROJECT_PORTION.GENERAL]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    let isCompleted = true;

    const isRaManager = checkUserRole(user, USER_ROLES.RA_MANAGER);
    const isDeManager = checkUserRole(user, USER_ROLES.DE_MANAGER);

    const handleExpand = portion => (e, expanded) =>
        setExpandedPortions(expanded ? [...expandedPortions, portion] : expandedPortions.filter(p => p !== portion));

    const handleApprove = async (approved, notes) => {
        let approveStatus = '';
        let rejectStatus = '';
        if (isRaManager) {
            approveStatus = PROJECT_STATUS.INTAKE_ACCEPTED;
            rejectStatus = PROJECT_STATUS.INTAKE_REJECTED;
        }
        if (isDeManager) {
            approveStatus = PROJECT_STATUS.OVERHEAD_APPROVED;
        }
        const success = await updateProjectStatus(currentProjectId, approved ? approveStatus : rejectStatus, notes);
        if (success) {
            let variant = ALERT_LEVEL.SUCCESS;
            let message = '';
            if (!approved) {
                variant = ALERT_LEVEL.WARNING;
                message = 'The project is rejected and an email will be sent out.';
            } else {
                if (isRaManager) {
                    message = 'The project is accepted. You can assign it to specialist.';
                }
                if (isDeManager) {
                    message = 'The overhead project is approved and an email will be sent out.';
                }
            }

            setAlert({
                variant,
                message,
            });
            if (isRaManager) {
                history.push(APP_PATHS.DASHBOARD);
            }
        }
    };

    const handleCloseConfirmDialog = confirmed => async () => {
        if (confirmed) {
            await updateProjectStatus(currentProjectId, PROJECT_STATUS.INTAKE_SUBMITTED);
        }
        setOpenConfirmDialog(false);
    };

    const handleProjectSubmit = () => {
        setOpenConfirmDialog(true);
    };

    const handleFileUpload = (formName, fieldName) => async file =>
        await uploadFile(currentProjectId, fieldName, file, formName);

    const renderGroups = (formName, groups) => (
        <Grid container className={classes.fieldsGrid} spacing={2}>
            {groups.map(({ fields }) =>
                fields.map(field => {
                    const { name, currentValue, rules } = field;
                    if (!validate(currentValue, rules)) {
                        isCompleted = false;
                    }
                    if (
                        _.isNil(currentValue) ||
                        _.trim(currentValue) === '' ||
                        (_.isArray(currentValue) && _.isEmpty(currentValue))
                    )
                        return null;

                    return (
                        <FieldGrid
                            key={name}
                            field={field}
                            showUpload={
                                checkUserRole(user, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST) &&
                                name !== 'maherScreeningForm'
                            }
                            onUpload={handleFileUpload(formName, name)}
                        />
                    );
                })
            )}
        </Grid>
    );

    const renderFooter = () => {
        if (!isCompleted) return null;
        if (currentProjectStatus === PROJECT_STATUS.INTAKE_SUBMITTED && isRaManager)
            return <ApprovalButtonGroup onApproveOrReject={handleApprove} />;
        if (currentProjectStatus === PROJECT_STATUS.OVERHEAD_SUBMITTED && isDeManager)
            return <ApprovalButtonGroup noReject onApproveOrReject={handleApprove} />;

        if ([PROJECT_STATUS.INTAKE_NOT_SUBMITTED, PROJECT_STATUS.INTAKE_REJECTED].includes(currentProjectStatus))
            return (
                <Button variant="contained" color="primary" onClick={handleProjectSubmit}>
                    Submit
                </Button>
            );
    };

    return (
        projectFormsWithValue && (
            <div>
                <SubHeader title="Request Summary" />
                {steps.map(formName => (
                    <ExpansionPanel
                        key={formName}
                        expanded={expandedPortions.includes(formName)}
                        onChange={handleExpand(formName)}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" className={classes.formTitle}>
                                {formName}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.detailPanel}>
                            {renderGroups(formName, projectFormsWithValue[formName])}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
                <div className={classes.footer}>{renderFooter()}</div>
                <ConfirmDialog
                    open={openConfirmDialog}
                    onCloseDialog={handleCloseConfirmDialog}
                    title="Completed all the information?"
                    content="Once submitted the project, you will not be able to edit unless Regulatory
                    Affairs staff rejects the request."
                />
            </div>
        )
    );
};

RequestSummary.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    currentProjectId: PropTypes.number,
    projectFormsWithValue: PropTypes.object,
    currentProjectStatus: PropTypes.string,
    updateProjectStatus: PropTypes.func,
    setAlert: PropTypes.func,
    history: PropTypes.object,
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    user: currentUserSelector(state),
    projectFormsWithValue: getProjectFieldsWithValue(state),
    currentProjectStatus: projectStatusSelector(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, { updateProjectStatus, setAlert, uploadFile }),
    withStyles(styles)
)(RequestSummary);
