import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import FileDownloadButton from './FileDownloadButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    icon: {
        marginLeft: theme.spacing(1),
    },
    uploadInput: {
        display: 'none',
    },
}));

const FileController = ({ filePath, fieldName, showUpload, onUploadFile }) => {
    const classes = useStyles();
    const [currentFile, setCurrentFile] = useState(filePath);

    const handleUpload = async e => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile && onUploadFile) {
            const uploadedFilePath = await onUploadFile(uploadedFile);
            setCurrentFile(uploadedFilePath);
        }
    };

    return (
        <div className={classes.root}>
            {currentFile && <FileDownloadButton filePath={currentFile} />}
            {showUpload && (
                <>
                    <input
                        className={classes.uploadInput}
                        id={`upload-file-controller-${fieldName}`}
                        multiple
                        type="file"
                        onChange={handleUpload}
                    />
                    <input type="text" className={classes.uploadInput} name={fieldName} readOnly value={currentFile} />
                    <label htmlFor={`upload-file-controller-${fieldName}`}>
                        <Button color="primary" variant="outlined" component="span">
                            Upload
                            <CloudUpload className={classes.icon} />
                        </Button>
                    </label>
                </>
            )}
        </div>
    );
};

FileController.propTypes = {
    filePath: PropTypes.string,
    onUploadFile: PropTypes.func,
    fieldName: PropTypes.string,
    showUpload: PropTypes.bool,
};

export default FileController;
