import { PlayCircleFilled, FeaturedPlayList, PlaylistAddCheck, Ballot } from '@material-ui/icons';

import { USER_ROLES, APP_PATHS } from '../../constants';
import DocReview from './views/DocReview';

const reviewRoutes = [
    {
        path: APP_PATHS.REVIEW_INITIAL,
        component: DocReview,
        sidebarName: 'Begin Environmental',
        icon: PlayCircleFilled,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.ACCOUNT,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.REVIEW_SUBMITTAL,
        component: DocReview,
        sidebarName: 'Application Submittal',
        icon: FeaturedPlayList,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.ACCOUNT,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.REVIEW_STUDIES,
        component: DocReview,
        sidebarName: 'Environmental Studies',
        icon: PlaylistAddCheck,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.ACCOUNT,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.REVIEW_ENVDOCUMENT,
        component: DocReview,
        sidebarName: 'Environmental Document',
        icon: Ballot,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.ACCOUNT,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.REVIEW,
        redirect: true,
        to: APP_PATHS.REVIEW_INITIAL,
    },
];

export { reviewRoutes };
