import { AccountBalance, TableChart, Assessment } from '@material-ui/icons';

import { USER_ROLES, APP_PATHS } from '../../constants';
import { FeeFunding, FeeBilling, FeeSummary } from './views';

const feeRoutes = [
    {
        path: APP_PATHS.FEE_FUNDING,
        component: FeeFunding,
        sidebarName: 'Funding Source',
        icon: AccountBalance,
        title: 'Initiate Project Funding',
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.ADMIN],
    },
    {
        path: APP_PATHS.FEE_BILLING,
        component: FeeBilling,
        sidebarName: 'Billing',
        title: 'Prepare Billing Details',
        icon: TableChart,
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.ADMIN],
    },
    {
        path: APP_PATHS.FEE_SUMMARY,
        component: FeeSummary,
        sidebarName: 'Fee Summary',
        icon: Assessment,
        title: 'Fee Summary',
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.PROJECT_CREATOR, USER_ROLES.ADMIN],
    },
];

export default feeRoutes;
