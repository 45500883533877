import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import { FormDialog } from '.';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing(2),
        width: '96px',
    },
});

const rejectNote = {
    message: '',
};
const fields = Object.keys(rejectNote).map(key => ({
    name: key,
    type: 'textarea',
    rules: ['required'],
}));

const ApprovalButtonGroup = ({ classes, onApproveOrReject, noReject }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleSubmit = ({ message }) => {
        onApproveOrReject(false, message);
        setShowDialog(false);
    };
    return (
        <>
            {!noReject && (
                <Button
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => setShowDialog(true)}
                >
                    Reject
                </Button>
            )}
            <Button
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => onApproveOrReject(true)}
            >
                Approve
            </Button>
            <FormDialog
                formObject={rejectNote}
                fields={fields}
                title="Notes"
                onClose={() => setShowDialog(false)}
                open={showDialog}
                onSubmit={handleSubmit}
            />
        </>
    );
};

ApprovalButtonGroup.propTypes = {
    classes: PropTypes.object,
    onApproveOrReject: PropTypes.func.isRequired,
    noReject: PropTypes.bool,
};

export default withStyles(styles)(ApprovalButtonGroup);
