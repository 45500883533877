import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    Button,
    DialogTitle,
    TextField,
    DialogContent,
    FormHelperText,
    makeStyles,
    DialogContentText,
} from '@material-ui/core';
import dateFormat from 'date-fns/format';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';

import FileController from './FileController';
import { DATE_FORMAT } from '../constants';

const multiSelectStyle = {
    container: styles => ({
        ...styles,
        marginBottom: '16px',
        width: '100%',
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: '240px',
    }),
    menu: styles => ({
        ...styles,
        zIndex: 10,
    }),
};

const useStyles = makeStyles(theme => ({
    contentRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    field: {
        minWidth: '45%',
        marginBottom: theme.spacing(2),

        '&:last-child': {
            marginBottom: 0,
        },
    },
}));

const FormDialog = ({
    title,
    subTitle,
    open,
    onClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    maxWidth,
    fields,
    onSubmit,
    formObject,
    formValidate,
    renderCustomSection,
    singleColumn,
}) => {
    const classes = useStyles();
    const [error, setError] = useState();

    const handleSubmit = e => {
        e.preventDefault();
        let values = {};
        const data = new FormData(e.target);
        for (const key of data.keys()) {
            const formValues = data.getAll(key);
            values[key] = formValues.length > 1 ? formValues.filter(v => v !== '') : formValues[0];
        }
        const result = { ...formObject, ...values };

        if (formValidate) {
            const error = formValidate(result);
            if (error) {
                setError(error);
            } else {
                onSubmit(result);
            }
        } else {
            onSubmit(result);
        }
    };

    if (!formObject) return null;

    const renderField = (field, defaultValue, showError) => {
        const { name, label, type, rules, options, onUpload } = field;
        if (name === 'id') return null;

        if (type === 'date') {
            defaultValue = defaultValue ? dateFormat(defaultValue, DATE_FORMAT) : defaultValue;
        }
        let validations = {};
        if (!_.isEmpty(rules)) rules.forEach(rule => (validations[rule] = true));

        switch (type) {
            case 'multi-select':
                return (
                    <Select
                        styles={multiSelectStyle}
                        closeMenuOnSelect
                        components={makeAnimated()}
                        isMulti
                        options={options}
                        name={name}
                        defaultValue={defaultValue}
                        placeholder={label}
                    />
                );
            case 'upload':
                return <FileController filePath={defaultValue} fieldName={name} showUpload onUploadFile={onUpload} />;
            default:
                return (
                    <TextField
                        onChange={() => setError(null)}
                        error={showError}
                        className={classes.field}
                        variant="outlined"
                        fullWidth={singleColumn}
                        name={name}
                        type={type === 'textarea' ? 'text' : type}
                        label={_.upperFirst(label || name)}
                        multiline={type === 'textarea'}
                        rows="5"
                        select={type === 'select'}
                        defaultValue={defaultValue}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            native: true,
                        }}
                        {...validations}
                    >
                        {options &&
                            options.map(op => (
                                <option key={op.value} value={op.value}>
                                    {op.label}
                                </option>
                            ))}
                    </TextField>
                );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={maxWidth}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            <DialogTitle>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent classes={{ root: classes.contentRoot }}>
                    {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
                    {fields.map((field, index) => {
                        const showError = error && error.field === field.name;
                        return (
                            <Fragment key={index}>
                                {renderField(field, formObject[field.name], showError)}
                                {showError && <FormHelperText error>{error.message}</FormHelperText>}
                            </Fragment>
                        );
                    })}
                </DialogContent>
                {renderCustomSection && <DialogContent>{renderCustomSection()}</DialogContent>}
                <DialogActions>
                    <Button onClick={onClose} variant="contained">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

FormDialog.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    formObject: PropTypes.object,
    fields: PropTypes.array.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    renderCustomSection: PropTypes.func,
    singleColumn: PropTypes.bool,
};

FormDialog.defaultProps = {
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    maxWidth: 'sm',
    singleColumn: true,
};

export default FormDialog;
