import httpClient from '../lib/httpClient';
import { setLocalItem, removeLocalItem, getLocalItem } from '../lib/localStorage';
import { isAfter, addHours } from 'date-fns';
import _ from 'lodash';

const client = new httpClient();

const login = async (userName, password) => {
    try {
        const auth = await client.post('auth/login', { userName, password });
        setLocalItem('auth', auth);
        return auth;
    } catch (error) {
        throw error;
    }
};

const renewToken = async () => {
    if (getLocalItem('refreshToken')) return;

    setLocalItem('refreshToken', true);
    const { token, refreshToken } = authService.getLocalAuth();
    const auth = await client.post('auth/refresh', { token, refreshToken });
    setLocalItem('auth', auth);
    removeLocalItem('refreshToken');
    return auth;
};

const logout = () => removeLocalItem('auth');

const getLocalAuth = () => JSON.parse(getLocalItem('auth'));

const isTokenAboutExpire = () => {
    const localAuth = getLocalAuth();
    return localAuth && isAfter(addHours(new Date(), 1), localAuth.expiration);
};

const isTokenValid = () => {
    const localAuth = getLocalAuth();
    return localAuth && isAfter(localAuth.expiration, new Date());
};

const isAuthorized = roles => {
    const localAuth = getLocalAuth();
    return localAuth && roles && _.intersection(localAuth.userInfo.roles, roles).length > 0;
};

export const authService = {
    login,
    renewToken,
    logout,
    getLocalAuth,
    isAuthorized,
    isTokenValid,
    isTokenAboutExpire,
};
