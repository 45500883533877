import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import {
    GET_REVIEW_ASSIGNMENTS,
    SAVE_REVIEW_ASSIGNMENTS,
    UPDATE_REVIEW_ASSIGNMENTS,
    REMOVE_PROJECT_DOC,
} from '../actions/types';

const initialState = {};

const reducer = handleActions(
    {
        [combineActions(GET_REVIEW_ASSIGNMENTS, SAVE_REVIEW_ASSIGNMENTS, UPDATE_REVIEW_ASSIGNMENTS)]: (
            state,
            { payload: { docId, assignments } }
        ) => ({ ...state, [docId]: assignments }),
        [REMOVE_PROJECT_DOC]: (state, { payload }) => _.omit(state, payload),
    },
    initialState
);

export default reducer;
