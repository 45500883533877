import { createAction } from 'redux-actions';

import {
    GET_PROJECT_ECRS,
    CREATE_ECR,
    UPDATE_ECR,
    REMOVE_ECR,
    EXPORT_ECRS,
    CERTIFY_ECR,
    UNCERTIFY_ECR,
    ACTION_ECR,
} from './type';
import { networkRequestStart, handleRequestError, networkRequestSuccess } from '../../common/actions';
import { projectService } from '../../../services';
import { projectIdSelector } from '../../project/selectors';
import { download } from '../../../lib/utils';

export const getProjectECRs = () => async (dispatch, getState) => {
    const requestName = GET_PROJECT_ECRS;
    try {
        dispatch(networkRequestStart({ requestName }));
        const projectId = projectIdSelector(getState());
        const records = await projectService.fetchProjectECRs(projectId);
        const action = createAction(requestName);
        dispatch(action(records));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const createECR = ecr => async (dispatch, getState) => {
    const requestName = CREATE_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const projectId = projectIdSelector(getState());
        const records = await projectService.postProjectECR(projectId, ecr);
        const action = createAction(requestName);
        dispatch(action(records));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const updateECR = (ecrId, ecr) => async (dispatch, getState) => {
    const requestName = UPDATE_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const record = await projectService.updateProjectECR(ecrId, ecr);
        const action = createAction(requestName);
        dispatch(action(record));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const deleteECRs = ecrIds => async (dispatch, getState) => {
    const requestName = REMOVE_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const promises = ecrIds.map(id => projectService.deleteProjectECR(id));
        await Promise.all(promises);
        const action = createAction(requestName);
        dispatch(action(ecrIds));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const exportECRs = () => async (dispatch, getState) => {
    const requestName = EXPORT_ECRS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const projectId = projectIdSelector(getState());
        const file = await projectService.downloadProjectECR(projectId);
        download(file, 'ECR.xlsx');
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const certifyECR = (ecrId, record) => async (dispatch, getState) => {
    const requestName = CERTIFY_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const certifiedECR = await projectService.certifyECR(ecrId, record);
        const action = createAction(requestName);
        dispatch(action(certifiedECR));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const uncertifyECR = ecrId => async (dispatch, getState) => {
    const requestName = UNCERTIFY_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const ecr = await projectService.uncertifyECR(ecrId);
        const action = createAction(requestName);
        dispatch(action(ecr));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const actionECR = (ecrId, record) => async (dispatch, getState) => {
    const requestName = ACTION_ECR;
    try {
        dispatch(networkRequestStart({ requestName }));
        const actionedECR = await projectService.actionECR(ecrId, record);
        const action = createAction(requestName);
        dispatch(action(actionedECR));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
