import { matchPath } from 'react-router-dom';

import { feeRoutes } from '../modules/fee';
import { reviewRoutes } from '../modules/review';
import { mainRoutes } from './main';
import { PATH_STATUS_MAP, APP_PATHS, STATUS_MESSAGE_MAP, USER_ROLES } from '../constants';

export const getAppRouteByPath = path =>
    [...mainRoutes, ...feeRoutes, ...reviewRoutes].find(
        r => !r.redirect && (r.path === path || matchPath(path, { path: r.path, exact: true, strict: false }))
    );

export const buildProjectPath = (id, path) => {
    if (path) return path.replace(':id', id);
};

export const getNextPathByStatus = status =>
    Object.keys(PATH_STATUS_MAP).find(key => PATH_STATUS_MAP[key].includes(status));

export const navigateTo = (selectedProject, currentUser) => {
    let path = STATUS_MESSAGE_MAP[selectedProject.status].navigateTo;
    if (
        currentUser.roles.includes(USER_ROLES.DE_MANAGER) ||
        currentUser.roles.includes(USER_ROLES.ACCOUNT) ||
        currentUser.roles.includes(USER_ROLES.PROJECT_CREATOR)
    )
        path = APP_PATHS.SUMMARY;
    return buildProjectPath(selectedProject.id, path);
};
