import { handleActions } from 'redux-actions';
import {
    GET_DOCS,
    GET_PROJECT_DOC_REVIEWS,
    ADD_PROJECT_DOCS,
    REMOVE_PROJECT_DOC,
    SET_CURRENT_DOC,
    CHANGE_REVIEW_STEP,
    UPDATE_REVIEW_STATUS,
} from '../actions/types';

const initialState = {
    selectedDocId: -1,
    predefinedDocs: [],
    currentProjectDocReviews: [],
};

const reducer = handleActions(
    {
        [GET_DOCS]: (state, { payload }) => ({ ...state, predefinedDocs: payload }),
        [GET_PROJECT_DOC_REVIEWS]: (state, { payload }) => ({
            ...state,
            currentProjectDocReviews: payload,
        }),
        [ADD_PROJECT_DOCS]: (state, { payload }) => ({
            ...state,
            currentProjectDocReviews: [...state.currentProjectDocReviews, ...payload],
        }),
        [REMOVE_PROJECT_DOC]: (state, { payload }) => ({
            ...state,
            currentProjectDocReviews: state.currentProjectDocReviews.filter(doc => doc.id !== payload),
            selectedDocId: -1,
        }),
        [SET_CURRENT_DOC]: (state, { payload }) => ({
            ...state,
            selectedDocId: payload,
        }),
        [UPDATE_REVIEW_STATUS]: (state, { payload: { docId, status } }) => ({
            ...state,
            currentProjectDocReviews: state.currentProjectDocReviews.map(doc =>
                doc.id === docId ? { ...doc, status } : doc
            ),
        }),
        [CHANGE_REVIEW_STEP]: (state, action) => ({ ...state, selectedDocId: -1 }),
    },
    initialState
);

export default reducer;
