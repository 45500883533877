import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Step, Stepper, StepButton } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SubHeader } from '../../components';
import { DOC_REVIEW_STAGE, APP_PATHS } from '../../constants';
import { predefinedDocsSelector, currentProjectDocReviewsSelector } from './selectors';
import { projectIdSelector } from '../project/selectors';
import DocReview from './views/DocReview';
import { buildProjectPath } from '../../routes/helper';
import { changeReviewStep, getProjectDocReviews, getAllReviewDocuments, setCurrentDoc } from './actions';
import useDocIdQuery from './hooks/useDocIdQuery';

const steps = [
    { key: DOC_REVIEW_STAGE.INITIAL, label: 'Begin Environmental', path: APP_PATHS.REVIEW_INITIAL },
    {
        key: DOC_REVIEW_STAGE.SUBMITTAL,
        label: 'Application Submittal',
        path: APP_PATHS.REVIEW_SUBMITTAL,
    },
    {
        key: DOC_REVIEW_STAGE.STUDIES,
        label: 'Environmental Studies',
        path: APP_PATHS.REVIEW_STUDIES,
    },
    {
        key: DOC_REVIEW_STAGE.ENVDOCUMENT,
        label: 'Environmental Documents',
        path: APP_PATHS.REVIEW_ENVDOCUMENT,
    },
];

const Review = ({
    match,
    location,
    history,
    documents,
    currentProjectDocs,
    currentProjectId,
    changeReviewStep,
    getDocReviewsByProject,
    getAllReviewDocuments,
    setCurrentDoc,
}) => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        getAllReviewDocuments();
    }, [getAllReviewDocuments]);

    useEffect(() => {
        if (currentProjectId) getDocReviewsByProject();
    }, [currentProjectId, getDocReviewsByProject]);

    useEffect(() => {
        const currentStep = _.last(location.pathname.split('/'));
        const stepIndex = steps.findIndex(step => step.key.toUpperCase() === currentStep.toUpperCase());
        if (stepIndex > -1) setActiveStep(stepIndex);
    }, [location.pathname]);

    const documentsByStage = useMemo(() => documents.filter(doc => doc.stage === steps[activeStep].key), [
        documents,
        activeStep,
    ]);

    const projectDocsByStage = useMemo(() => currentProjectDocs.filter(doc => doc.stage === steps[activeStep].key), [
        currentProjectDocs,
        activeStep,
    ]);

    useDocIdQuery(location.search, currentProjectDocs, setCurrentDoc);

    const handleStepClick = step => () => {
        const stepIndex = steps.findIndex(s => s.key === step);
        if (activeStep !== stepIndex) {
            changeReviewStep();
            history.push(buildProjectPath(currentProjectId, steps[stepIndex].path));
        }
    };

    const renderSteps = () => {
        return (
            <Stepper activeStep={activeStep} nonLinear>
                {steps.map(({ key, label }) => {
                    return (
                        <Step key={key}>
                            <StepButton onClick={handleStepClick(key)}>{label}</StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );
    };

    return (
        <div>
            <SubHeader title="Document Review Process" />
            {renderSteps()}
            <Switch>
                <Redirect path={APP_PATHS.REVIEW} exact to={`${match.url}/${DOC_REVIEW_STAGE.INITIAL}`} />
                <Route
                    path={`${APP_PATHS.REVIEW}/:stage`}
                    render={() => (
                        <DocReview
                            stage={steps[activeStep].key}
                            currentProjectId={currentProjectId}
                            documents={documentsByStage}
                            currentProjectDocs={projectDocsByStage}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

Review.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    documents: PropTypes.array,
    history: PropTypes.object.isRequired,
    currentProjectId: PropTypes.number,
    currentProjectDocs: PropTypes.array,
    changeReviewStep: PropTypes.func,
    getDocReviewsByProject: PropTypes.func,
    getAllReviewDocuments: PropTypes.func,
};

const mapStateToProps = state => ({
    documents: predefinedDocsSelector(state),
    currentProjectId: projectIdSelector(state),
    currentProjectDocs: currentProjectDocReviewsSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        changeReviewStep,
        getDocReviewsByProject: getProjectDocReviews,
        getAllReviewDocuments,
        setCurrentDoc,
    })
)(Review);
