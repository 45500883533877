import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Grid, Button, TextField, Paper, Avatar, Typography, withStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import _ from 'lodash';

import styles from './styles/auth';
import { submitLogin } from '../actions';
import { authService } from '../../../services';
import { getAppRouteByPath } from '../../../routes/helper';
import { APP_PATHS } from '../../../constants';
import { currentUserSelector } from '../../user/selectors';

const Login = ({ classes, user, submitLogin, location }) => {
    const [redirect, setRedirect] = useState('');

    const handleLoginSubmit = e => {
        e.preventDefault();
        const loginInfo = new FormData(e.target);
        submitLogin(loginInfo.get('email'), loginInfo.get('password'));
    };

    useEffect(() => {
        if (user) {
            const from = _.get(location, 'state.from', '');
            if (from) {
                setRedirect(from);
            } else {
                const dashboardRoute = getAppRouteByPath(APP_PATHS.DASHBOARD);
                if (authService.isAuthorized(dashboardRoute.roles)) {
                    setRedirect(APP_PATHS.DASHBOARD);
                }
            }
        }
    }, [location, user]);

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <Grid container justify="center">
            <Grid item className={classes.layout}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography variant="h5">Login</Typography>
                    <form autoComplete="off" className={classes.form} onSubmit={handleLoginSubmit}>
                        <Grid container direction="column">
                            <TextField required type="email" label="Email" name="email" className={classes.field} />
                            <TextField
                                required
                                label="Password"
                                name="password"
                                type="password"
                                className={classes.field}
                            />
                        </Grid>
                        <Button type="submit" color="primary" variant="contained" className={classes.submit}>
                            Submit
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
};

Login.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    submitLogin: PropTypes.func,
    location: PropTypes.object,
};

const mapStateToProps = state => ({
    user: currentUserSelector(state),
});

export default connect(mapStateToProps, { submitLogin })(withStyles(styles)(Login));
