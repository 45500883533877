import _ from 'lodash';
import qs from 'qs';
import { useEffect } from 'react';

const useDocIdQuery = (queryString, docs, setDocId) =>
    useEffect(() => {
        if (queryString) {
            const parsedQs = qs.parse(queryString, { ignoreQueryPrefix: true });
            if (parsedQs.documentId) {
                const docId = parseInt(parsedQs.documentId, 10);
                if (docs.some(doc => doc.id === docId)) {
                    setDocId(docId);
                }
                const removedDocIdQS = qs.stringify(_.omit(parsedQs, 'documentId'), {
                    addQueryPrefix: true,
                });
                window.history.replaceState(
                    null,
                    null,
                    `${window.location.origin}${window.location.pathname}${removedDocIdQS}`
                );
            }
        }
    }, [queryString, docs, setDocId]);

export default useDocIdQuery;
