const styles = theme => ({
    tableContainer: {
        width: '100%',
    },
    subject: {
        minWidth: '200px',
    },
    note: {
        minWidth: '400px',
    },
    addButton: {
        margin: theme.spacing(2),
    },
});

export default styles;
