import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Home as HomeIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import styles from './styles/header';
import { logout } from '../modules/auth/actions';
import { resetProject, clearProjectInfo } from '../modules/project/actions';
import { projectNameSelector, projectIdSelector, projectStatusSelector } from '../modules/project/selectors';
import SearchBar from '../modules/project/components/SearchBar';
import { STATUS_MESSAGE_MAP, APP_PATHS } from '../constants';
import { navigateTo } from '../routes/helper';
import { currentUserSelector } from '../modules/user/selectors';

const Header = ({
    classes,
    logout,
    resetProject,
    user,
    projectName,
    history,
    currentProjectId,
    clearProjectInfo,
    currentProjectStatus,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
        resetProject();
        setAnchorEl(null);
        history.push(APP_PATHS.LOGIN);
    };

    const renderLoginAvatar = () => {
        if (!user) return null;
        const open = Boolean(anchorEl);

        return (
            <div>
                <Tooltip title={user.userName}>
                    <IconButton
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </Menu>
            </div>
        );
    };

    const handleProjectSelected = projectInfoStr => {
        const projectInfo = JSON.parse(projectInfoStr);
        const { id } = projectInfo;
        if (currentProjectId !== id) {
            clearProjectInfo();
        }
        history.push(navigateTo(projectInfo, user));
    };

    const handleNavigateHome = () => {
        history.push(APP_PATHS.DASHBOARD);
    };

    const renderSearchInput = () => {
        if (!user) return null;
        return (
            <div className={classes.search}>
                <SearchBar onOptionSelect={handleProjectSelected} />
            </div>
        );
    };

    const renderTitle = () => {
        let title = 'Regulatory Affairs Management System';
        let status = '';
        if (projectName) {
            title += ` - ${projectName} `;
        }
        if (currentProjectStatus) {
            status = `[ ${STATUS_MESSAGE_MAP[currentProjectStatus].headerLabel} ]`;
        }
        return (
            <div className={classes.title}>
                <Typography variant="h6" color="inherit">
                    {title}
                </Typography>
                <Typography variant="h6" color="secondary" className={classes.status}>
                    {status}
                </Typography>
            </div>
        );
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Home"
                    onClick={handleNavigateHome}
                >
                    <HomeIcon />
                </IconButton>
                {renderTitle()}
                {renderSearchInput()}
                {renderLoginAvatar()}
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    routes: PropTypes.array,
    logout: PropTypes.func,
    resetProject: PropTypes.func,
    user: PropTypes.object,
    projectName: PropTypes.string,
    history: PropTypes.object,
    currentProjectId: PropTypes.number,
    clearProjectInfo: PropTypes.func,
};

const mapStateToProps = state => ({
    projectName: projectNameSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    currentProjectId: projectIdSelector(state),
    user: currentUserSelector(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, { logout, resetProject, clearProjectInfo }),
    withStyles(styles)
)(Header);
