import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Button,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    IconButton,
    withStyles,
    Fab,
} from '@material-ui/core';
import { AddCircle, Delete, Edit, SwapVert } from '@material-ui/icons';
import { addDays, format as dateFormat, isBefore, parse as dateParse } from 'date-fns';
import _ from 'lodash';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import cx from 'classnames';

import { FormDialog, EmptyState, ConfirmDialog } from '../../../components';
import styles from './styles/tasksTable';
import { REVIEW_ROLES, DOC_REVIEW_STATUS, USER_ROLES, DATE_FORMAT } from '../../../constants';
import { saveReviewAssignments, updateReviewAssignments, updateReviewStatus } from '../actions';
import { currentDocAssignmentsSelector, currentDocSelector } from '../selectors';
import { allUsersSelector, currentUserSelector } from '../../user/selectors';
import { fetchProjectInfo } from '../../project/actions';

const DragHandle = SortableHandle(({ className }) => (
    <TableCell className={className} padding="checkbox">
        <SwapVert />
    </TableCell>
));

const SortableItem = SortableElement(({ cols, item, classes, onEdit, onDelete }) => (
    <TableRow className={cx(classes.tableRow, { [classes.activeRow]: item.isActive })}>
        <DragHandle className={classes.dragHandle} />
        {cols.map(key => (
            <TableCell key={key} className={classes[item[key]]}>
                {item[key]}
            </TableCell>
        ))}
        <TableCell padding="checkbox">
            <IconButton onClick={onEdit}>
                <Edit />
            </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
            <IconButton onClick={onDelete}>
                <Delete />
            </IconButton>
        </TableCell>
    </TableRow>
));

const SortableRows = SortableContainer(({ items, cols, classes, onEdit, onDelete }) => (
    <TableBody>
        {items.map((item, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                item={item}
                cols={cols}
                onEdit={onEdit(index)}
                onDelete={onDelete(index)}
                classes={classes}
            />
        ))}
    </TableBody>
));

const TasksTable = ({
    allAssignments,
    classes,
    projectDoc,
    saveReviewAssignments,
    updateReviewAssignments,
    updateReviewStatus,
    allUsers,
    currentUser,
    fetchProjectInfo,
}) => {
    const [currentAssignment, setCurrentAssignment] = useState({});
    const [showAssignmentForm, setShowAssignmentForm] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const editMode = useMemo(() => {
        if (_.intersection(currentUser.roles, [USER_ROLES.RA_SPECIALIST, USER_ROLES.RA_MANAGER]).length === 0)
            return false;
        return (
            projectDoc &&
            [DOC_REVIEW_STATUS.PENDING_ASSIGNMENT, DOC_REVIEW_STATUS.OVERDUE, DOC_REVIEW_STATUS.REJECTED].includes(
                projectDoc.status
            )
        );
    }, [projectDoc, currentUser.roles]);

    const editableFields = ['assignedUserId', 'startDate', 'endDate'];
    const cols = ['sequence', 'role', 'account', 'startDate', 'endDate'];

    const getDefaultDateValue = isEndDate => {
        if (allAssignments.length > 0) {
            return addDays(dateParse(_.last(allAssignments).endDate), isEndDate ? 1 : 0);
        }
        return dateFormat(addDays(Date.now(), isEndDate ? 1 : 0), DATE_FORMAT);
    };

    const generateUserOptions = role =>
        allUsers
            .filter(user => user.roles.includes(role))
            .map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName} <${user.email}>`,
            }));

    const handleAddAssignment = role => () => {
        setShowAssignmentForm(true);
        setCurrentAssignment({
            sequence: allAssignments.length + 1,
            role,
            assignedUserId: -1,
            startDate: getDefaultDateValue(false),
            endDate: getDefaultDateValue(true),
        });
    };

    const handleSubmitAssignment = assignment => {
        let updatedItems;
        let user = allUsers.find(x => x.id === Number(assignment.assignedUserId));
        assignment.fullName = user.firstName + ' ' + user.lastName;
        if (assignment.sequence <= allAssignments.length) {
            updatedItems = allAssignments.map(item => (item.sequence === assignment.sequence ? assignment : item));
        } else {
            updatedItems = [...allAssignments, assignment];
        }
        updateReviewAssignments({ docId: projectDoc.id, assignments: updatedItems });
        setShowAssignmentForm(false);
        setCurrentAssignment({});
    };

    const handleAssignmentRemove = index => () => {
        const updatedItems = allAssignments.map((item, i) => (i < index ? item : { ...item, sequence: i }));

        updateReviewAssignments({
            docId: projectDoc.id,
            assignments: [...updatedItems.slice(0, index), ...updatedItems.slice(index + 1)],
        });
    };

    const handleAssignmentEdit = index => () => {
        setCurrentAssignment({ ...allAssignments[index] });
        setShowAssignmentForm(true);
    };

    const handleFlowStart = () => {
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = confirmed => async () => {
        if (confirmed) {
            const items =
                projectDoc.status === DOC_REVIEW_STATUS.PENDING_ASSIGNMENT
                    ? allAssignments.map((item, index) => ({ ...item, isActive: index === 0 }))
                    : allAssignments;
            const success = await saveReviewAssignments(projectDoc.id, items);
            if (success) {
                updateReviewStatus({ docId: projectDoc.id, status: DOC_REVIEW_STATUS.IN_PROGRESS });
                fetchProjectInfo();
            }
        }
        setShowConfirmDialog(false);
    };

    const handleAssignmentValidate = assignment => {
        if (assignment && isBefore(assignment.endDate, assignment.startDate)) {
            return {
                field: 'endDate',
                message: 'End date has to be equal or after the start date.',
            };
        }
        return null;
    };

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        let updatedItems = arrayMove(allAssignments, oldIndex, newIndex).map((item, index) => ({
            ...item,
            sequence: index + 1,
        }));
        updateReviewAssignments({ docId: projectDoc.id, assignments: updatedItems });
    };

    const renderButton = () => {
        if (!editMode || allAssignments.length === 0) return null;

        let text = '';
        if (projectDoc.status === DOC_REVIEW_STATUS.PENDING_ASSIGNMENT) text = 'Start';
        if (projectDoc.status === DOC_REVIEW_STATUS.OVERDUE || projectDoc.status === DOC_REVIEW_STATUS.REJECTED)
            text = 'Reset';

        return (
            text && (
                <div className={classes.footButton}>
                    <Button variant="contained" color="primary" onClick={handleFlowStart}>
                        {text}
                    </Button>
                </div>
            )
        );
    };

    const renderRoleButtonsGroup = () => {
        if (!editMode) return null;

        return Object.keys(REVIEW_ROLES).map((role, index) => {
            const roleLabel = REVIEW_ROLES[role];
            return (
                <Fab
                    variant="extended"
                    size="medium"
                    aria-label="Add"
                    onClick={handleAddAssignment(role)}
                    className={cx(classes.roleButton, classes[roleLabel])}
                    key={index}
                    disableFocusRipple
                    disableRipple
                >
                    <AddCircle className={classes.addIcon} />
                    {_.capitalize(roleLabel)}
                </Fab>
            );
        });
    };

    const renderTableHeaders = () => {
        const [, ...headerLabels] = cols;
        const headers = editMode ? ['', '', ...headerLabels, '', ''] : ['', ...headerLabels];
        return (
            <TableHead>
                <TableRow className={classes.highlightRow}>
                    {headers.map((col, index) => (
                        <TableCell key={index} className={classes.tableCell}>
                            {col}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTableBody = () => {
        const allItems = allAssignments.map(item => ({
            ...item,
            role: REVIEW_ROLES[item.role],
            account: item.fullName,
        }));
        return editMode ? (
            <SortableRows
                cols={cols}
                items={allItems}
                classes={classes}
                onEdit={handleAssignmentEdit}
                onDelete={handleAssignmentRemove}
                helperClass={classes.dndRow}
                onSortEnd={handleSortEnd}
                useDragHandle
            />
        ) : (
            <TableBody>
                {allItems.map((item, index) => (
                    <TableRow key={index} className={cx({ [classes.activeRow]: item.isActive })}>
                        {cols.map(key => (
                            <TableCell key={key} className={classes[item[key]]}>
                                {item[key]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    const renderAssignmentsTable = () => {
        if (projectDoc.status === DOC_REVIEW_STATUS.PENDING_ASSIGNMENT && allAssignments.length === 0)
            return <EmptyState content="Start creating assignments to author, reviewers and approvers." />;
        return (
            <Table>
                {renderTableHeaders()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderAssignmentForm = () => {
        let formTitle = '';
        let fields = [];
        if (currentAssignment) {
            formTitle = `Save ${_.capitalize(REVIEW_ROLES[currentAssignment.role])} Assignment`;
            fields = editableFields.map(key => {
                let type;
                let label;
                let options;
                switch (key) {
                    case 'assignedUserId':
                        label = 'Pick An Account';
                        type = 'select';
                        options = generateUserOptions(currentAssignment.role);
                        break;
                    case 'startDate':
                    case 'endDate':
                        type = 'date';
                        break;
                    default:
                        type = 'text';
                }

                return {
                    label,
                    name: key,
                    type,
                    options,
                    rules: ['required'],
                };
            });
        }

        return (
            <FormDialog
                formObject={currentAssignment}
                title={formTitle}
                fields={fields}
                open={showAssignmentForm}
                onClose={() => setShowAssignmentForm(false)}
                onSubmit={handleSubmitAssignment}
                formValidate={handleAssignmentValidate}
            />
        );
    };

    if (!projectDoc) return null;
    return (
        <div>
            {renderRoleButtonsGroup()}
            <div className={classes.tableContainer}>{renderAssignmentsTable()}</div>
            {renderButton()}
            {renderAssignmentForm()}
            <ConfirmDialog
                open={showConfirmDialog}
                onCloseDialog={handleCloseConfirmDialog}
                title="Filled in all required assignments?"
                content="Once start the document review flow, you will not be able to edit them until it's overdue or rejected."
            />
        </div>
    );
};

TasksTable.propTypes = {
    classes: PropTypes.object,
    projectDoc: PropTypes.object,
    saveReviewAssignments: PropTypes.func,
    allAssignments: PropTypes.array,
    updateReviewAssignments: PropTypes.func,
    allUsers: PropTypes.array,
    currentUser: PropTypes.object,
    fetchProjectInfo: PropTypes.func,
};

const mapStateToProps = state => ({
    allAssignments: currentDocAssignmentsSelector(state),
    projectDoc: currentDocSelector(state),
    allUsers: allUsersSelector(state),
    currentUser: currentUserSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        saveReviewAssignments,
        updateReviewAssignments,
        updateReviewStatus,
        fetchProjectInfo,
    }),
    withStyles(styles)
)(TasksTable);
