import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
    withStyles,
    Button,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { AddCircle, Delete, CheckCircle, Warning } from '@material-ui/icons';

import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import { FormDialog } from '../../../components';
import { DOC_REVIEW_TYPE, DOC_REVIEW_STATUS, USER_ROLES } from '../../../constants';
import { statusColor } from '../../../components/styles';
import { amber } from '@material-ui/core/colors';
import { checkUserRole } from '../../../lib/utils';

const styles = theme => ({
    docSelection: {
        display: 'flex',
        alignItems: 'center',
    },
    listDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    done: {
        color: statusColor.approved,
    },
    warning: {
        color: amber[800],
    },
});

const DocList = ({
    classes,
    predefinedDocOptions,
    onSelectChange,
    selectedOptions,
    onAddDocs,
    currentProjectDocs,
    selectedDocId,
    onDocItemClick,
    onSubmitNewDoc,
    onRemoveProjectDoc,
    reviewStage = 'DEFAULT',
    currentUser,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    let docTypes = Object.values(DOC_REVIEW_TYPE[reviewStage]) || [];

    const newDoc = {
        name: '',
        type: '',
    };

    const fields = [
        {
            name: 'name',
            rules: ['required'],
        },
        {
            name: 'type',
            type: 'select',
            options: docTypes.map(type => ({ label: type, value: type })).concat({ label: 'Other', value: '' }),
        },
    ];
    const handleNewDocumentSubmit = newDoc => {
        setShowDialog(false);
        onSubmitNewDoc(newDoc);
    };

    const renderDocAction = doc => {
        switch (doc.status) {
            case DOC_REVIEW_STATUS.PENDING_ASSIGNMENT:
                return (
                    <ListItemSecondaryAction>
                        <IconButton onClick={onRemoveProjectDoc(doc.id)}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                );
            case DOC_REVIEW_STATUS.COMPLETE:
                return (
                    <ListItemSecondaryAction>
                        <IconButton disabled>
                            <CheckCircle className={classes.done} />
                        </IconButton>
                    </ListItemSecondaryAction>
                );
            case DOC_REVIEW_STATUS.OVERDUE:
                return (
                    <ListItemSecondaryAction>
                        <IconButton disabled>
                            <Warning className={classes.warning} />
                        </IconButton>
                    </ListItemSecondaryAction>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {checkUserRole(currentUser, USER_ROLES.ADMIN, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST) && (
                <section className={classes.docSelection}>
                    <Select
                        styles={{ container: styles => ({ ...styles, flex: 1 }) }}
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        isMulti
                        options={predefinedDocOptions}
                        onChange={onSelectChange}
                        value={selectedOptions}
                        noOptionsMessage={() => null}
                    />
                    <IconButton
                        disabled={selectedOptions.length === 0}
                        color="primary"
                        variant="contained"
                        onClick={onAddDocs}
                    >
                        <AddCircle fontSize="large" />
                    </IconButton>
                </section>
            )}
            <Divider className={classes.listDivider} />
            <List dense>
                {currentProjectDocs.map(doc => (
                    <ListItem button key={doc.id} selected={doc.id === selectedDocId} onClick={onDocItemClick(doc.id)}>
                        <ListItemText primary={doc.name} />
                        {renderDocAction(doc)}
                    </ListItem>
                ))}
            </List>
            {currentProjectDocs.length > 0 && <Divider className={classes.listDivider} />}
            {checkUserRole(currentUser, USER_ROLES.ADMIN, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST) && (
                <Button fullWidth onClick={() => setShowDialog(true)} color="primary" variant="outlined">
                    Create A New Document
                </Button>
            )}

            <FormDialog
                open={showDialog}
                title="New Document"
                onClose={() => setShowDialog(false)}
                onSubmit={handleNewDocumentSubmit}
                formObject={newDoc}
                fields={fields}
            />
        </div>
    );
};

DocList.propTypes = {
    classes: PropTypes.object,
    predefinedDocOptions: PropTypes.array,
    onSelectChange: PropTypes.func,
    selectedOptions: PropTypes.array,
    onAddDocs: PropTypes.func,
    currentProjectDocs: PropTypes.array,
    selectedDocId: PropTypes.number,
    onDocItemClick: PropTypes.func,
    onSubmitNewDoc: PropTypes.func,
    onRemoveProjectDoc: PropTypes.func,
    isAddOn: PropTypes.bool,
    currentUser: PropTypes.object,
};

export default withStyles(styles)(DocList);
