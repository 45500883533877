import { handleActions } from 'redux-actions';
import { networkRequestActionTypes } from '../common/actions';
import { CLOSE_ALERT, SET_ALERT, SHOW_SPINNER, HIDE_SPINNER, SET_SIDEBAR_STATUS } from './actions';
import { ALERT_LEVEL } from '../../constants';
import { GET_TOP_PROJECTS } from '../project/actions/types';
import { GET_DOCS } from '../review/actions/types';

const initialState = {
    showSpinner: false,
    alert: null,
    errorCode: null,
    showSideBar: true,
    onGoingActions: [],
};
const ActionsWithoutSpinner = [GET_TOP_PROJECTS, GET_DOCS];
const reducer = handleActions(
    {
        [networkRequestActionTypes.start]: (state, { payload }) => {
            const { requestName } = payload;
            if (ActionsWithoutSpinner.includes(requestName)) {
                return state;
            }
            return {
                ...state,
                showSpinner: true,
                onGoingActions: [...state.onGoingActions, requestName],
            };
        },
        [networkRequestActionTypes.success]: (state, { payload }) => {
            const { requestName } = payload;
            return {
                ...state,
                showSpinner: false,
                onGoingActions: state.onGoingActions.filter(action => action !== requestName),
            };
        },
        [networkRequestActionTypes.fail]: (state, { payload }) => {
            const { requestName } = payload;
            return {
                ...state,
                showSpinner: false,
                alert: { variant: ALERT_LEVEL.ERROR, message: payload.message },
                errorCode: payload.code,
                onGoingActions: state.onGoingActions.filter(action => action !== requestName),
            };
        },
        [CLOSE_ALERT]: (state, action) => ({ ...state, alert: null, errorCode: null }),
        [SET_ALERT]: (state, { payload }) => ({ ...state, alert: payload }),
        [SHOW_SPINNER]: state => ({ ...state, showSpinner: true }),
        [HIDE_SPINNER]: state => ({ ...state, showSpinner: false }),
        [SET_SIDEBAR_STATUS]: (state, { payload }) => ({ ...state, showSideBar: payload }),
    },
    initialState
);

export default reducer;
