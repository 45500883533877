const styles = theme => ({
    layout: {
        display: 'flex',
    },
    billContainer: {
        flex: 4,
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    ratesTitle: {
        marginBottom: theme.spacing(3),
    },
    rateAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    tasksContainer: {
        flex: 1,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4, 2),
        marginBottom: theme.spacing(4),
    },
    chip: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(4),
    },
    taskList: {
        maxHeight: '80vh',
        overflow: 'auto',
    },
});

export default styles;
