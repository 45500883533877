import { handleActions } from 'redux-actions';
import { set } from 'lodash';

import {
    CREATE_PROJECT,
    FETCH_PROJECT,
    UPDATE_PROJECT,
    RESET_PROJECT,
    FETCH_FORMS,
    UPDATE_STATUS,
    GET_TOP_PROJECTS,
    CLEAR_PROJECT_INFO,
    GET_PROJECT_MILESTONES,
    UPDATE_PROJECT_FIELD,
    GET_PROJECT_DOCUMENTS_WITH_FILE,
} from './actions/types';

const initialState = {
    topProjects: [],
    projectInfo: {
        id: null,
        status: '',
        general: {},
        detail: {},
        impact: {},
    },
    formFields: [],
    milestones: [],
    allDocs: [],
};

const reducer = handleActions(
    {
        [GET_TOP_PROJECTS]: (state, { payload }) => ({ ...state, topProjects: payload }),
        [CREATE_PROJECT]: (state, { payload }) => ({
            ...state,
            projectInfo: payload,
        }),
        [UPDATE_PROJECT]: (state, { payload }) => ({
            ...state,
            projectInfo: { ...state.projectInfo, ...payload },
        }),
        [UPDATE_STATUS]: (state, { payload }) => ({
            ...state,
            topProjects: state.topProjects.map(project =>
                project.id === state.projectInfo.id ? { ...project, status: payload } : project
            ),
            projectInfo: { ...state.projectInfo, status: payload },
        }),
        [FETCH_FORMS]: (state, { payload }) => ({ ...state, formFields: payload }),
        [FETCH_PROJECT]: (state, { payload }) => ({ ...state, projectInfo: payload }),
        [RESET_PROJECT]: () => initialState,
        [CLEAR_PROJECT_INFO]: state => ({ ...state, projectInfo: initialState.projectInfo }),
        [GET_PROJECT_MILESTONES]: (state, { payload }) => ({ ...state, milestones: payload }),
        [UPDATE_PROJECT_FIELD]: (state, { payload: { fieldPath, fieldValue } }) =>
            set(state, `projectInfo.${fieldPath}`, fieldValue),
        [GET_PROJECT_DOCUMENTS_WITH_FILE]: (state, { payload }) => ({ ...state, allDocs: payload }),
    },
    initialState
);

export default reducer;
