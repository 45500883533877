import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Typography,
    Grid,
    withStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import FileController from './FileController';

const styles = theme => ({
    field: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fieldTitle: {
        fontSize: '1rem',
        textDecoration: 'underline',
    },
    fieldBody: {
        flex: 1,
        fontSize: '1rem',
        backgroundColor: theme.palette.grey[100],
        padding: '12px 0',
    },
});
const FieldGrid = ({
    classes,
    field: { name, label, labelType, currentValue, controlType, fieldDefinition },
    showUpload,
    onUpload,
}) => {
    if (labelType === 'html') {
        label = _.startCase(name);
    }
    const renderValue = () => {
        if (controlType === 'upload') {
            return (
                <FileController
                    filePath={currentValue}
                    fieldName={name}
                    showUpload={showUpload}
                    onUploadFile={onUpload}
                />
            );
        }

        if (controlType === 'complex') {
            if (!fieldDefinition) return null;
            const { fields } = JSON.parse(fieldDefinition);

            return (
                <TableContainer className={classes.fieldBody}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {fields.map(({ label }) => (
                                    <TableCell key={label}>{label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentValue.map((record, index) => (
                                <TableRow key={index}>
                                    {Object.values(record).map((val, index) => (
                                        <TableCell key={index}>{val}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }

        if (_.isBoolean(currentValue)) currentValue = currentValue ? 'yes' : 'no';

        return (
            <Typography variant="body1" className={classes.fieldBody}>
                {currentValue}
            </Typography>
        );
    };
    return (
        <Grid item xs={controlType === 'complex' ? 12 : 4} className={classes.field}>
            <Typography variant="subtitle2" className={classes.fieldTitle}>
                {label}
            </Typography>
            {renderValue()}
        </Grid>
    );
};

FieldGrid.propTypes = {
    classes: PropTypes.object,
    field: PropTypes.object,
    showUpload: PropTypes.bool,
    onUpload: PropTypes.func,
};

export default withStyles(styles)(FieldGrid);
