import { createAction } from 'redux-actions';
import { createActionTypes } from '../../lib/utils';

export const networkRequestActionTypes = createActionTypes('network_request');

export const networkRequestStart = createAction(networkRequestActionTypes.start);
export const networkRequestSuccess = createAction(networkRequestActionTypes.success);
export const networkRequestFail = createAction(networkRequestActionTypes.fail);

export const handleRequestError = (dispatch, requestName, error) =>
    dispatch(
        networkRequestFail({
            requestName,
            code: error.code,
            message: error.message,
        })
    );
