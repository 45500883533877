import { get } from 'lodash';
import { createSelector } from 'reselect';
import { ADD_ON_STATUS } from '../../constants';

export const projectAddOnsSelector = state => get(state, 'addon.projectAddOns', []);

export const completedAddOnsSelector = createSelector(projectAddOnsSelector, addons =>
    addons.filter(addon => addon.status === ADD_ON_STATUS.APPROVED)
);

export const currentAddOnIdSelector = state => get(state, 'addon.selectedAddOnId');

export const AddOnTrackingRecordsSelector = state => get(state, 'addon.AddOnTrackingRecords', []);
