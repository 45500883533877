import { Button, Fab, makeStyles, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { ChartLoader, DataTableLoader, FormDialog, PopoverComment } from '../../components';
import {
    ALERT_LEVEL,
    APPROVAL_STATUS,
    APP_PATHS,
    LOCATION_STATE,
    STATUS_MESSAGE_MAP,
    USER_ROLES,
} from '../../constants';
import { buildProjectPath, navigateTo } from '../../routes/helper';
import { projectService } from '../../services';
import { reviewService } from '../../services/reviewService';
import { clearProjectInfo, createProject } from '../project/actions';
import DocDataTable from '../project/components/DocDataTable';
import { getAllReviewDocuments } from '../review/actions';
import { predefinedDocsSelector } from '../review/selectors';
import { hideSpinner, setAlert, showSpinner } from '../ui/actions';
import { allUsersSelector, currentUserSelector } from '../user/selectors';
import { TodoSection } from './components';
import { checkUserRole } from '../../lib/utils';

const useStyles = makeStyles(theme => ({
    layout: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minWidth: 0, // So the Typography noWrap works
        display: 'flex',
    },
    mainContainer: {
        flex: 3,
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    chartContainer: {
        minHeight: '400px',
    },

    newButton: {
        position: 'absolute',
        right: theme.spacing(8),
        top: theme.spacing(45),
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
    },
}));

const Dashboard = ({ currentUser, createProject, history, clearProjectInfo, location, setAlert, allUsers }) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [allProjects, setAllProjects] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [allDocs, setAllDocs] = useState([]);

    useEffect(() => {
        const getProjectsAndDocs = async () => {
            try {
                setLoadingStatus(true);
                const [projects, allDocs] = await Promise.all([
                    projectService.fetchAllProjects(),
                    reviewService.fetchDocs({ assignedUserId: currentUser.userId }),
                ]);
                setAllProjects(projects);
                setAllDocs(allDocs);
            } catch (error) {
                throw error;
            } finally {
                setLoadingStatus(false);
            }
        };
        clearProjectInfo();
        getProjectsAndDocs();
    }, [clearProjectInfo, currentUser.userId]);

    const isAccountOnly = !checkUserRole(
        currentUser,
        USER_ROLES.ADMIN,
        USER_ROLES.RA_MANAGER,
        USER_ROLES.RA_SPECIALIST,
        USER_ROLES.DE_MANAGER,
        USER_ROLES.PROJECT_CREATOR
    );

    const handleProjectSubmit = async ({ name, identifier }) => {
        const newProject = await createProject(name, identifier);
        if (newProject) {
            setShowDialog(false);
            history.push(buildProjectPath(newProject.id, APP_PATHS.PROJECT_WIZARD));
        }
    };

    const handleProjectClick = id => {
        const selectedProject = allProjects.find(p => p.id === id);
        if (selectedProject) history.push(navigateTo(selectedProject, currentUser));
    };

    const handleProjectStatusChange = (id, status) => {
        setAllProjects(allProjects.map(project => (project.id === id ? { ...project, status } : project)));
    };

    const renderProjectDataTable = () => {
        const columns = [
            'Id',
            {
                name: 'Name',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        //rowData[0] is id
                        return (
                            <Button color="primary" onClick={() => handleProjectClick(tableMeta.rowData[0])}>
                                {value}
                            </Button>
                        );
                    },
                },
            },
            'Identifier',
            'Status',
            {
                name: 'Notes',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        if (!value) return null;
                        const status = tableMeta.rowData[tableMeta.columnIndex - 1];
                        return (
                            <PopoverComment
                                content={value}
                                status={status.includes('Rejected') ? APPROVAL_STATUS.REJECTED : ''}
                            />
                        );
                    },
                },
            },
            'Project Lead',
            { name: 'Created Date', options: { filter: false } },
        ];
        const data = allProjects.map(({ id, name, identifier, status, statusNotes, projectLeadId, dateCreated }) => {
            const projectLead = _.get(
                allUsers.find(user => user.id === projectLeadId),
                'fullName',
                ''
            );
            return [
                id,
                name,
                identifier,
                STATUS_MESSAGE_MAP[status].headerLabel,
                statusNotes,
                projectLead,
                dateCreated,
            ];
        });
        const options = {
            filterType: 'multiselect',
            selectableRows: 'none',
            print: false,
            responsive: 'scrollFullHeight',
        };
        return (
            <div className={classes.tableWrapper}>
                <MUIDataTable columns={columns} data={data} options={options} title="All Projects" />
            </div>
        );
    };

    const renderDataTable = () => {
        if (loadingStatus) return <DataTableLoader />;

        // if (isAccountOnly) {
        //     return (
        //         <div className={classes.tableWrapper}>
        //             <DocDataTable allDocs={allDocs} />
        //         </div>
        //     );
        // }
        return (
            <>
                {renderProjectDataTable()}
                {!_.isEmpty(allDocs) && (
                    <div className={classes.tableWrapper}>
                        <DocDataTable allDocs={allDocs} canViewDetail={true} />
                    </div>
                )}
            </>
        );
    };

    const renderCharts = () => {
        if (loadingStatus) return <ChartLoader />;
        const data = [['Status', 'Project Count']];

        const projectStatusCount = allProjects.reduce((prev, project) => {
            const currentCount = prev[project.status];
            if (currentCount) {
                prev[project.status] = currentCount + 1;
            } else {
                prev[project.status] = 1;
            }
            return prev;
        }, {});

        Object.keys(projectStatusCount).forEach(status =>
            data.push([STATUS_MESSAGE_MAP[status].headerLabel, projectStatusCount[status]])
        );

        const options = {
            title: 'Overall Projects Status',
            pieHole: 0.4,
        };

        return <Chart chartType="PieChart" width="100%" height="400px" data={data} options={options} />;
    };

    if (location) {
        const { state } = location;
        if (state && state[LOCATION_STATE.UNAUTHORIZED]) {
            setAlert({
                variant: ALERT_LEVEL.WARNING,
                message: 'Not authorized to access the page.',
            });
            delete state[LOCATION_STATE.UNAUTHORIZED];
            history.replace({ ...history.location, state });
        }
    }

    const newProject = {
        name: '',
        identifier: '',
    };
    const fields = Object.keys(newProject).map(key => ({
        name: key,
        type: 'text',
        rules: ['required'],
    }));

    return (
        <main className={classes.layout}>
            <section className={classes.mainContainer}>
                <Alert severity="warning">
                    Please refresh browser intermittently to update the display and get the latest action items.
                </Alert>
                {!isAccountOnly && (
                    <div className={classes.chartContainer}>
                        <Paper>{renderCharts()}</Paper>
                        <Fab
                            variant="extended"
                            color="primary"
                            className={classes.newButton}
                            onClick={() => setShowDialog(true)}
                        >
                            New Project
                        </Fab>
                    </div>
                )}

                {renderDataTable()}
            </section>
            {!isAccountOnly && <TodoSection onAssigned={handleProjectStatusChange} />}
            <FormDialog
                title="Create a new project"
                onClose={() => setShowDialog(false)}
                open={showDialog}
                fields={fields}
                formObject={newProject}
                onSubmit={handleProjectSubmit}
            />
        </main>
    );
};

Dashboard.propTypes = {
    createProject: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    allUsers: PropTypes.array,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    allUsers: allUsersSelector(state),
    currentUser: currentUserSelector(state),
    allDocs: predefinedDocsSelector(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        createProject,
        showSpinner,
        hideSpinner,
        clearProjectInfo,
        setAlert,
        getAllReviewDocuments,
    })
)(Dashboard);
