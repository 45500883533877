import { get } from 'lodash';
import { createSelector } from 'reselect';
import { ADD_ON_STATUS } from '../../constants';

export const projectVerificationDocsSelector = state => get(state, 'verificationDoc.projectVerificationDocs', []);

export const completedVerificationDocsSelector = createSelector(projectVerificationDocsSelector, verificationDocs =>
    verificationDocs.filter(verificationDoc => verificationDoc.status === ADD_ON_STATUS.APPROVED)
);

export const currentVerificationDocIdSelector = state => get(state, 'verificationDoc.selectedVerificationDocId');

export const VerificationDocTrackingRecordsSelector = state =>
    get(state, 'verificationDoc.VerificationDocTrackingRecords', []);
