import { highlightRow } from '../../../../components/styles';

const styles = theme => ({
    main: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
    docListSection: {
        width: '20vw',
    },
    reviewTrackSection: {
        flex: 1,
        marginLeft: theme.spacing(3),
    },
    paper: {
        minHeight: '75vh',
        padding: theme.spacing(2),
    },
    trackingTable: {
        marginTop: theme.spacing(3),
    },
    highlightRow: highlightRow(theme),
});

export default styles;
