import { useMemo } from 'react';

const createDocOptions = docs =>
    docs.map(doc => ({
        label: doc.name,
        value: doc.id,
    }));

const useDocOptions = (allDocs, currentDocs) =>
    useMemo(() => {
        const filteredDocs = allDocs.filter(doc => !currentDocs.some(d => d.name === doc.name));
        return createDocOptions(filteredDocs);
    }, [allDocs, currentDocs]);

export default useDocOptions;
