const styles = theme => ({
    titleDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    detailPanel: {
        flexDirection: 'column',
    },
    formTitle: {
        textTransform: 'capitalize',
    },
    fieldGroup: {
        width: '100%',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    fieldsGrid: {
        marginTop: theme.spacing(1),
    },
    footer: {
        textAlign: 'right',
        marginTop: theme.spacing(4),
    },
});

export default styles;
