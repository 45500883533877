import { combineReducers } from 'redux';
import ui from './ui/reducer';
import project from './project/reducer';
import users from './user/reducer';
import review from './review/reducers';
import fee from './fee/reducer';
import logEntries from './logEntries/reducers';
import ecrs from './ecr/reducer';
import addon from './addon/reducer';
import verificationDoc from './verificationDocs/reducer';

const rootReducer = combineReducers({
    ui,
    project,
    users,
    review,
    fee,
    logEntries,
    ecrs,
    addon,
    verificationDoc,
});

export default rootReducer;
