import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, Typography, Button } from '@material-ui/core';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import styles from './styles/dataForm';
import FormField from './FormField';
import { validate } from '../lib/utils';
import ComplexField from './ComplexField';

const DataForm = ({
    onSave,
    onFileUpload,
    setDirtyFlag,
    saveButtonText,
    formSchema,
    formObject,
    classes,
    singleColumn,
    className,
}) => {
    const formGroups = useMemo(
        () =>
            formSchema.map(({ groupLabel, fields }) => ({
                title: groupLabel,
                fieldsNumber: fields.length,
            })),
        [formSchema]
    );

    const fieldsSchema = useMemo(() => formSchema.reduce((result, { fields }) => [...result, ...fields], []), [
        formSchema,
    ]);

    const values = useMemo(
        () =>
            fieldsSchema.reduce(
                (result, field) => ({
                    ...result,
                    [field.name]:
                        field.controlType === 'switch' && formObject[field.name] === null
                            ? false
                            : formObject[field.name],
                }),
                {}
            ),
        [formObject, fieldsSchema]
    );

    const [formFields, setFormFields] = useState(fieldsSchema);
    const [formValues, setFormValues] = useState(values);

    const handleRegularFieldChange = async e => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === 'radio') {
            if (value === 'null') value = null;
            else value = value === 'true';
        }
        if (type === 'file' && onFileUpload) {
            const file = e.target.files[0];
            if (file) {
                const path = await onFileUpload(name, file);
                value = path;
            }
        }
        const validatedFields = formFields.map(field => {
            if (field.name === name) {
                if (!validate(value, field.rules)) return { ...field, error: true };

                return { ...field, error: false };
            }
            return field;
        });

        setFormFields(validatedFields);

        setFormValues({
            ...formValues,
            [name]: value,
        });

        setDirtyFlag(true);
    };

    const handleFieldCheck = name => e => {
        setFormValues({
            ...formValues,
            [name]: e.target.checked,
        });
        setDirtyFlag(true);
    };

    const handleComplexFieldChange = (name, index, nestedFieldName, value) => {
        const currentValue = formValues[name];
        let newValue = [];
        if (isEmpty(currentValue)) newValue = [{ [nestedFieldName]: value }];
        else newValue = currentValue.map((val, i) => (i === index ? { ...val, [nestedFieldName]: value } : val));

        setFormValues({
            ...formValues,
            [name]: newValue,
        });
        setDirtyFlag(true);
    };

    const handleAddComplexRecord = name => {
        if (!formValues[name][0]) return;
        let newRecord = {};
        for (const key in formValues[name][0]) {
            newRecord[key] = '';
        }
        setFormValues({ ...formValues, [name]: [...formValues[name], newRecord] });
    };

    const handleRemoveComplexRecord = (name, index) => {
        const complexValues = formValues[name];
        if (isEmpty(complexValues)) return;

        setFormValues({ ...formValues, [name]: [...complexValues.slice(0, index), ...complexValues.slice(index + 1)] });
    };

    const handleFieldChange = controlType => {
        switch (controlType) {
            case 'switch':
                return handleFieldCheck;
            default:
                return handleRegularFieldChange;
        }
    };

    const handleFormSubmit = e => {
        e.preventDefault();
        let hasError = false;
        const validatedFields = formFields.map(field => {
            const { name, rules = [] } = field;
            if (showField(field) && !validate(formValues[name], rules)) {
                hasError = true;
                return { ...field, error: true };
            }
            return field;
        });
        if (hasError) {
            setFormFields(validatedFields);
        } else {
            onSave(formValues);
        }
    };

    const showField = field => {
        const { condition } = field;
        let show = true;
        if (condition) {
            const [fieldName, operator, value] = condition.split(' ');
            const fieldValue = formValues[fieldName];
            // there may be other type of operators in future
            if (operator === '===') {
                show = `${fieldValue}` === value;
            }
        }
        return show;
    };

    const renderField = field => {
        if (!showField(field)) return null;

        const { name, controlType, label, rules, error } = field;
        let value = formValues[name];

        if (controlType === 'complex') {
            const fieldDefinition = JSON.parse(field.fieldDefinition);
            const { isMultiple, fields } = fieldDefinition;
            if (isEmpty(value)) {
                value = fields.reduce(
                    (result, { name }) => {
                        result[0][name] = '';
                        return result;
                    },
                    [{}]
                );
            }

            return (
                <ComplexField
                    key={name}
                    name={name}
                    label={label}
                    nestedFields={fields}
                    fieldValues={value}
                    allowMulti={isMultiple}
                    onChange={handleComplexFieldChange}
                    onAdd={handleAddComplexRecord}
                    onRemove={handleRemoveComplexRecord}
                />
            );
        }

        return (
            <FormField
                fullWidth={singleColumn}
                key={name}
                field={field}
                onChange={handleFieldChange(controlType)}
                value={value}
                rules={rules}
                error={error}
            />
        );
    };

    const renderSection = (formGroup, index) => {
        const { title, fieldsNumber } = formGroup;
        let start = 0;
        if (index > 0) {
            for (let gIndex = 0; gIndex < index; gIndex++) {
                start += formGroups[gIndex].fieldsNumber;
            }
        }
        const fields = formFields.slice(start, start + fieldsNumber);
        return (
            <section key={title} className={classes.formSection}>
                <Typography variant="subtitle1">{title}</Typography>
                <Paper className={classes.formGroup}>{fields.map(field => renderField(field))}</Paper>
            </section>
        );
    };

    return (
        <form onSubmit={handleFormSubmit} className={cx(classes.form, className)}>
            {formGroups.map((formGroup, index) => renderSection(formGroup, index))}
            <div className={classes.formFooter}>
                <Button type="submit" color="primary" variant="contained">
                    {saveButtonText}
                </Button>
            </div>
        </form>
    );
};

DataForm.propTypes = {
    onSave: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func,
    formSchema: PropTypes.array.isRequired,
    formObject: PropTypes.object,
    saveButtonText: PropTypes.string.isRequired,
    classes: PropTypes.object,
    setDirtyFlag: PropTypes.func,
    singleColumn: PropTypes.bool,
    className: PropTypes.string,
};

DataForm.defaultProps = {
    setDirtyFlag: () => {},
};

export default withStyles(styles)(DataForm);
