export const CREATE_PROJECT = 'CREATE_PROJECT';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';
export const FETCH_FORMS = 'FETCH_FORMS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const GET_TOP_PROJECTS = 'GET_TOP_PROJECTS';
export const ASSIGN_PROJECT = 'ASSIGN_PROJECT';
export const CLEAR_PROJECT_INFO = 'CLEAR_PROJECT_INFO';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const GET_PROJECT_MILESTONES = 'GET_PROJECT_MILESTONES';
export const REMOVE_PROJECT_MILESTONE = 'REMOVE_PROJECT_MILESTONE';
export const UPDATE_PROJECT_FIELD = 'UPDATE_PROJECT_FIELD';
export const GET_PROJECT_DOCUMENTS_WITH_FILE = 'GET_PROJECT_DOCUMENTS_WITH_FILE';
