import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Table, TableHead, TableBody, TableCell, TableRow, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { createShortenLabel } from '../../../utils/label';
import EditableTableCell from '../../../components/EditableTableCell';
import styles from './styles/billTable';

const BillTable = ({ classes, editable, rates, currentBills, onCellChange, onRowRemove }) => {
    const getColumns = editable => {
        let columns = ['Task'];
        const createColumnName = type => (editable ? createShortenLabel(type, 2) : type);
        columns = columns.concat(rates.map(r => `${createColumnName(r.type)}(hr)`)).concat('$');
        return editable ? columns.concat('') : columns;
    };

    const calculateTaskTotal = index => {
        const task = currentBills[index];
        return rates.reduce((total, rate) => total + task[rate.type] * rate.price, 0);
    };

    const calculateHoursTotal = type => currentBills.reduce((total, bill) => total + bill[type], 0);

    const calculateEntireTotal = () =>
        currentBills.reduce((total, bill, index) => total + calculateTaskTotal(index), 0);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    return (
        <Table className={classes.layout}>
            <TableHead>
                <TableRow className={classes.highlightRow}>
                    {getColumns(editable).map((column, index) => (
                        <TableCell key={index}>{column}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {currentBills.map((row, index) => (
                    <TableRow key={index} className={classes.row}>
                        <TableCell>{row.name}</TableCell>
                        {rates.map(({ type }) => (
                            <EditableTableCell
                                key={type}
                                className={classes.numberCellWidth}
                                editable={editable}
                                isNumber
                                value={row[type]}
                                onChange={onCellChange(index, type)}
                            />
                        ))}
                        <TableCell>{formatter.format(calculateTaskTotal(index))}</TableCell>
                        {editable && (
                            <TableCell padding="checkbox">
                                <IconButton onClick={onRowRemove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                <TableRow className={classes.highlightRow}>
                    <TableCell colSpan={1}>Total</TableCell>
                    {rates
                        .map(({ type }) => calculateHoursTotal(type))
                        .map((hour, index) => (
                            <TableCell key={index}>{hour}</TableCell>
                        ))}
                    <TableCell>{formatter.format(calculateEntireTotal())}</TableCell>
                    {editable && <TableCell />}
                </TableRow>
            </TableBody>
        </Table>
    );
};

BillTable.propTypes = {
    classes: PropTypes.object,
    editable: PropTypes.bool,
    rates: PropTypes.array,
    currentBills: PropTypes.array,
    onCellChange: PropTypes.func,
    onRowRemove: PropTypes.func,
};

BillTable.defaultProps = {
    onCellChange: () => {},
    onRowRemove: () => {},
};

export default withStyles(styles)(BillTable);
