import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import { GET_FEE_ITEMS, GET_TASKS, SAVE_FEE_ITEMS } from './types';
import { networkRequestStart, networkRequestFail, networkRequestSuccess } from '../../common/actions';
import { feeService } from '../../../services';
import { feeTasksSelector } from '../selectors';

export const getFeeItems = projectId => async dispatch => {
    const requestName = GET_FEE_ITEMS;
    try {
        dispatch(networkRequestStart({ requestName }));
        const feeItems = await feeService.getFeeItems(projectId);
        const action = createAction(requestName);
        dispatch(action(feeItems));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        dispatch(networkRequestFail({ requestName, code: error.code, message: error.message }));
    }
};

export const saveFeeItems = (projectId, feeItems) => async dispatch => {
    const requestName = SAVE_FEE_ITEMS;
    try {
        dispatch(networkRequestStart({ requestName }));
        await feeService.submitFeeItems(projectId, feeItems);
        const action = createAction(requestName);
        dispatch(action(feeItems));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        dispatch(networkRequestFail({ requestName, code: error.code, message: error.message }));
    }
};

export const getTasks = () => async (dispatch, getState) => {
    if (!isEmpty(feeTasksSelector(getState()))) return;

    const requestName = GET_TASKS;
    try {
        dispatch(networkRequestStart({ requestName }));
        const tasks = await feeService.getTasks();
        const action = createAction(requestName);
        dispatch(action(tasks));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        dispatch(networkRequestFail({ requestName, code: error.code, message: error.message }));
    }
};
