import httpClient from '../lib/httpClient';
import { generateQueryStringByFilters } from '../lib/utils';

const client = new httpClient();

const fetchPredefinedDocs = filters => {
    let queryString = '';
    if (filters) {
        queryString = generateQueryStringByFilters([filters]);
    }
    return client.get(`documents${queryString}`);
};

const fetchDocReviewsByProject = projectId => client.get(`projects/${projectId}/documents`);

const postDocsToProject = (projectId, docs) => client.post(`projects/${projectId}/documents`, docs);

const fetchDocs = filters => {
    let queryString = '';
    if (filters) {
        queryString = generateQueryStringByFilters([filters]);
    }
    return client.get(`projectdocuments/${queryString}`);
};
const deleteProjectDoc = projectDocId => client.delete(`projectdocuments/${projectDocId}`);

const uploadProjectDoc = (projectDocId, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return client.post(`uploads/projectdocuments/${projectDocId}`, data, true);
};

const fetchReviewAssignments = projectDocId => client.get(`projectdocuments/${projectDocId}/reviewassignments`);

const postReviewAssignments = (projectDocId, assignments) =>
    client.post(`projectdocuments/${projectDocId}/reviewassignments`, assignments);

const fetchTimelineNodes = projectDocId => client.get(`projectdocuments/${projectDocId}/timelinenodes`);

const postTimelineNode = (projectDocId, timelineNode) =>
    client.post(`projectdocuments/${projectDocId}/timelinenodes`, timelineNode);

export const reviewService = {
    fetchPredefinedDocs,
    fetchDocReviewsByProject,
    postDocsToProject,
    deleteProjectDoc,
    uploadProjectDoc,
    fetchReviewAssignments,
    postReviewAssignments,
    fetchTimelineNodes,
    postTimelineNode,
    fetchDocs,
};
