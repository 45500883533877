import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControlLabel,
    Switch,
    withStyles,
    TextField,
    Button,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import cx from 'classnames';
import _ from 'lodash';

import { transferNameToLabel } from '../utils/label';
import { getFileNameFromPath } from '../lib/utils';

const styles = theme => ({
    formField: {
        minWidth: '45%',
    },

    uploadInput: {
        opacity: '0',
    },

    fullWidth: {
        width: '100%',
    },

    customField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    rightIcon: {
        marginLeft: theme.spacing(1),
    },

    radioGroup: {
        flexDirection: 'row',
    },
});

const FormField = ({ field, value, onChange, classes, fullWidth }) => {
    let { name, controlType, label, labelType, options, rules, error = false } = field;
    if (!label) {
        label = transferNameToLabel(name);
    }
    let validations = {};
    let inputProps = {};
    const rule = _.get(rules, 0);
    if (rule === 'required') {
        if (controlType === 'upload' && value) {
            validations.required = false;
        } else validations.required = true;
    } else {
        validations.type = rule;
        if (rule === 'tel') {
            validations.placeholder = '###-###-####';
            inputProps.pattern = '\\d{3}-\\d{3}-\\d{4}';
        }
    }

    const renderHtmlLabel = labelType === 'html';

    switch (controlType) {
        default:
            return (
                <TextField
                    error={error}
                    fullWidth={fullWidth}
                    className={classes.formField}
                    key={name}
                    name={name}
                    label={label}
                    margin="normal"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={inputProps}
                    {...validations}
                />
            );
        case 'multiline':
            return (
                <TextField
                    error={error}
                    fullWidth={fullWidth}
                    className={classes.formField}
                    key={name}
                    name={name}
                    label={label}
                    multiline
                    rows={3}
                    margin="normal"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...validations}
                />
            );
        case 'select':
            return (
                <TextField
                    fullWidth={fullWidth}
                    className={classes.formField}
                    key={name}
                    name={name}
                    select
                    label={label}
                    margin="normal"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    error={error}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    {...validations}
                >
                    <option value="">None</option>
                    {options.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </TextField>
            );
        case 'switch':
            if (renderHtmlLabel)
                return (
                    <FormControl fullWidth>
                        <div dangerouslySetInnerHTML={{ __html: label }} />
                        <Switch
                            checked={value || false}
                            onChange={onChange(name)}
                            value={value || false}
                            color="primary"
                        />
                    </FormControl>
                );
            return (
                <FormControl fullWidth={fullWidth} className={cx(classes.formField, classes.customField)}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={value || false}
                                onChange={onChange(name)}
                                value={value || false}
                                color="primary"
                            />
                        }
                        label={label}
                    />
                </FormControl>
            );
        case 'nullableswitch': {
            const stringifyValue = JSON.stringify(value);
            return (
                <FormControl fullWidth={fullWidth} className={cx(classes.formField, classes.customField)}>
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup
                        aria-label={label}
                        name={name}
                        value={stringifyValue}
                        onChange={onChange}
                        className={classes.radioGroup}
                    >
                        <FormControlLabel value="true" control={<Radio />} label="yes" />
                        <FormControlLabel value="false" control={<Radio />} label="no" />
                        <FormControlLabel value="null" control={<Radio />} label="n/a" />
                    </RadioGroup>
                </FormControl>
            );
        }
        case 'upload':
            return (
                <div className={cx(classes.formField, classes.customField)} key={name}>
                    <Typography variant="subtitle1">{getFileNameFromPath(value)}</Typography>
                    <label htmlFor={`upload-file-${name}`}>
                        <Button variant="outlined" component="span" fullWidth>
                            {label}
                            <CloudUpload className={classes.rightIcon} />
                        </Button>
                    </label>
                    <input
                        name={name}
                        className={classes.uploadInput}
                        id={`upload-file-${name}`}
                        multiple
                        type="file"
                        onChange={onChange}
                        {...validations}
                    />
                </div>
            );
    }
};

FormField.propTypes = {
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    classes: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
};

export default withStyles(styles)(FormField);
