import React from 'react';
import { HeaderLayout, MainLayout } from '../layouts';
import { Auth } from '../modules/auth';
import { Dashboard } from '../modules/dashboard';

export const AuthPage = () => (
    <HeaderLayout>
        <Auth />
    </HeaderLayout>
);

export const DashboardPage = () => (
    <HeaderLayout>
        <Dashboard />
    </HeaderLayout>
);

export const MainPage = () => (
    <HeaderLayout>
        <MainLayout />
    </HeaderLayout>
);
export { default as LandingPage } from './Landing';
