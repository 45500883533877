import React from 'react';
import PropTypes from 'prop-types';

import { Modal, CircularProgress, Paper, withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
    layout: {
        position: 'absolute',
        width: theme.spacing(25),
        padding: theme.spacing(4),
        outline: 'none',
        top: '45%',
        left: '45%',
        textAlign: 'center',
    },
    text: {
        textTransform: 'capitalize',
        marginTop: theme.spacing(2),
    },
});
const Spinner = ({ open = false, title = 'loading...', classes }) => (
    <Modal open={open}>
        <Paper className={classes.layout}>
            <CircularProgress size={50} />
            <Typography color="textPrimary" variant="subtitle2" className={classes.text}>
                {title}
            </Typography>
        </Paper>
    </Modal>
);

Spinner.propTypes = {
    open: PropTypes.bool,
    classes: PropTypes.object,
    title: PropTypes.string,
};

export default withStyles(styles)(Spinner);
