import { Checkbox, Divider, FormControlLabel, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

const useStyles = makeStyles(theme => ({
    recurringSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    textLabel: {
        minWidth: '100px',
        textAlign: 'right',
        marginRight: theme.spacing(1),
    },
    textFieldRoot: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    textField: {
        width: '220px',
    },
    checkField: {
        marginBottom: theme.spacing(2),
    },

    divider: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const LabelTextField = ({ startLabel, name, type, endLabel }) => {
    const classes = useStyles();
    return (
        <FormControlLabel
            classes={{ label: classes.textLabel, root: classes.textFieldRoot }}
            control={
                <TextField
                    required
                    className={classes.textField}
                    variant="outlined"
                    name={name}
                    type={type}
                    InputProps={
                        endLabel && {
                            endAdornment: <InputAdornment position="end">{endLabel}</InputAdornment>,
                        }
                    }
                />
            }
            label={startLabel}
            labelPlacement="start"
        />
    );
};

const RecurringSection = () => {
    const classes = useStyles();
    const [isRecurring, setIsRecurring] = useState(false);

    return (
        <section className={classes.recurringSection}>
            <FormControlLabel
                classes={isRecurring ? null : { root: classes.checkField }}
                control={
                    <Checkbox checked={isRecurring} color="secondary" onChange={() => setIsRecurring(!isRecurring)} />
                }
                label="Task Is Recurring"
            />
            {!isRecurring && (
                <TextField
                    variant="outlined"
                    fullWidth
                    name="endDate"
                    label="Due Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                />
            )}
            <Divider className={classes.divider} />
            {isRecurring && (
                <Fragment>
                    <LabelTextField startLabel="Start From" type="date" name="startDate" />
                    <div>
                        <LabelTextField
                            startLabel="Recurs Every"
                            type="number"
                            name="recurringMonth"
                            endLabel="Months"
                        />
                        <LabelTextField startLabel="For" type="number" name="recurringTimes" endLabel="Times" />
                    </div>
                </Fragment>
            )}
        </section>
    );
};

export default RecurringSection;
