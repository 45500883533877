import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Chip,
    withStyles,
    Input,
    InputAdornment,
    IconButton,
} from '@material-ui/core';

import { Face as FaceIcon, AddCircle as AddIcon } from '@material-ui/icons';

import styles from './styles/emailDialog';

export class EmailDialog extends Component {
    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.string,
        onClose: PropTypes.func,
        open: PropTypes.bool,
        beforeEmailSend: PropTypes.func,
        defaultRecipients: PropTypes.array,
        classes: PropTypes.object,
    };

    state = {
        recipients: [],
        newEmail: '',
    };

    componentDidMount = () => {
        this.setState({
            recipients: [...this.props.defaultRecipients],
        });
    };

    handleSend = async () => {
        const { beforeEmailSend, onClose } = this.props;
        const success = await beforeEmailSend();
        if (success) {
            //TODO: call email service to send email
            onClose();
        }
    };

    handleEmailDelete = index => e => {
        const emails = [...this.state.recipients];
        emails.splice(index, 1);
        this.setState({
            recipients: emails,
        });
    };

    handleAddEmail = () => {
        this.setState({
            recipients: [...this.state.recipients, this.state.newEmail],
            newEmail: '',
        });
    };

    handleNewEmailChange = e => {
        this.setState({
            newEmail: e.target.value,
        });
    };

    render() {
        const { classes, title, onClose, open } = this.props;
        const { recipients, newEmail } = this.state;

        return (
            <Dialog onClose={onClose} open={open} className={classes.layout}>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Email notification will be sent to following recipient. Add or remove any if needed.
                    </DialogContentText>
                    <div className={classes.emailsContainer}>
                        {recipients.map((rev, index) => (
                            <Chip
                                key={index}
                                label={rev}
                                color="primary"
                                onDelete={this.handleEmailDelete(index)}
                                icon={<FaceIcon />}
                                variant="outlined"
                                className={classes.emailItem}
                            />
                        ))}
                        <Input
                            type="email"
                            placeholder="Email"
                            className={classes.emailItem}
                            onChange={this.handleNewEmailChange}
                            value={newEmail}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton color="primary" onClick={this.handleAddEmail}>
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <TextField variant="outlined" label="Message" multiline rows="5" fullWidth />
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleSend}
                            disabled={recipients.length === 0}
                        >
                            Send
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(EmailDialog);
