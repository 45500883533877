import React from 'react';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { renderLabels } from '../../../constants/labels';
import { buildProjectPath } from '../../../routes/helper';
import { APP_PATHS, DOC_REVIEW_STAGE } from '../../../constants';

import FileDownloadButton from '../../../components/FileDownloadButton';

const DocDataTable = ({ allDocs, history, canViewDetail }) => {
    const renderActionButton = recordIndex => {
        const record = allDocs[recordIndex];
        if (record.path) return <FileDownloadButton label="Download" filePath={record.path} />;
        return null;
    };
    const handleDocRowClick = recordIndex => {
        const selectedDoc = allDocs[recordIndex];
        let url = '';
        if (selectedDoc.stage === DOC_REVIEW_STAGE.ADDONS) {
            url = buildProjectPath(selectedDoc.projectId, APP_PATHS.ADD_ON);
        } else if (selectedDoc.stage === DOC_REVIEW_STAGE.VERIFICATIONS) {
            url = buildProjectPath(selectedDoc.projectId, APP_PATHS.VERIFICATION_DOCS);
        } else {
            url = buildProjectPath(selectedDoc.projectId, APP_PATHS[`REVIEW_${selectedDoc.stage}`]);
        }

        history.push(`${url}?documentId=${selectedDoc.id}`);
    };

    const columns = [
        {
            name: 'Name',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (canViewDetail)
                        return (
                            <Button color="primary" onClick={() => handleDocRowClick(tableMeta.rowIndex)}>
                                {value}
                            </Button>
                        );
                    else return <span>{value}</span>;
                },
            },
        },
        'Status',
        'Stage',
        'Type',
        {
            name: '',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => renderActionButton(tableMeta.rowIndex),
            },
        },
    ];
    const data = allDocs.map(({ name, status, stage, type }) => [
        name,
        renderLabels(status),
        renderLabels(stage),
        renderLabels(type),
    ]);
    const options = {
        filterType: 'multiselect',
        selectableRows: 'none',
        // onRowClick: handleDocRowClick,
        sort: false,
        print: false,
        responsive: 'scrollFullHeight',
    };

    return <MUIDataTable columns={columns} data={data} options={options} title="All Documents" />;
};

DocDataTable.propTypes = {
    allDocs: PropTypes.array,
    history: PropTypes.object,
    canViewDetail: PropTypes.bool,
};

export default compose(withRouter)(DocDataTable);
