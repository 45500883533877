import { handleActions, combineActions } from 'redux-actions';
import {
    CREATE_LOG_ENTRY,
    UPDATE_LOG_ENTRY,
    REMOVE_LOG_ENTRIES,
    GET_DOC_LOG_ENTRIES,
    GET_PROJECT_LOG_ENTRIES,
} from '../actions/types';
const initialState = [];

const reducer = handleActions(
    {
        [CREATE_LOG_ENTRY]: (state, { payload }) => [payload, ...state],
        [UPDATE_LOG_ENTRY]: (state, { payload }) => state.map(log => (log.id === payload.id ? payload : log)),
        [REMOVE_LOG_ENTRIES]: (state, { payload }) => state.filter(log => !payload.includes(log.id)),
        [combineActions(GET_PROJECT_LOG_ENTRIES, GET_DOC_LOG_ENTRIES)]: (state, { payload }) => payload,
    },
    initialState
);

export default reducer;
