import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { compose } from 'redux';
import {
    Button,
    withStyles,
    List,
    ListItem,
    ListSubheader,
    MenuItem,
    TextField,
    InputAdornment,
    Divider,
    Badge,
    Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import { USER_ROLES, PROJECT_STATUS, STATUS_MESSAGE_MAP, APPROVAL_STATUS } from '../../../constants';
import { SideSection, ListLoader, PopoverComment } from '../../../components';
import styles from './styles/todoSection';
import { projectService } from '../../../services';
import { showSpinner, hideSpinner } from '../../ui/actions';
import { assignProject } from '../../project/actions';
import { buildProjectPath } from '../../../routes/helper';
import { allUsersSelector, currentUserSelector } from '../../user/selectors';

const TodoSection = ({ classes, user, raSpecialists, showSpinner, hideSpinner, assignProject, onAssigned }) => {
    const [toDoProjects, setToDoProjects] = useState({});
    const [loadingTodo, setLoadingTodo] = useState(false);

    useEffect(() => {
        const setToDoItems = (statuses, projects) => {
            if (!_.isEmpty(projects)) {
                const todos = statuses.reduce(
                    (result, status) => ({
                        ...result,
                        [status]: projects.filter(p => p.status === status),
                    }),
                    {}
                );
                setToDoProjects(todos);
            }
        };
        const getTodoProjectsByUserRole = async () => {
            showSpinner();
            setLoadingTodo(true);
            if (user.roles.includes(USER_ROLES.RA_MANAGER)) {
                const statusToSearch = [
                    PROJECT_STATUS.INTAKE_ACCEPTED,
                    PROJECT_STATUS.INTAKE_SUBMITTED,
                    PROJECT_STATUS.FEE_SUBMITTED,
                    PROJECT_STATUS.DOC_REVIEW_COMPLETE,
                ];
                const projects = await projectService.filterProjectsBy([['status', 'in', statusToSearch.join(',')]]);
                setToDoItems(statusToSearch, projects);
            } else if (user.roles.includes(USER_ROLES.RA_SPECIALIST)) {
                const status1 = [
                    PROJECT_STATUS.INTAKE_ASSIGNED,
                    PROJECT_STATUS.FEE_PENDING,
                    PROJECT_STATUS.FEE_REJECTED,
                    PROJECT_STATUS.FEE_ACCEPTED,
                    PROJECT_STATUS.DOC_REVIEW_PENDING,
                ];
                const query1 = { assignedStaffId: user.userId };
                const status2 = PROJECT_STATUS.INTAKE_REJECTED;
                const query2 = { projectLeadId: user.userId };
                const [result1, result2] = await Promise.all([
                    projectService.filterProjectsBy([['status', 'in', status1.join(',')], query1]),
                    projectService.filterProjectsBy([{ status: status2 }, query2]),
                ]);
                setToDoItems([...status1, status2], [...result1, ...result2]);
            } else if (user.roles.includes(USER_ROLES.DE_MANAGER)) {
                const statusToSearch = [
                    PROJECT_STATUS.INTAKE_NOT_SUBMITTED,
                    PROJECT_STATUS.INTAKE_REJECTED,
                    PROJECT_STATUS.FEE_APPROVED,
                    PROJECT_STATUS.OVERHEAD_SUBMITTED,
                ];
                const projects = await projectService.filterProjectsBy([['status', 'in', statusToSearch.join(',')]]);
                setToDoItems(statusToSearch, projects);
            } else if (user.roles.includes(USER_ROLES.PROJECT_CREATOR)) {
                const statusToSearch = [
                    PROJECT_STATUS.INTAKE_NOT_SUBMITTED,
                    PROJECT_STATUS.INTAKE_REJECTED,
                    PROJECT_STATUS.FEE_APPROVED,
                ];
                const query = { projectLeadId: user.userId };
                const projects = await projectService.filterProjectsBy([
                    ['status', 'in', statusToSearch.join(',')],
                    query,
                ]);
                setToDoItems(statusToSearch, projects);
            }
            hideSpinner();
            setLoadingTodo(false);
        };
        if (user) getTodoProjectsByUserRole();
    }, [hideSpinner, showSpinner, user]);

    const handleAssignChange = projectId => async ({ target }) => {
        const assignTo = target.value;
        if (assignTo) {
            const success = await assignProject(projectId, assignTo);
            if (success) {
                setToDoProjects({
                    ...toDoProjects,
                    [PROJECT_STATUS.INTAKE_ACCEPTED]: toDoProjects[PROJECT_STATUS.INTAKE_ACCEPTED].filter(
                        p => p.id !== projectId
                    ),
                });
                onAssigned(projectId, PROJECT_STATUS.INTAKE_ASSIGNED);
            }
        }
    };

    const renderTodoItem = project => {
        return (
            <Button
                fullWidth
                component={Link}
                to={buildProjectPath(project.id, STATUS_MESSAGE_MAP[project.status].navigateTo)}
            >
                {project.name}
            </Button>
        );
    };

    const renderTodoAction = project => {
        if (project.status === PROJECT_STATUS.INTAKE_ACCEPTED) {
            return (
                <ListItem className={classes.itemAction}>
                    <TextField
                        fullWidth
                        select
                        label="Assign To Specialist"
                        value={''}
                        onChange={handleAssignChange(project.id)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    >
                        {raSpecialists.map(sp => (
                            <MenuItem key={sp.id} value={sp.id}>
                                {`${sp.fullName}`}
                            </MenuItem>
                        ))}
                    </TextField>
                </ListItem>
            );
        }
    };

    const renderListLoader = () =>
        _.times(4, index => (
            <div key={index} className={classes.listLoader}>
                <ListLoader />
                <Divider />
            </div>
        ));

    const renderTodoList = () =>
        Object.keys(toDoProjects).map((key, index) => (
            <React.Fragment key={key}>
                <List className={classes.list} dense>
                    <ListSubheader className={classes.todoHeader}>
                        <Badge color="primary" badgeContent={toDoProjects[key].length}>
                            <Typography className={classes.todoTitle}>
                                {STATUS_MESSAGE_MAP[key].headerLabel + ' Projects'}
                            </Typography>
                        </Badge>
                    </ListSubheader>
                    {toDoProjects[key].map(project => (
                        <React.Fragment key={project.id}>
                            <ListItem>
                                {project.statusNotes ? (
                                    <PopoverComment
                                        content={project.statusNotes}
                                        status={project.status.includes('Rejected') ? APPROVAL_STATUS.REJECTED : ''}
                                    />
                                ) : (
                                    <div className={classes.listItemIconHolder} />
                                )}
                                {renderTodoItem(project)}
                            </ListItem>
                            {renderTodoAction(project)}
                        </React.Fragment>
                    ))}
                </List>
                {index !== toDoProjects.length - 1 && <Divider />}
            </React.Fragment>
        ));

    return (
        <SideSection title="Action Items" className={classes.layout}>
            {!user || loadingTodo ? renderListLoader() : renderTodoList()}
        </SideSection>
    );
};

TodoSection.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object.isRequired,
    assignProject: PropTypes.func,
    showSpinner: PropTypes.func,
    hideSpinner: PropTypes.func,
    onAssigned: PropTypes.func,
};

const mapStateToProps = state => ({
    user: currentUserSelector(state),
    raSpecialists: allUsersSelector(state).filter(user => user.roles.includes(USER_ROLES.RA_SPECIALIST)),
});

export default compose(
    connect(mapStateToProps, { assignProject, showSpinner, hideSpinner }),
    withStyles(styles)
)(TodoSection);
