import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import DataForm from '../../../components/DataForm';
import { PROJECT_PORTION, ALERT_LEVEL, APP_PATHS } from '../../../constants';
import { projectIdSelector, getFormFields, getProjectInfo } from '../../project/selectors';
import { saveProjectInfo } from '../../project/actions';
import { setAlert } from '../../ui/actions';
import { buildProjectPath } from '../../../routes/helper';

const styles = () => ({
    dataForm: {
        width: '50%',
    },
});

const FeeFunding = ({ classes, currentProjectId, projectFeeInitial, feeInitialForm, saveProjectInfo, setAlert }) => {
    const [redirect, setRedirect] = useState('');

    const handleSaveData = async data => {
        const success = await saveProjectInfo(currentProjectId, PROJECT_PORTION.FEE_INITIAL_SCREEN, data);
        if (success) {
            if (data.fundingSource === 'Overhead') {
                setAlert({
                    variant: ALERT_LEVEL.SUCCESS,
                    message: 'Initial screening is done and wait for overhead approval.',
                });
                setRedirect(APP_PATHS.DASHBOARD);
            } else {
                setRedirect(buildProjectPath(currentProjectId, APP_PATHS.FEE_BILLING));
            }
        }
    };

    if (_.isEmpty(feeInitialForm)) return null;
    if (_.isEmpty(projectFeeInitial)) return null;
    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <DataForm
            formSchema={feeInitialForm}
            formObject={projectFeeInitial}
            onSave={handleSaveData}
            saveButtonText="Submit"
            className={classes.dataForm}
            singleColumn
        />
    );
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    feeInitialForm: _.get(getFormFields(state), PROJECT_PORTION.FEE_INITIAL_SCREEN, []),
    projectFeeInitial: _.get(getProjectInfo(state), PROJECT_PORTION.FEE_INITIAL_SCREEN, {}),
});

FeeFunding.propTypes = {
    classes: PropTypes.object,
    saveProjectInfo: PropTypes.func,
    currentProjectId: PropTypes.number,
    projectFeeInitial: PropTypes.object,
    feeInitialForm: PropTypes.array,
    history: PropTypes.object,
};

export default compose(
    withRouter,
    connect(mapStateToProps, { saveProjectInfo, setAlert }),
    withStyles(styles)
)(FeeFunding);
