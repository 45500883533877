import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';
import { createStore } from './lib/createStore';
import rootReducer from './modules/rootReducer';
import { authService } from './services';
import ErrorBoundary from './ErrorBoundary';
import { setCurrentUserInfo } from './modules/user/actions';

const store = createStore(rootReducer);

if (authService.isTokenValid()) {
    const { userInfo } = authService.getLocalAuth();
    store.dispatch(setCurrentUserInfo(userInfo));
}

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary store={store}>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
