import { Snackbar, SnackbarContent, withStyles, IconButton } from '@material-ui/core';
import { CheckCircle, Error as ErrorIcon, Info, Warning, Close } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ALERT_LEVEL } from '../constants';
import styles from './styles/alert';

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: ErrorIcon,
    info: Info,
};

class Alert extends Component {
    static propTypes = {
        classes: PropTypes.object,
        onClose: PropTypes.func,
        message: PropTypes.string,
        variant: PropTypes.string,
        open: PropTypes.bool,
    };
    static defaultProps = {
        open: false,
        message: '',
        variant: ALERT_LEVEL.ERROR,
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        const { onClose } = this.props;
        onClose();
    };

    render() {
        const { classes, variant, message, open } = this.props;
        const Icon = variantIcon[variant];

        return (
            <Snackbar open={open} autoHideDuration={5000} onClose={this.handleClose}>
                <SnackbarContent
                    className={classes[variant]}
                    aria-describedby="client-snackbar"
                    message={
                        <span className={classes.message}>
                            <Icon className={cx(classes.icon, classes.iconVariant)} />
                            {message}
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose}
                        >
                            <Close className={classes.icon} />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );
    }
}

export default withStyles(styles)(Alert);
