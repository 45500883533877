import { handleActions } from 'redux-actions';
import {
    GET_PROJECT_VERIFICATIONDOCS,
    SET_CURRENT_VERIFICATIONDOC,
    SAVE_PROJECT_VERIFICATIONDOCS,
    GET_VERIFICATIONDOC_TRACKINGS,
    SAVE_VERIFICATIONDOC_TRACKING,
    UPDATE_VERIFICATIONDOC_TRACKING,
    REMOVE_VERIFICATIONDOC_TRACKING,
} from './actions/types';

const initialState = {
    projectVerificationDocs: [],
    selectedVerificationDocId: -1,
    VerificationDocTrackingRecords: [],
};

const reducer = handleActions(
    {
        [GET_PROJECT_VERIFICATIONDOCS]: (state, { payload }) => ({ ...state, projectVerificationDocs: payload }),
        [SET_CURRENT_VERIFICATIONDOC]: (state, { payload }) => ({ ...state, selectedVerificationDocId: payload }),
        [SAVE_PROJECT_VERIFICATIONDOCS]: (state, { payload }) => ({
            ...state,
            projectVerificationDocs: [...state.projectVerificationDocs, ...payload],
        }),
        [GET_VERIFICATIONDOC_TRACKINGS]: (state, { payload }) => ({
            ...state,
            VerificationDocTrackingRecords: payload,
        }),
        [SAVE_VERIFICATIONDOC_TRACKING]: (state, { payload }) => ({
            ...state,
            VerificationDocTrackingRecords: [...state.VerificationDocTrackingRecords, payload],
        }),
        [UPDATE_VERIFICATIONDOC_TRACKING]: (state, { payload }) => ({
            ...state,
            VerificationDocTrackingRecords: state.VerificationDocTrackingRecords.map(item =>
                item.id === payload.id ? { ...item, ...payload } : item
            ),
        }),
        [REMOVE_VERIFICATIONDOC_TRACKING]: (state, { payload }) => ({
            ...state,
            VerificationDocTrackingRecords: state.VerificationDocTrackingRecords.filter(item => item.id !== payload),
        }),
    },
    initialState
);

export default reducer;
