import _ from 'lodash';
import { createSelector } from 'reselect';
import { PROJECT_PORTION } from '../../constants';

export const projectIdSelector = ({ project }) => _.get(project, ['projectInfo', 'id']);
export const projectNameSelector = ({ project }) => _.get(project, ['projectInfo', 'general', 'name']);
export const projectStatusSelector = ({ project }) => _.get(project, ['projectInfo', 'status']);
export const projectLeadIdSelector = ({ project }) => _.get(project, ['projectInfo', 'general', 'projectLeadId']);
export const projectNoteSelector = ({ project }) => _.get(project, ['projectInfo', 'statusNotes']);
export const projectLeadNameSelector = ({ project }) => _.get(project, ['projectInfo', 'general', 'projectLeadName']);
export const assignedStaffNameSelector = ({ project }) =>
    _.get(project, ['projectInfo', 'general', 'assignedStaffName']);
export const getFormFields = ({ project }) => {
    const formFields = _.get(project, 'formFields');
    if (formFields) {
        return formFields.reduce((result, { formName, formGroups }) => {
            result[formName] = [...formGroups];
            return result;
        }, {});
    }
};

export const getProjectInfo = ({ project }) => _.get(project, 'projectInfo');

export const getProjectFieldsWithValue = createSelector(getFormFields, getProjectInfo, (projectForms, projectInfo) => {
    if (_.isEmpty(projectForms)) return null;
    if (_.isEmpty(projectInfo[PROJECT_PORTION.GENERAL])) return null;
    Object.keys(projectForms).forEach(formName => {
        projectForms[formName].forEach(group => {
            group.fields.forEach(field => (field.currentValue = projectInfo[formName][field.name]));
        });
    });
    return projectForms;
});

export const getTopProjectSearchOptions = ({ project }) => {
    const projects = _.get(project, 'topProjects', []);
    return createProjectSelectOptions(projects);
};

export const createProjectSelectOptions = projects =>
    projects.map(({ id, status, name, identifier }) => ({
        value: JSON.stringify({ id, status }),
        label: `${name} (${identifier})`,
    }));

export const projectMilestonesSelector = ({ project }) => _.get(project, 'milestones', []);

export const projectDocumentsWithFileSelector = ({ project }) => _.get(project, 'allDocs', []);
