import { createAction } from 'redux-actions';
import { omit } from 'lodash';

import {
    GET_PROJECT_VERIFICATIONDOCS,
    SET_CURRENT_VERIFICATIONDOC,
    SAVE_PROJECT_VERIFICATIONDOCS,
    SAVE_VERIFICATIONDOC_TRACKING,
    GET_VERIFICATIONDOC_TRACKINGS,
    UPLOAD_VERIFICATIONDOC_TRACKING_FILE,
    UPDATE_VERIFICATIONDOC_TRACKING,
    REMOVE_VERIFICATIONDOC_TRACKING,
} from './types';
import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';

import { verificationDocService } from '../../../services';

import { currentVerificationDocIdSelector } from '../selectors';
import { projectIdSelector } from '../../project/selectors';
import { currentUserSelector } from '../../user/selectors';
import { USER_ROLES } from '../../../constants';
import { checkUserRole } from '../../../lib/utils';

export const getProjectVerificationDocs = () => async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    if (!checkUserRole(currentUser, USER_ROLES.ADMIN, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST)) return;

    const projectId = projectIdSelector(getState());
    const requestName = GET_PROJECT_VERIFICATIONDOCS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const verificationDocs = await verificationDocService.fetchVerificationDocsByProject(projectId);
        const action = createAction(requestName);
        dispatch(action(verificationDocs));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const setCurrentVerificationDoc = createAction(SET_CURRENT_VERIFICATIONDOC);

export const saveVerificationDocsToProject = newVerificationDocs => async (dispatch, getState) => {
    const projectId = projectIdSelector(getState());
    const requestName = SAVE_PROJECT_VERIFICATIONDOCS;
    dispatch(networkRequestStart(requestName));
    try {
        const verificationDocs = await verificationDocService.postVerificationDocsToProject(
            projectId,
            newVerificationDocs
        );
        const action = createAction(requestName);
        dispatch(action(verificationDocs));
        dispatch(networkRequestSuccess(requestName));
        return verificationDocs;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const saveVerificationDocTrackingRecordToDocument = newRecord => async (dispatch, getState) => {
    const addOnId = currentVerificationDocIdSelector(getState());
    const requestName = SAVE_VERIFICATIONDOC_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        const record = await verificationDocService.postVerificationDocHistoryToVerificationDoc(addOnId, newRecord);
        const action = createAction(requestName);
        dispatch(action(record));
        dispatch(networkRequestSuccess(requestName));
        return record;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getVerificationDocTrackingRecords = () => async (dispatch, getState) => {
    const addOnId = currentVerificationDocIdSelector(getState());
    const requestName = GET_VERIFICATIONDOC_TRACKINGS;
    dispatch(networkRequestStart(requestName));
    try {
        const records = await verificationDocService.fetchVerificationDocHistoryByVerificationDocId(addOnId);
        const action = createAction(requestName);
        dispatch(action(records));
        dispatch(networkRequestSuccess(requestName));
        return records;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const uploadVerificationDocTrackingFile = file => async (dispatch, getState) => {
    const addOnId = currentVerificationDocIdSelector(getState());
    const requestName = UPLOAD_VERIFICATIONDOC_TRACKING_FILE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const { path } = await verificationDocService.uploadVerificationDocHistoryDoc(addOnId, file);
        dispatch(networkRequestSuccess({ requestName }));
        return path;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const updateVerificationDocTrackingRecord = updatedRecord => async dispatch => {
    const requestName = UPDATE_VERIFICATIONDOC_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        await verificationDocService.putVerificationDocHistoryById(updatedRecord.id, {
            ...omit(updatedRecord, 'id', 'file'),
            path: updatedRecord.file.path,
        });
        const action = createAction(requestName);
        dispatch(action(updatedRecord));
        dispatch(networkRequestSuccess(requestName));
        return updatedRecord;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const removeVerificationDocTrackingRecord = id => async dispatch => {
    const requestName = REMOVE_VERIFICATIONDOC_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        await verificationDocService.deleteVerificationDocHistory(id);
        const action = createAction(requestName);
        dispatch(action(id));
        dispatch(networkRequestSuccess(requestName));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
