import React from 'react';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import httpClient from '../lib/httpClient';

const DropFileUploader = ({ onFileUploaded, postUrl, onUploadFailed, onFileRemoved }) => {
    const client = new httpClient();
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    const djsConfig = {
        addRemoveLinks: true,
        maxFiles: 1,
        headers: client.getAuthHeader(),
    };

    const componentConfig = {
        iconFiletypes: ['.doc', '.pdf', '.png', '....'],
        showFiletypeIcon: true,
        postUrl,
    };

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
        success: onFileUploaded,
        error: onUploadFailed,
        removedfile: onFileRemoved,
    };

    return <DropzoneComponent config={componentConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} />;
};

DropFileUploader.propTypes = {
    onFileUploaded: PropTypes.func.isRequired,
    onUploadFailed: PropTypes.func,
    postUrl: PropTypes.string.isRequired,
    onFileRemoved: PropTypes.func.isRequired,
};

export default DropFileUploader;
