import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Grid } from '@material-ui/core';
import FormField from './FormField';
import { AddCircle, RemoveCircleOutline } from '@material-ui/icons';

const ComplexField = ({ name, label, nestedFields, fieldValues, onChange, onAdd, onRemove, allowMulti }) => {
    const handleFieldChange = (index, nestedFieldName) => e => {
        const { value } = e.target;
        onChange(name, index, nestedFieldName, value);
    };
    return (
        <Grid container direction="column">
            <Grid item container direction="row" alignItems="center">
                <Typography variant="subtitle1">{label}</Typography>
                {allowMulti && (
                    <IconButton color="primary" onClick={() => onAdd(name)}>
                        <AddCircle />
                    </IconButton>
                )}
            </Grid>
            {fieldValues.map((valueObj, index) => (
                <Grid key={index} item container direction="row" alignItems="center" spacing={2}>
                    {nestedFields.map(field => (
                        <Grid key={field.name} item xs>
                            <FormField
                                fullWidth
                                field={field}
                                value={valueObj[field.name]}
                                onChange={handleFieldChange(index, field.name)}
                            />
                        </Grid>
                    ))}
                    <Grid key={index} item xs={1}>
                        <IconButton color="secondary" onClick={() => onRemove(name, index)}>
                            <RemoveCircleOutline />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

ComplexField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    nestedFields: PropTypes.array.isRequired,
    fieldValues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    allowMulti: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default ComplexField;
