import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { NoteAdd as AddIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { FormDialog } from '../../../components';
import { APP_PATHS } from '../../../constants';
import { buildProjectPath } from '../../../routes/helper';
import { createShortenLabel } from '../../../utils/label';
import { projectIdSelector, projectStatusSelector } from '../../project/selectors';
import { setAlert } from '../../ui/actions';
import { getFeeItems, getTasks, saveFeeItems } from '../actions';
import { BillTable } from '../components';
import { feeBillsSelector, feeRatesSelector, feeTasksSelector } from '../selectors';
import styles from './styles/feeBilling';

export const FeeBilling = ({
    classes,
    bills,
    rates,
    allTasks,
    getFeeItems,
    getTasks,
    saveFeeItems,
    currentProjectId,
}) => {
    const newBillItem = { name: '' };
    const fields = [
        {
            name: 'name',
            type: 'textarea',
            rules: ['required'],
        },
    ];

    const [currentBills, setCurrentBills] = useState([]);
    const [currentRates, setCurrentRates] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [redirect, setRedirect] = useState('');

    useEffect(() => {
        getTasks();
    }, [getTasks]);

    useEffect(() => {
        if (currentProjectId) {
            getFeeItems(currentProjectId);
        }
    }, [currentProjectId, getFeeItems]);

    useEffect(() => setCurrentRates(rates), [rates]);
    useEffect(() => setCurrentBills(bills), [bills]);

    const preDefinedTasks = useMemo(
        () => allTasks.filter(task => !currentBills.map(bill => bill.name).includes(task.name)),
        [currentBills, allTasks]
    );

    const initialRate = useMemo(
        () =>
            rates.reduce((result, rate) => {
                result[rate.type] = 0;
                return result;
            }, {}),
        [rates]
    );

    const handleCellChange = (rowIndex, fieldName) => e => {
        const parsed = Number.parseInt(e.target.value, 10);
        const value = Number.isInteger(parsed) ? parsed : 0;
        const updatedBills = currentBills.map((bill, index) =>
            index === rowIndex ? { ...bill, [fieldName]: value } : bill
        );
        setCurrentBills(updatedBills);
    };

    const handleBillRemove = index => () => {
        const updatedBills = currentBills.filter((bill, i) => i !== index);
        setCurrentBills(updatedBills);
    };

    const handleTaskAdd = name => () => {
        const newBill = { ...initialRate, name };
        setCurrentBills([...currentBills, newBill]);
    };

    const handleRateChange = e => {
        const { name, value } = e.target;
        const updatedRates = currentRates.map(rate =>
            rate.type === name ? { type: name, price: Number.parseInt(value, 10) } : rate
        );
        setCurrentRates(updatedRates);
    };

    const handleSubmit = async () => {
        const bills = currentBills.filter(bill => _.some(rates, rate => bill[rate.type] !== 0));
        await saveFeeItems(currentProjectId, { rates: currentRates, bills });
        setRedirect(buildProjectPath(currentProjectId, APP_PATHS.FEE_SUMMARY));
    };

    const handleAddBillItem = bill => {
        const newBill = { name: bill.name, ...initialRate };
        setShowDialog(false);
        setCurrentBills([...currentBills, newBill]);
    };

    if (redirect) return <Redirect to={redirect} />;

    if (_.isEmpty(currentBills) || _.isEmpty(currentRates)) return null;

    return (
        <div className={classes.layout}>
            <section className={classes.billContainer}>
                <Paper className={classes.paper}>
                    <Typography variant="subtitle1" className={classes.ratesTitle}>
                        Current Hourly Rate:
                    </Typography>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justify="space-around"
                        wrap="nowrap"
                    >
                        {currentRates.map(({ type, price }) => (
                            <Grid container item spacing={1} key={type} direction="row" alignItems="center">
                                <Grid item>
                                    <Avatar className={classes.rateAvatar}>{createShortenLabel(type, 2)}</Avatar>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        key={type}
                                        name={type}
                                        variant="outlined"
                                        label={_.capitalize(type)}
                                        type="number"
                                        defaultValue={price}
                                        onChange={handleRateChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            min: '0',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <BillTable
                        editable
                        rates={currentRates}
                        currentBills={currentBills}
                        onCellChange={handleCellChange}
                        onRowRemove={handleBillRemove}
                    />
                    <Grid container alignItems="center" justify="space-between">
                        <Button color="primary" variant="contained" onClick={() => setShowDialog(true)}>
                            New Task
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                            Save Tasks
                        </Button>
                    </Grid>
                    <FormDialog
                        open={showDialog}
                        title="New Bill Item"
                        onClose={() => setShowDialog(false)}
                        onSubmit={handleAddBillItem}
                        formObject={newBillItem}
                        fields={fields}
                    />
                </Paper>
            </section>
            <section className={classes.tasksContainer}>
                <Paper>
                    <List
                        className={classes.taskList}
                        dense
                        subheader={<ListSubheader>Predefined Tasks</ListSubheader>}
                    >
                        {preDefinedTasks.map(task => (
                            <ListItem key={task.id} button>
                                <IconButton color="primary" onClick={handleTaskAdd(task.name)}>
                                    <AddIcon />
                                </IconButton>
                                <ListItemText primary={task.type} secondary={task.name} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </section>
        </div>
    );
};

FeeBilling.propTypes = {
    currentProjectId: PropTypes.number,
    currentProjectStatus: PropTypes.string,
    bills: PropTypes.array,
    rates: PropTypes.array,
    allTasks: PropTypes.array,
    getFeeItems: PropTypes.func,
    saveFeeItems: PropTypes.func,
    getTasks: PropTypes.func,
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    bills: feeBillsSelector(state),
    rates: feeRatesSelector(state),
    allTasks: feeTasksSelector(state),
});

export default compose(
    connect(mapStateToProps, { getFeeItems, saveFeeItems, getTasks, setAlert }),
    withStyles(styles)
)(FeeBilling);
