import _ from 'lodash';

const transferNameToLabel = name => {
    return _.split(name, '_')
        .map(w => _.upperFirst(w))
        .join(' ');
};

const createShortenLabel = (label, length) => label.substr(0, length).toUpperCase();

export { transferNameToLabel, createShortenLabel };
