import httpClient from '../lib/httpClient';
import { generateQueryStringByFilters } from '../lib/utils';

const client = new httpClient();

const logApiUrl = 'logEntries/';
const tagApiUrl = 'tags/';

const fetchLogEntriesByDocumentId = projectDocumentId =>
    client.get(`${logApiUrl}${generateQueryStringByFilters([{ projectDocumentId }])}`);

const fetchLogEntriesByProjectId = projectId =>
    client.get(`${logApiUrl}${generateQueryStringByFilters([{ projectId }])}`);

const postLogEntry = logEntry => client.post(logApiUrl, logEntry);

const putLogEntry = ({ id, ...logEntry }) => client.put(`${logApiUrl}${id}`, logEntry);

const deleteLogEntry = id => client.delete(`${logApiUrl}${id}`);

const fetchTags = () => client.get(tagApiUrl);

const uploadLogFile = (projectId, file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return client.post(`uploads/projects/${projectId}/logentries/`, data, true);
};

export const logService = {
    fetchTags,
    fetchLogEntriesByDocumentId,
    fetchLogEntriesByProjectId,
    postLogEntry,
    putLogEntry,
    deleteLogEntry,
    uploadLogFile,
};
