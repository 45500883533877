const styles = theme => ({
    layout: {
        minWidth: '40vw',
    },
    title: {
        textTransform: 'capitalize',
    },
    emailsContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    emailItem: {
        marginRight: theme.spacing(0.5),
    },
});

export default styles;
