import bgImg from '../../images/background.jpg';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        position: 'relative',

        '&::before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: '-1',
            backgroundImage: `url(${bgImg})`,
            backgroundSize: 'cover',
            backgroundColor: '#444',
            backgroundPosition: 'center',
            filter: `brightness(0.75) grayscale(60%)`,
        },
    },

    title: {
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightMedium,
    },

    logo: {
        position: 'absolute',
        top: '64px',
        left: '64px',
        backgroundColor: '#eee',
        width: '160px',

        '& img': {
            width: '100%',
        },
    },

    enter: {
        marginTop: theme.spacing(5),
        '& h5': {
            marginRight: theme.spacing(2),
            color: theme.palette.primary.contrastText,
        },
    },
});

export default styles;
