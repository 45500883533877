import { createAction } from 'redux-actions';
import { orderBy, isEmpty } from 'lodash';

import {
    GET_DOCS,
    GET_PROJECT_DOC_REVIEWS,
    ADD_PROJECT_DOCS,
    REMOVE_PROJECT_DOC,
    UPLOAD_DOC,
    GET_REVIEW_ASSIGNMENTS,
    SAVE_REVIEW_ASSIGNMENTS,
    SET_CURRENT_DOC,
    UPDATE_REVIEW_ASSIGNMENTS,
    CHANGE_REVIEW_STEP,
    UPDATE_REVIEW_STATUS,
    GET_PROJECT_DOC_TIMELINE_NODES,
    SAVE_PROJECT_DOC_TIMELINE_NODE,
} from './types';
import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';

import { reviewService } from '../../../services/reviewService';

import { currentDocIdSelector, predefinedDocsSelector } from '../selectors';
import { projectIdSelector } from '../../project/selectors';
export const setCurrentDoc = createAction(SET_CURRENT_DOC);
export const updateReviewAssignments = createAction(UPDATE_REVIEW_ASSIGNMENTS);
export const changeReviewStep = createAction(CHANGE_REVIEW_STEP);
export const updateReviewStatus = createAction(UPDATE_REVIEW_STATUS);

export const getAllReviewDocuments = () => async (dispatch, getState) => {
    if (!isEmpty(predefinedDocsSelector(getState()))) return;
    const requestName = GET_DOCS;

    dispatch(networkRequestStart({ requestName }));
    try {
        const documentList = await reviewService.fetchPredefinedDocs();
        const action = createAction(requestName);
        dispatch(action(documentList));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getProjectDocReviews = stage => async (dispatch, getState) => {
    const requestName = GET_PROJECT_DOC_REVIEWS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const projectId = projectIdSelector(getState());
        const docReviews = await reviewService.fetchDocReviewsByProject(projectId, stage);
        const action = createAction(requestName);
        dispatch(action(docReviews));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const addDocsToProject = (projectId, docs) => async dispatch => {
    const requestName = ADD_PROJECT_DOCS;
    dispatch(networkRequestStart({ requestName }));

    try {
        const addedDocs = await reviewService.postDocsToProject(projectId, docs);
        const action = createAction(requestName);
        dispatch(action(addedDocs));
        dispatch(networkRequestSuccess({ requestName }));
        return addedDocs;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const removeProjectDoc = projectDocId => async dispatch => {
    const requestName = REMOVE_PROJECT_DOC;
    dispatch(networkRequestStart({ requestName }));
    try {
        await reviewService.deleteProjectDoc(projectDocId);
        const action = createAction(requestName);
        dispatch(action(projectDocId));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const uploadDoc = (projectDocId, file) => async dispatch => {
    const requestName = UPLOAD_DOC;
    dispatch(networkRequestStart({ requestName }));
    try {
        await reviewService.uploadProjectDoc(projectDocId, file);
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getReviewAssignments = projectDocId => async dispatch => {
    const requestName = GET_REVIEW_ASSIGNMENTS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const assignments = await reviewService.fetchReviewAssignments(projectDocId);
        const action = createAction(requestName);
        dispatch(action({ docId: projectDocId, assignments }));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const saveReviewAssignments = (projectDocId, assignments) => async dispatch => {
    const requestName = SAVE_REVIEW_ASSIGNMENTS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const savedAssignments = await reviewService.postReviewAssignments(projectDocId, assignments);
        const action = createAction(requestName);
        dispatch(action({ docId: projectDocId, assignments: savedAssignments }));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getDocTimelineNodes = () => async (dispatch, getState) => {
    const currentDocId = currentDocIdSelector(getState());
    const requestName = GET_PROJECT_DOC_TIMELINE_NODES;
    dispatch(networkRequestStart({ requestName }));
    try {
        const timelineNodes = await reviewService.fetchTimelineNodes(currentDocId);
        const action = createAction(requestName);
        dispatch(action(orderBy(timelineNodes, ['id'], ['desc'])));
        dispatch(networkRequestSuccess({ requestName }));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const saveTimelineNode = timelineNode => async (dispatch, getState) => {
    const currentDocId = currentDocIdSelector(getState());
    const requestName = SAVE_PROJECT_DOC_TIMELINE_NODE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const savedTimelineNode = await reviewService.postTimelineNode(currentDocId, timelineNode);
        if (savedTimelineNode) {
            const action = createAction(requestName);
            dispatch(action(savedTimelineNode));
            dispatch(networkRequestSuccess({ requestName }));
            return true;
        }
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
