import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { DropFileUploader } from '../../../components/';
import { APPROVAL_STATUS, ALERT_LEVEL } from '../../../constants';
import { currentDocIdSelector } from '../selectors';
import { BASE_URL } from '../../../lib/httpClient';
import { setAlert } from '../../ui/actions';
import FileDownloadButton from '../../../components/FileDownloadButton';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing(2),
        width: '96px',
    },
    comments: {
        marginTop: theme.spacing(4),
    },
    title: {
        textTransform: 'capitalize',
    },
});
export const ReviewDialog = ({
    classes,
    open,
    onClose,
    onSubmitTimelineNode,
    timelineNode,
    currentProjectDocumentId,
    setAlert,
    activeAssignment = {},
}) => {
    const [comments, setComments] = useState('');
    const [file, setFile] = useState({});

    const title = useMemo(() => {
        if (timelineNode) {
            return `${timelineNode.action} by ${timelineNode.fullName} at ${timelineNode.dateSubmitted}`;
        }
        return activeAssignment.role === 'doc_author' ? 'Submit Document' : 'Submit Review';
    }, [activeAssignment.role, timelineNode]);

    const handleFileUploaded = file => {
        setFile(file);
    };

    const handleUploadFailed = (file, message) => {
        setAlert({
            message: message || 'File upload is failed, please try again.',
            variant: ALERT_LEVEL.ERROR,
        });
    };

    const handleFileRemoved = () => {
        setFile({});
    };

    const handleReviewAction = type => () => {
        const node = {
            action: type,
            path: isEmpty(file) ? '' : JSON.parse(file.xhr.response).path,
            comments,
        };

        if (type === APPROVAL_STATUS.SUBMITTED && isEmpty(node.path)) {
            setAlert({ variant: ALERT_LEVEL.ERROR, message: 'Please upload a file for submit.' });
            return;
        }

        if (type === APPROVAL_STATUS.REJECTED && isEmpty(node.comments)) {
            setAlert({
                variant: ALERT_LEVEL.ERROR,
                message: 'Please provide comments for the rejection',
            });
            return;
        }

        onSubmitTimelineNode(node);
        setFile({});
        setComments('');
    };

    const renderActions = () => {
        if (timelineNode) {
            return (
                <Button className={classes.button} variant="contained" onClick={onClose}>
                    Close
                </Button>
            );
        }
        if (activeAssignment.role === 'doc_author') {
            return (
                <>
                    <Button className={classes.button} variant="contained" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleReviewAction(APPROVAL_STATUS.SUBMITTED)}
                    >
                        Submit
                    </Button>
                </>
            );
        }

        return (
            <>
                <Button className={classes.button} variant="contained" onClick={onClose}>
                    Close
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleReviewAction(APPROVAL_STATUS.REJECTED)}
                >
                    Reject
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleReviewAction(APPROVAL_STATUS.APPROVED)}
                >
                    Approve
                </Button>
            </>
        );
    };

    const renderFileLoadSection = () => {
        if (timelineNode) {
            return timelineNode.path ? <FileDownloadButton filePath={timelineNode.path} /> : null;
        }

        const postUrl = `${BASE_URL}/uploads/projectdocuments/${currentProjectDocumentId}`;
        return (
            <DropFileUploader
                onFileUploaded={handleFileUploaded}
                postUrl={postUrl}
                onUploadFailed={handleUploadFailed}
                onFileRemoved={handleFileRemoved}
            />
        );
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className={classes.title} onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent>
                {renderFileLoadSection()}
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: !!timelineNode }}
                    variant="outlined"
                    multiline
                    rows="6"
                    label="Comments"
                    className={classes.comments}
                    defaultValue={timelineNode ? timelineNode.comments : ''}
                    onChange={e => setComments(e.target.value)}
                />
            </DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </Dialog>
    );
};

ReviewDialog.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmitTimelineNode: PropTypes.func,
    timelineNode: PropTypes.object,
    currentProjectDocumentId: PropTypes.number,
    setAlert: PropTypes.func,
    activeAssignment: PropTypes.object,
    downloadFile: PropTypes.func,
};

const mapStateToProps = state => ({
    currentProjectDocumentId: currentDocIdSelector(state),
});

export default compose(connect(mapStateToProps, { setAlert }), withStyles(styles))(ReviewDialog);
