import { handleActions } from 'redux-actions';
import {
    GET_PROJECT_ADDONS,
    SET_CURRENT_ADDON,
    SAVE_PROJECT_ADDONS,
    GET_ADDON_TRACKINGS,
    SAVE_ADDON_TRACKING,
    UPDATE_ADDON_TRACKING,
    REMOVE_ADDON_TRACKING,
} from './actions/types';

const initialState = {
    projectAddOns: [],
    selectedAddOnId: -1,
    AddOnTrackingRecords: [],
};

const reducer = handleActions(
    {
        [GET_PROJECT_ADDONS]: (state, { payload }) => ({ ...state, projectAddOns: payload }),
        [SET_CURRENT_ADDON]: (state, { payload }) => ({ ...state, selectedAddOnId: payload }),
        [SAVE_PROJECT_ADDONS]: (state, { payload }) => ({
            ...state,
            projectAddOns: [...state.projectAddOns, ...payload],
        }),
        [GET_ADDON_TRACKINGS]: (state, { payload }) => ({
            ...state,
            AddOnTrackingRecords: payload,
        }),
        [SAVE_ADDON_TRACKING]: (state, { payload }) => ({
            ...state,
            AddOnTrackingRecords: [...state.AddOnTrackingRecords, payload],
        }),
        [UPDATE_ADDON_TRACKING]: (state, { payload }) => ({
            ...state,
            AddOnTrackingRecords: state.AddOnTrackingRecords.map(item =>
                item.id === payload.id ? { ...item, ...payload } : item
            ),
        }),
        [REMOVE_ADDON_TRACKING]: (state, { payload }) => ({
            ...state,
            AddOnTrackingRecords: state.AddOnTrackingRecords.filter(item => item.id !== payload),
        }),
    },
    initialState
);

export default reducer;
