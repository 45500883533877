import { highlightRow } from '../../../../components/styles/index';

const styles = theme => ({
    layout: {
        marginBottom: theme.spacing(2),
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    highlightRow: highlightRow(theme),
    numberCellWidth: {
        width: '150px',
    },
});

export default styles;
