import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Stepper, Step, StepButton, Button, withStyles, List, ListItem } from '@material-ui/core';
import _ from 'lodash';

import { DataForm, SubHeader, SideSection } from '../../../components';
import styles from './styles/projectWizard';
import { projectIdSelector, getFormFields, getProjectInfo, projectStatusSelector } from '../selectors';
import { saveProjectInfo, uploadFile } from '../actions';
import { PROJECT_PORTION, APP_PATHS } from '../../../constants';
import { projectService } from '../../../services';
import { buildProjectPath } from '../../../routes/helper';

export const steps = [PROJECT_PORTION.GENERAL, PROJECT_PORTION.DETAIL, PROJECT_PORTION.IMPACT];
export const ProjectWizard = ({
    classes,
    saveProjectInfo,
    currentProjectId,
    formFields,
    projectInfo,
    uploadFile,
    history,
}) => {
    const [activeStep, setActiveStep] = useState(PROJECT_PORTION.GENERAL);
    const [completedSteps, setCompletedSteps] = useState({});
    const [references, setReferences] = useState([]);

    const getReferences = async () => {
        const results = await projectService.getReferences();
        setReferences(results);
    };

    useEffect(() => {
        getReferences();
    }, []);

    useEffect(() => {
        const isLastStep = () => {
            const activeIndex = steps.indexOf(activeStep);
            return activeIndex === steps.length - 1;
        };
        if (completedSteps[activeStep] && !isLastStep()) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [activeStep, completedSteps]);

    const handleStep = step => () => {
        setActiveStep(step);
    };

    const handleSaveData = async data => {
        const success = await saveProjectInfo(currentProjectId, activeStep, data);
        if (success) {
            setCompletedSteps({ ...completedSteps, [activeStep]: true });
            const activeStepIndex = steps.indexOf(activeStep);
            if (activeStepIndex < steps.length - 1) {
                setActiveStep(steps[activeStepIndex + 1]);
            }
            if (activeStepIndex === steps.length - 1) {
                history.push(buildProjectPath(currentProjectId, APP_PATHS.PROJECT_REQUEST));
            }
        }
    };

    const handleDirtyFlag = isDirty => {
        if (isDirty) {
            setCompletedSteps({ ...completedSteps, [activeStep]: false });
        }
    };

    const handleFileUpload = async (name, file) => await uploadFile(currentProjectId, name, file, activeStep);

    const renderCurrentForm = () => {
        if (_.isEmpty(formFields)) return;
        const form = formFields[activeStep];
        const formObject = projectInfo[activeStep];
        if (_.isEmpty(formObject)) return;

        return (
            <DataForm
                key={currentProjectId + activeStep}
                setDirtyFlag={handleDirtyFlag}
                formSchema={form}
                formObject={formObject}
                onSave={handleSaveData}
                onFileUpload={handleFileUpload}
                saveButtonText="Save and Next"
            />
        );
    };

    const renderStepper = () => {
        const activeIndex = steps.indexOf(activeStep);
        return (
            <Stepper nonLinear activeStep={activeIndex}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index} completed={completedSteps[step]}>
                            <StepButton onClick={handleStep(step)}>{step}</StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );
    };

    const renderReferences = () => (
        <SideSection title="Reference" className={classes.referSection}>
            <List>
                {references.map(({ label, url }) => (
                    <ListItem key={label}>
                        <Button fullWidth target="_blank" href={url}>
                            {label}
                        </Button>
                    </ListItem>
                ))}
            </List>
        </SideSection>
    );

    return (
        <div className={classes.layout}>
            <section className={classes.mainSection}>
                <SubHeader title="Fill Project Info By Step" />
                {renderStepper()}
                {renderCurrentForm()}
            </section>
            {renderReferences()}
        </div>
    );
};

ProjectWizard.propTypes = {
    classes: PropTypes.object.isRequired,
    currentProjectId: PropTypes.number,
    currentProjectStatus: PropTypes.string,
    saveProjectInfo: PropTypes.func,
    formFields: PropTypes.object,
    projectInfo: PropTypes.object,
    uploadFile: PropTypes.func,
    history: PropTypes.object,
};

const mapStateToProps = state => ({
    currentProjectId: projectIdSelector(state),
    currentProjectStatus: projectStatusSelector(state),
    formFields: getFormFields(state),
    projectInfo: getProjectInfo(state),
});

export default connect(mapStateToProps, {
    saveProjectInfo,
    uploadFile,
})(withStyles(styles)(ProjectWizard));
