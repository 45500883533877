import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';

import logo from '../images/logo.png';
import { Send } from '@material-ui/icons';
import { setAlert } from '../modules/ui/actions';
import { APP_PATHS } from '../constants';
import styles from './styles/landing';

const Landing = ({ classes, hasError }) => (
    <div className={classes.layout}>
        <Button className={classes.logo} href="https://www.sfpublicworks.org/" target="_blank">
            <img src={logo} alt="" />
        </Button>
        <Typography className={classes.title} variant="h2">
            {hasError ? 'Oops, something is wrong. Please try again!' : 'Regulatory Affairs Management System'}
        </Typography>
        <Button href={APP_PATHS.LOGIN} variant="contained" size="large" color="primary" className={classes.enter}>
            <Typography variant="h5">Enter</Typography>
            <Send className={classes.enterIcon} />
        </Button>
    </div>
);

Landing.propTypes = {
    classes: PropTypes.object,
    hasError: PropTypes.bool,
};

export default compose(connect(null, { setAlert }), withStyles(styles))(Landing);
