export const GET_DOCS = 'GET_DOCS';
export const GET_PROJECT_DOC_REVIEWS = 'GET_PROJECT_DOC_REVIEWS';
export const ADD_PROJECT_DOCS = 'ADD_PROJECT_DOCS';
export const REMOVE_PROJECT_DOC = 'REMOVE_PROJECT_DOC';
export const UPLOAD_DOC = 'UPLOAD_DOC';
export const GET_REVIEW_ASSIGNMENTS = 'GET_REVIEW_ASSIGNMENTS';
export const SAVE_REVIEW_ASSIGNMENTS = 'SAVE_REVIEW_ASSIGNMENTS';
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC';
export const UPDATE_REVIEW_ASSIGNMENTS = 'UPDATE_REVIEW_ASSIGNMENTS';
export const CHANGE_REVIEW_STEP = 'CHANGE_REVIEW_STEP';
export const UPDATE_REVIEW_STATUS = 'UPDATE_REVIEW_STATUS';
export const GET_PROJECT_DOC_TIMELINE_NODES = 'GET_PROJECT_DOC_TIMELINE_NODES';
export const SAVE_PROJECT_DOC_TIMELINE_NODE = 'SAVE_PROJECT_DOC_TIMELINE_NODE';
