const highlightRow = theme => ({
    backgroundColor: theme.palette.common.black,
    '& td, th': {
        ...theme.typography.subtitle1,
        color: theme.palette.common.white,
        textTransform: 'capitalize',
    },
});

const roleColors = {
    author: '#00CCFF',
    approver: '#e65100',
    reviewer: '#ff9800',
};

const statusColor = {
    approved: '#43a047',
    rejected: '#dd2c00',
};

export { highlightRow, roleColors, statusColor };
