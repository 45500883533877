import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { connect } from 'react-redux';

import { getTopProjectSearchOptions, createProjectSelectOptions } from '../selectors';
import { projectService } from '../../../services';

const SearchBar = ({ defaultOptions, onOptionSelect }) => {
    const handleLoadOptions = async inputValue => {
        const projects = await projectService.searchProjects({ q: inputValue });
        const result = createProjectSelectOptions(projects);
        return new Promise(resolve => resolve(result));
    };

    const handleSelectChange = selectedOption => {
        if (selectedOption) {
            onOptionSelect(selectedOption.value);
        }
    };

    return (
        <AsyncSelect
            defaultOptions={defaultOptions}
            cacheOptions
            loadOptions={handleLoadOptions}
            placeholder="Search project..."
            noOptionsMessage={() => 'No project is found.'}
            isClearable
            onChange={handleSelectChange}
        />
    );
};

SearchBar.propTypes = {
    defaultOptions: PropTypes.array,
    onOptionSelect: PropTypes.func,
};

const mapStateToProps = state => ({
    defaultOptions: getTopProjectSearchOptions(state),
});
export default connect(mapStateToProps)(SearchBar);
