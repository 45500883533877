const PROCESS_ERROR_CODE = 10000;

const TOP_PROJECTS_COUNT = 20;

const DATE_FORMAT = 'YYYY-MM-DD';

const APPROVAL_STATUS = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING: 'pending',
    SUBMITTED: 'submitted',
};

const ALERT_LEVEL = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
};

const USER_ROLES = {
    ADMIN: 'admin',
    RA_SPECIALIST: 'ra_specialist',
    RA_MANAGER: 'ra_manager',
    DE_MANAGER: 'de_manager',
    PROJECT_CREATOR: 'project_creator',
    ACCOUNT: 'account',
};

const PROJECT_ROLES = {
    LEAD: 'lead',
};

const LOCATION_STATE = {
    UNAUTHORIZED: 'unauthorized',
    FROM: 'from',
};

const PROJECT_PORTION = {
    GENERAL: 'general',
    DETAIL: 'detail',
    IMPACT: 'impact',
    FEE_INITIAL_SCREEN: 'feeInitialScreen',
};

const PROJECT_STAGE = {
    INTAKE: 'intake',
    OVERHEAD: 'overhead',
    FEES: 'fees',
};

const PROJECT_STATUS = {
    INTAKE_NOT_SUBMITTED: 'intake_NotSubmitted',
    INTAKE_SUBMITTED: 'intake_Submitted',
    INTAKE_ACCEPTED: 'intake_Accepted',
    INTAKE_REJECTED: 'intake_Rejected',
    INTAKE_ASSIGNED: 'intake_Assigned',
    OVERHEAD_SUBMITTED: 'overhead_Submitted',
    OVERHEAD_APPROVED: 'overhead_Approved',
    FEE_PENDING: 'fee_Pending',
    FEE_SUBMITTED: 'fee_Submitted',
    FEE_ACCEPTED: 'fee_Accepted',
    FEE_REJECTED: 'fee_Rejected',
    FEE_APPROVED: 'fee_Approved',
    DOC_REVIEW_PENDING: 'docReview_Pending',
    DOC_REVIEW_COMPLETE: 'docReview_Complete',
};

const FILTER_OPERATOR = {
    CONTAIN: 'co',
    EQUAL: 'eq',
    START_WITH: 'sw',
    IN: 'in',
};

const DOC_REVIEW_STAGE = {
    INITIAL: 'INITIAL',
    SUBMITTAL: 'SUBMITTAL',
    STUDIES: 'STUDIES',
    ENVDOCUMENT: 'ENVDOCUMENT',
    ADDONS: 'ADDONS',
    VERIFICATIONS: 'VERIFICATIONS',
};

const DOC_REVIEW_TYPE = {
    DEFAULT: {
        INITIAL: 'Initial',
        CEQA: 'CEQA',
        NEPA: 'NEPA',
    },
    [DOC_REVIEW_STAGE.ADDONS]: {
        PERMIT: 'PERMIT',
        ENTITLEMENT: 'ENTITLEMENT',
        SF_INTERNAL: 'SF_INTERNAL',
        PW_INTERNAL: 'PW_INTERNAL',
    },
    [DOC_REVIEW_STAGE.VERIFICATIONS]: {},
};

const ADD_ON_STATUS = {
    PENDING: 'pending',
    SUBMITTED: 'submitted',
    APPROVED: 'approved',
    REJECTED: 'rejected',
};

const DOC_REVIEW_STATUS = {
    PENDING_ASSIGNMENT: 'pendingAssignment',
    IN_PROGRESS: 'inProgress',
    COMPLETE: 'complete',
    OVERDUE: 'overdue',
    REJECTED: 'rejected',
};

const REVIEW_ROLES = {
    doc_author: 'author',
    doc_reviewer: 'reviewer',
    doc_approver: 'approver',
};

const APP_PATHS = {
    LANDING: '/',
    LOGIN: '/auth/login',
    SIGNUP: 'auth/signup',
    DASHBOARD: '/dashboard',
    PROJECT_WIZARD: '/projects/:id/wizard',
    PROJECT_REQUEST: '/projects/:id/request',
    FEE_PROPOSAL: '/projects/:id/fee',
    FEE_FUNDING: '/projects/:id/fee/funding',
    FEE_BILLING: '/projects/:id/fee/billing',
    FEE_SUMMARY: '/projects/:id/fee/summary',
    REVIEW: '/projects/:id/reviews',
    REVIEW_INITIAL: `/projects/:id/reviews/${DOC_REVIEW_STAGE.INITIAL}`,
    REVIEW_SUBMITTAL: `/projects/:id/reviews/${DOC_REVIEW_STAGE.SUBMITTAL}`,
    REVIEW_STUDIES: `/projects/:id/reviews/${DOC_REVIEW_STAGE.STUDIES}`,
    REVIEW_ENVDOCUMENT: `/projects/:id/reviews/${DOC_REVIEW_STAGE.ENVDOCUMENT}`,
    ADD_ON: '/projects/:id/addons',
    VERIFICATION_DOCS: '/projects/:id/verification',
    ECR: '/projects/:id/ecrs',
    SUMMARY: '/projects/:id/summary',
};

const STATUS_MESSAGE_MAP = {
    [PROJECT_STATUS.INTAKE_NOT_SUBMITTED]: {
        headerLabel: 'Not submitted',
        warningMessage: 'Project is not submitted yet.',
        navigateTo: APP_PATHS.PROJECT_WIZARD,
    },
    [PROJECT_STATUS.INTAKE_SUBMITTED]: {
        headerLabel: 'Submitted',
        warningMessage: 'Project is still waiting for approval.',
        navigateTo: APP_PATHS.PROJECT_REQUEST,
    },
    [PROJECT_STATUS.INTAKE_ACCEPTED]: {
        headerLabel: 'Accepted',
        warningMessage: 'Project is accepted and cannot be changed.',
        navigateTo: APP_PATHS.PROJECT_REQUEST,
    },
    [PROJECT_STATUS.INTAKE_REJECTED]: {
        headerLabel: 'Rejected',
        warningMessage: 'Project is rejected and need be re-submitted again.',
        navigateTo: APP_PATHS.PROJECT_WIZARD,
    },
    [PROJECT_STATUS.INTAKE_ASSIGNED]: {
        headerLabel: 'Assigned',
        warningMessage: 'Project is assigned and cannot be changed.',
        navigateTo: APP_PATHS.FEE_FUNDING,
    },
    [PROJECT_STATUS.OVERHEAD_SUBMITTED]: {
        headerLabel: 'Overhead Submitted',
        warningMessage: 'Project is waiting for overhead approval.',
        navigateTo: APP_PATHS.PROJECT_REQUEST,
    },
    [PROJECT_STATUS.OVERHEAD_APPROVED]: {
        headerLabel: 'Overhead Approved',
        warningMessage: 'Project is overhead approved and cannot be changed.',
        navigateTo: APP_PATHS.PROJECT_REQUEST,
    },
    [PROJECT_STATUS.FEE_PENDING]: {
        headerLabel: 'Fee Pending',
        warningMessage: 'Project fee proposal is pending.',
        navigateTo: APP_PATHS.FEE_BILLING,
    },
    [PROJECT_STATUS.FEE_SUBMITTED]: {
        headerLabel: 'Fee Submitted',
        warningMessage: 'Project fee proposal is waiting for approve.',
        navigateTo: APP_PATHS.FEE_SUMMARY,
    },
    [PROJECT_STATUS.FEE_ACCEPTED]: {
        headerLabel: 'Fee Accepted',
        warningMessage: 'Project fee proposal is accepted and cannot be changed',
        navigateTo: APP_PATHS.FEE_SUMMARY,
    },
    [PROJECT_STATUS.FEE_APPROVED]: {
        headerLabel: 'Fee Approved',
        warningMessage: 'Project fee proposal is approved and cannot be changed',
        navigateTo: APP_PATHS.FEE_SUMMARY,
    },
    [PROJECT_STATUS.FEE_REJECTED]: {
        headerLabel: 'Fee Rejected',
        warningMessage: 'Project fee proposal is rejected. Proceed to make adjustments and resubmit it.',
        navigateTo: APP_PATHS.FEE_FUNDING,
    },
    [PROJECT_STATUS.DOC_REVIEW_PENDING]: {
        headerLabel: 'Review Pending',
        warningMessage: 'Project is currently under document review.',
        navigateTo: APP_PATHS.REVIEW_INITIAL,
    },
    [PROJECT_STATUS.DOC_REVIEW_COMPLETE]: {
        headerLabel: 'Review Complete',
        warningMessage: 'Document review is complete for current project.',
        navigateTo: APP_PATHS.SUMMARY,
    },
};

const PATH_STATUS_MAP = {
    [APP_PATHS.DASHBOARD]: Object.values(PROJECT_STATUS),
    [APP_PATHS.PROJECT_WIZARD]: [PROJECT_STATUS.INTAKE_NOT_SUBMITTED, PROJECT_STATUS.INTAKE_REJECTED],
    [APP_PATHS.PROJECT_REQUEST]: Object.values(PROJECT_STATUS),
    [APP_PATHS.FEE_PROPOSAL]: [
        PROJECT_STATUS.INTAKE_ASSIGNED,
        PROJECT_STATUS.FEE_ACCEPTED,
        PROJECT_STATUS.FEE_APPROVED,
        PROJECT_STATUS.FEE_PENDING,
        PROJECT_STATUS.FEE_SUBMITTED,
        PROJECT_STATUS.FEE_REJECTED,
        PROJECT_STATUS.DOC_REVIEW_PENDING,
        PROJECT_STATUS.DOC_REVIEW_COMPLETE,
    ],
    [APP_PATHS.FEE_FUNDING]: [PROJECT_STATUS.INTAKE_ASSIGNED, PROJECT_STATUS.FEE_REJECTED],
    [APP_PATHS.FEE_BILLING]: [PROJECT_STATUS.FEE_PENDING, PROJECT_STATUS.FEE_REJECTED],
    [APP_PATHS.FEE_SUMMARY]: [
        PROJECT_STATUS.FEE_ACCEPTED,
        PROJECT_STATUS.FEE_APPROVED,
        PROJECT_STATUS.FEE_PENDING,
        PROJECT_STATUS.FEE_SUBMITTED,
        PROJECT_STATUS.FEE_REJECTED,
        PROJECT_STATUS.DOC_REVIEW_PENDING,
        PROJECT_STATUS.DOC_REVIEW_COMPLETE,
    ],
    [APP_PATHS.REVIEW]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.REVIEW_INITIAL]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.REVIEW_SUBMITTAL]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.REVIEW_STUDIES]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.REVIEW_ENVDOCUMENT]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.ADD_ON]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.VERIFICATION_DOCS]: [PROJECT_STATUS.FEE_ACCEPTED, PROJECT_STATUS.DOC_REVIEW_PENDING],
    [APP_PATHS.ECR]: [
        PROJECT_STATUS.INTAKE_ASSIGNED,
        PROJECT_STATUS.FEE_ACCEPTED,
        PROJECT_STATUS.FEE_APPROVED,
        PROJECT_STATUS.FEE_PENDING,
        PROJECT_STATUS.FEE_SUBMITTED,
        PROJECT_STATUS.FEE_REJECTED,
        PROJECT_STATUS.DOC_REVIEW_PENDING,
        PROJECT_STATUS.DOC_REVIEW_COMPLETE,
    ],
    [APP_PATHS.SUMMARY]: [
        PROJECT_STATUS.INTAKE_ACCEPTED,
        PROJECT_STATUS.INTAKE_ASSIGNED,
        PROJECT_STATUS.FEE_ACCEPTED,
        PROJECT_STATUS.FEE_APPROVED,
        PROJECT_STATUS.FEE_PENDING,
        PROJECT_STATUS.FEE_SUBMITTED,
        PROJECT_STATUS.FEE_REJECTED,
        PROJECT_STATUS.DOC_REVIEW_PENDING,
        PROJECT_STATUS.DOC_REVIEW_COMPLETE,
    ],
};

export {
    DATE_FORMAT,
    FILTER_OPERATOR,
    TOP_PROJECTS_COUNT,
    PROJECT_STAGE,
    PROJECT_STATUS,
    PROJECT_PORTION,
    APPROVAL_STATUS,
    ALERT_LEVEL,
    USER_ROLES,
    LOCATION_STATE,
    PROJECT_ROLES,
    PROCESS_ERROR_CODE,
    DOC_REVIEW_STAGE,
    DOC_REVIEW_TYPE,
    DOC_REVIEW_STATUS,
    STATUS_MESSAGE_MAP,
    APP_PATHS,
    PATH_STATUS_MAP,
    REVIEW_ROLES,
    ADD_ON_STATUS,
};
