import {
    Dashboard as DashboardIcon,
    LocalAtm,
    EventNote,
    LibraryAdd,
    Storage,
    ListAlt,
    Dns,
    Payment,
    AssignmentTurnedIn,
} from '@material-ui/icons';

import { ProjectWizard, RequestSummary, ProjectSummary } from '../modules/project/views';

import { Fee, feeRoutes } from '../modules/fee';
import { Review, reviewRoutes } from '../modules/review/';
import { Dashboard } from '../modules/dashboard';
import { USER_ROLES, APP_PATHS } from '../constants';
import ECR from '../modules/ecr/ECR';
import Addon from '../modules/addon/views/Addon';
import VerificationDocs from '../modules/verificationDocs/views/VerificationDocs';

export const mainRoutes = [
    {
        path: APP_PATHS.DASHBOARD,
        component: Dashboard,
        sidebarName: 'Dashboard',
        icon: DashboardIcon,
        primary: true,
        roles: [...Object.values(USER_ROLES)],
    },
    {
        path: APP_PATHS.PROJECT_WIZARD,
        component: ProjectWizard,
        sidebarName: 'Project Wizard',
        icon: Dns,
        secondary: true,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.PROJECT_REQUEST,
        component: RequestSummary,
        sidebarName: 'Request',
        icon: ListAlt,
        secondary: true,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.DE_MANAGER,
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.ACCOUNT,
        ],
    },
    {
        path: APP_PATHS.FEE_PROPOSAL,
        component: Fee,
        sidebarName: 'Fee Proposal',
        icon: LocalAtm,
        secondary: true,
        routes: feeRoutes,
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.PROJECT_CREATOR, USER_ROLES.ADMIN],
    },
    {
        path: APP_PATHS.REVIEW,
        component: Review,
        sidebarName: 'Document Review',
        icon: EventNote,
        secondary: true,
        routes: reviewRoutes,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.ACCOUNT,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.DE_MANAGER,
        ],
    },
    {
        path: APP_PATHS.ADD_ON,
        component: Addon,
        sidebarName: 'Add On',
        icon: LibraryAdd,
        secondary: true,
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.ADMIN],
    },
    {
        path: APP_PATHS.VERIFICATION_DOCS,
        component: VerificationDocs,
        sidebarName: 'Verification Docs',
        icon: AssignmentTurnedIn,
        secondary: true,
        roles: [USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST, USER_ROLES.ADMIN],
    },
    {
        path: APP_PATHS.ECR,
        component: ECR,
        sidebarName: 'Environmental Commitment',
        icon: Payment,
        secondary: true,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.ADMIN,
            USER_ROLES.DE_MANAGER,
            USER_ROLES.PROJECT_CREATOR,
        ],
    },
    {
        path: APP_PATHS.SUMMARY,
        component: ProjectSummary,
        sidebarName: 'Summary',
        icon: Storage,
        secondary: true,
        roles: [
            USER_ROLES.RA_MANAGER,
            USER_ROLES.RA_SPECIALIST,
            USER_ROLES.DE_MANAGER,
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_CREATOR,
            USER_ROLES.ACCOUNT,
        ],
    },
    {
        redirect: true,
        path: '/projects',
        to: APP_PATHS.DASHBOARD,
    },
];
