import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import { FETCH_USERS, SET_CURRENT_USER } from './types';
import { userService } from '../../../services';
import { networkRequestSuccess, networkRequestStart, networkRequestFail } from '../../common/actions';
import { allUsersSelector } from '../selectors';

export const getAllUsers = () => async (dispatch, getState) => {
    if (!isEmpty(allUsersSelector(getState()))) return;

    const requestName = FETCH_USERS;
    try {
        dispatch(networkRequestStart({ requestName }));
        const users = await userService.fetchUsers();
        const action = createAction(requestName);
        dispatch(action(users));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        dispatch(networkRequestFail({ requestName, code: error.code, message: error.message }));
    }
};

export const setCurrentUserInfo = createAction(SET_CURRENT_USER);
