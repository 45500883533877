import React from 'react';

const HealthZ = () => {
    return (
        <div>
            <h3>Env Vars</h3>
            <dl>
                <dt>API URL</dt>
                <dd>{process.env.REACT_APP_BASE_API_URL}</dd>
            </dl>
        </div>
    );
};

export default HealthZ;
