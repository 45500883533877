import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { FormDialog } from '../../../components';
import { updateLogEntry, createLogEntry, setCurrentLog, uploadLogFile } from '../actions';
import { currentLogSelector, tagsSelector } from '../selectors';

const LogDetailDialog = ({ uploadLogFile, currentLog, tags, updateLogEntry, createLogEntry, setCurrentLog }) => {
    const fields = useMemo(() => {
        const handleUploadLogFile = file => uploadLogFile(file);

        return ['subject', 'tags', 'notes', 'eventDate', 'path'].map(key => {
            let type = '';
            let options;
            let name = key;
            let label = key;
            let rules;
            let onUpload = null;
            switch (key) {
                case 'subject':
                    type = 'text';
                    rules = ['required'];
                    break;
                case 'notes':
                    type = 'textarea';
                    rules = ['required'];
                    break;
                case 'tags':
                    type = 'multi-select';
                    label = 'Choose tags...';
                    options = tags.map(tag => ({ label: tag.name, value: tag.id }));
                    break;
                case 'eventDate':
                    type = 'date';
                    break;
                case 'path':
                    type = 'upload';
                    onUpload = handleUploadLogFile;
                    break;
                default:
                    type = 'text';
            }
            return { name, label, type, rules, options, onUpload };
        });
    }, [tags, uploadLogFile]);

    const handleLogSubmit = async log => {
        let tagArray = [];
        if (log.tags) tagArray = _.isArray(log.tags) ? log.tags : [log.tags];
        const parsedTagIds = tagArray.map(tagId => parseInt(tagId, 10));
        const newLog = { ...log, tags: parsedTagIds };
        let result;
        if (log.id) {
            result = await updateLogEntry(newLog);
        } else {
            result = await createLogEntry(newLog);
        }
        if (result) setCurrentLog(null);
    };

    return (
        <FormDialog
            title="Submit a log entry"
            onClose={() => setCurrentLog(null)}
            formObject={currentLog}
            open={!_.isEmpty(currentLog)}
            fields={fields}
            onSubmit={handleLogSubmit}
        />
    );
};

LogDetailDialog.propTypes = {
    currentLog: PropTypes.object,
    updateLogEntry: PropTypes.func,
    createLogEntry: PropTypes.func,
    setCurrentLog: PropTypes.func,
};

const mapStateToProps = state => ({
    currentLog: currentLogSelector(state),
    tags: tagsSelector(state),
});

export default connect(mapStateToProps, { updateLogEntry, createLogEntry, setCurrentLog, uploadLogFile })(
    LogDetailDialog
);
