import { createAction } from 'redux-actions';
import { omit, isEmpty, intersection } from 'lodash';

import {
    GET_PROJECT_ADDONS,
    SET_CURRENT_ADDON,
    SAVE_PROJECT_ADDONS,
    SAVE_ADDON_TRACKING,
    GET_ADDON_TRACKINGS,
    UPLOAD_ADDON_TRACKING_FILE,
    UPDATE_ADDON_TRACKING,
    REMOVE_ADDON_TRACKING,
} from './types';
import { networkRequestStart, networkRequestSuccess, handleRequestError } from '../../common/actions';

import { addOnService } from '../../../services/addOnService';

import { currentAddOnIdSelector } from '../selectors';
import { projectIdSelector } from '../../project/selectors';
import { currentUserSelector } from '../../user/selectors';
import { USER_ROLES } from '../../../constants';

export const getProjectAddOns = () => async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    if (isEmpty(intersection(currentUser.roles, [USER_ROLES.ADMIN, USER_ROLES.RA_MANAGER, USER_ROLES.RA_SPECIALIST])))
        return;
    const projectId = projectIdSelector(getState());
    const requestName = GET_PROJECT_ADDONS;
    dispatch(networkRequestStart({ requestName }));
    try {
        const addOns = await addOnService.fetchAddOnsByProject(projectId);
        const action = createAction(requestName);
        dispatch(action(addOns));
        dispatch(networkRequestSuccess({ requestName }));
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const setCurrentAddOn = createAction(SET_CURRENT_ADDON);

export const saveAddOnsToProject = newAddOns => async (dispatch, getState) => {
    const projectId = projectIdSelector(getState());
    const requestName = SAVE_PROJECT_ADDONS;
    dispatch(networkRequestStart(requestName));
    try {
        const addOns = await addOnService.postAddOnsToProject(projectId, newAddOns);
        const action = createAction(requestName);
        dispatch(action(addOns));
        dispatch(networkRequestSuccess(requestName));
        return addOns;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const saveAddOnTrackingRecordToDocument = newRecord => async (dispatch, getState) => {
    const addOnId = currentAddOnIdSelector(getState());
    const requestName = SAVE_ADDON_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        const record = await addOnService.postAddOnHistoryToAddOn(addOnId, newRecord);
        const action = createAction(requestName);
        dispatch(action(record));
        dispatch(networkRequestSuccess(requestName));
        return record;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const getAddOnTrackingRecords = () => async (dispatch, getState) => {
    const addOnId = currentAddOnIdSelector(getState());
    const requestName = GET_ADDON_TRACKINGS;
    dispatch(networkRequestStart(requestName));
    try {
        const records = await addOnService.fetchAddOnHistoryByAddOnId(addOnId);
        const action = createAction(requestName);
        dispatch(action(records));
        dispatch(networkRequestSuccess(requestName));
        return records;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const uploadAddOnTrackingFile = file => async (dispatch, getState) => {
    const addOnId = currentAddOnIdSelector(getState());
    const requestName = UPLOAD_ADDON_TRACKING_FILE;
    dispatch(networkRequestStart({ requestName }));
    try {
        const { path } = await addOnService.uploadAddOnHistoryDoc(addOnId, file);
        dispatch(networkRequestSuccess({ requestName }));
        return path;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const updateAddOnTrackingRecord = updatedRecord => async dispatch => {
    const requestName = UPDATE_ADDON_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        await addOnService.putAddOnHistoryById(updatedRecord.id, {
            ...omit(updatedRecord, 'id', 'file'),
            path: updatedRecord.file.path,
        });
        const action = createAction(requestName);
        dispatch(action(updatedRecord));
        dispatch(networkRequestSuccess(requestName));
        return updatedRecord;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};

export const removeAddOnTrackingRecord = id => async dispatch => {
    const requestName = REMOVE_ADDON_TRACKING;
    dispatch(networkRequestStart(requestName));
    try {
        await addOnService.deleteAddOnHistory(id);
        const action = createAction(requestName);
        dispatch(action(id));
        dispatch(networkRequestSuccess(requestName));
        return true;
    } catch (error) {
        handleRequestError(dispatch, requestName, error);
    }
};
