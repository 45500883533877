import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, Paper, Tabs, Tab, AppBar } from '@material-ui/core';

import styles from './styles/docReview';
import { DocList, Timeline, TasksTable } from '../components';
import { SectionHeader, EmptyState } from '../../../components';
import {
    addDocsToProject,
    removeProjectDoc,
    getReviewAssignments,
    setCurrentDoc,
    getDocTimelineNodes,
} from '../actions';
import { DOC_REVIEW_STATUS, USER_ROLES } from '../../../constants';
import { LogEntryTable } from '../../logEntries/components';
import { getLogEntriesForCurrentDoc } from '../../logEntries/actions';
import { currentDocIdSelector } from '../selectors';
import { showSpinnerSelector } from '../../ui/selectors';
import useDocOptions from '../hooks/useDocOptions';
import { logsSelector } from '../../logEntries/selectors';
import { checkUserRole } from '../../../lib/utils';
import { currentUserSelector } from '../../user/selectors';

const DocReview = ({
    classes,
    documents,
    currentProjectId,
    currentProjectDocs,
    addDocsToProject,
    stage,
    removeProjectDoc,
    setCurrentDoc,
    getLogEntriesForCurrentDoc,
    getReviewAssignments,
    getDocTimelineNodes,
    currentDocId,
    isLoading,
    logs,
    currentUser,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currentTab, setCurrentTab] = useState('tasks');

    const selectedDoc = useMemo(() => currentProjectDocs.find(doc => doc.id === currentDocId), [
        currentDocId,
        currentProjectDocs,
    ]);

    const predefinedDocOptions = useDocOptions(documents, currentProjectDocs);

    useEffect(() => {
        setSelectedOptions([]);
        setCurrentDoc(-1);
        setCurrentTab('tasks');
    }, [setCurrentDoc, stage]);

    useEffect(() => {
        if (selectedDoc) {
            if (currentTab === 'logs') {
                getLogEntriesForCurrentDoc();
            }
            if (currentTab === 'tasks' && selectedDoc.status !== DOC_REVIEW_STATUS.PENDING_ASSIGNMENT) {
                getReviewAssignments(selectedDoc.id);
            }
            if (selectedDoc.status !== DOC_REVIEW_STATUS.PENDING_ASSIGNMENT) {
                getDocTimelineNodes();
            }
        }
    }, [currentTab, selectedDoc, stage, getLogEntriesForCurrentDoc, getReviewAssignments, getDocTimelineNodes]);

    const handleDocItemClick = docId => () => {
        if (docId !== currentDocId) {
            setCurrentDoc(docId);
            setCurrentTab('tasks');
        }
    };

    const handleSelectChange = selectedOptions => {
        setSelectedOptions(selectedOptions);
    };

    const handleAddDocs = async () => {
        if (selectedOptions.length > 0) {
            const addedDocs = await addDocsToProject(
                currentProjectId,
                documents
                    .filter(doc => selectedOptions.some(op => op.value === doc.id))
                    .map(doc => _.pick(doc, ['name', 'stage', 'type', 'milestone']))
            );
            if (addedDocs) {
                setSelectedOptions([]);
            }
        }
    };

    const handleTabChange = (e, value) => {
        setCurrentTab(value);
    };

    const handleSubmitNewDoc = async newDoc => {
        const addedDocs = await addDocsToProject(currentProjectId, [{ ...newDoc, stage }]);
        if (addedDocs) {
            setCurrentDoc(addedDocs[0].id);
        }
    };

    const handleRemoveProjectDoc = projectDocId => () => {
        removeProjectDoc(projectDocId);
    };

    const renderTasksTable = () => {
        if (currentTab === 'tasks') {
            if (!isLoading && currentDocId < 0) {
                return <EmptyState content="Select an existing or create a new document to start." />;
            }
            return <TasksTable />;
        }
    };

    return (
        <main className={classes.main}>
            <section className={classes.docListSection}>
                <Paper className={classes.paper}>
                    <SectionHeader>Document List</SectionHeader>
                    <DocList
                        predefinedDocOptions={predefinedDocOptions}
                        onSelectChange={handleSelectChange}
                        selectedOptions={selectedOptions}
                        selectedDocId={currentDocId}
                        onAddDocs={handleAddDocs}
                        currentProjectDocs={currentProjectDocs}
                        onDocItemClick={handleDocItemClick}
                        onSubmitNewDoc={handleSubmitNewDoc}
                        onRemoveProjectDoc={handleRemoveProjectDoc}
                        currentUser={currentUser}
                    />
                </Paper>
            </section>
            {selectedDoc && selectedDoc.status !== DOC_REVIEW_STATUS.PENDING_ASSIGNMENT && (
                <section className={classes.timelineSection}>
                    <Paper className={classes.paper}>
                        <SectionHeader>Review Timeline</SectionHeader>
                        <Timeline />
                    </Paper>
                </section>
            )}
            <section className={classes.tabsSection}>
                <Paper className={classes.paper}>
                    <AppBar position="static">
                        <Tabs value={currentTab} onChange={handleTabChange}>
                            <Tab value="tasks" label="Tasks Assignments" />
                            {selectedDoc &&
                                selectedDoc.status !== DOC_REVIEW_STATUS.PENDING_ASSIGNMENT &&
                                checkUserRole(
                                    currentUser,
                                    USER_ROLES.ADMIN,
                                    USER_ROLES.RA_MANAGER,
                                    USER_ROLES.RA_SPECIALIST
                                ) && <Tab value="logs" label="Log Entries" />}
                        </Tabs>
                    </AppBar>
                    {renderTasksTable()}
                    {currentTab === 'logs' && <LogEntryTable logs={logs} />}
                </Paper>
            </section>
        </main>
    );
};

DocReview.propTypes = {
    classes: PropTypes.object,
    documents: PropTypes.array.isRequired,
    currentProjectDocs: PropTypes.array.isRequired,
    addDocsToProject: PropTypes.func,
    currentProjectId: PropTypes.number,
    stage: PropTypes.string.isRequired,
    removeProjectDoc: PropTypes.func,
    getReviewAssignments: PropTypes.func,
    assignments: PropTypes.array,
    getLogEntriesForCurrentDoc: PropTypes.func,
    currentDocId: PropTypes.number,
    isLoading: PropTypes.bool,
    logs: PropTypes.array,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentDocId: currentDocIdSelector(state),
    isLoading: showSpinnerSelector(state),
    logs: logsSelector(state),
    currentUser: currentUserSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        addDocsToProject,
        removeProjectDoc,
        getReviewAssignments,
        setCurrentDoc,
        getLogEntriesForCurrentDoc,
        getDocTimelineNodes,
    }),
    withStyles(styles)
)(DocReview);
