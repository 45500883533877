import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, TextField, Paper, Avatar, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import FaceIcon from '@material-ui/icons/Face';
import styles from './styles/auth';
export class SignUp extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container justify="center">
                <Grid item className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <FaceIcon />
                        </Avatar>
                        <Typography variant="h5">Sign Up</Typography>
                        <form noValidate autoComplete="off" className={classes.form}>
                            <Grid container direction="column">
                                <TextField label="Username" name="username" className={classes.field} />
                                <TextField label="Email" name="email" className={classes.field} />
                                <TextField label="Role" name="role" className={classes.field} />
                                <TextField label="Org" name="org" className={classes.field} />
                                <TextField label="Password" name="password" type="password" className={classes.field} />
                            </Grid>
                            <Button type="submit" color="primary" variant="contained" className={classes.submit}>
                                Submit
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SignUp);
